import { POST_REPAIR_RECORD, FINISH_REPAIR, REMOVE_COMPONENT, CLEAR_REPAIR_ACTION_STATE } from './types';


export const postRecord = (data) => dispatch => {
    return dispatch({
        type: POST_REPAIR_RECORD,
        payload: {
            request: {
                method: 'post',
                url: `/repair/RecordRepair`,
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                    "mockSfcsEventSuccess": window.env.MOCK_SFCS_EVENT_SUCCESS,
                },
                data: data
            }
        }
    })
}

export const clearRepairActionStates = () => dispatch => {
    return dispatch({
        type: CLEAR_REPAIR_ACTION_STATE,
    })
}


export const finishRepair = (data) => dispatch => {
    return dispatch({
        type: FINISH_REPAIR,
        payload: {
            request: {
                method: 'post',
                url: `/repair/FinishRepair`,
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                    "mockSfcsEventSuccess": window.env.MOCK_SFCS_EVENT_SUCCESS,
                },
                data: data
            }
        }
    })
}



export const removeComponent = (data) => dispatch => {
    return dispatch({
        type: REMOVE_COMPONENT,
        payload: {
            request: {
                method: 'post',
                url: `/repair/RemoveComponent`,
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                    "mockSfcsEventSuccess": window.env.MOCK_SFCS_EVENT_SUCCESS,
                },
                data: data
            }
        }
    })
}
