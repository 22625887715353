export const isAlreadyRecorded = function (components, selectedKey) {

    if (components[selectedKey]?.FailureMode) return true;
    if (components[selectedKey]?.IsComponentRecorded) return true;
    else return false;
}

export const getCriticalComponentsList = function (genealogyDetails, repairComponents) {
    const componentList = [...genealogyDetails.CriticalComponents.map(component => {
        return {
            key: component.ComponentLocation,
            text: component.ComponentLocation
        }
    }), { key: "OTHER", text: "OTHER" }];
    const componentListAlreadyAvailableForRecord = repairComponents.map(value => value.ComponentLocation);
    const finalList = componentList.filter(component => !componentListAlreadyAvailableForRecord.includes(component.key));
    return finalList;
}


export const getInitial = function (components, key, name, t) {
    const initialValues = {};

    if (name === t("repairAndReplace.addNonCritical") || name === t("repairAndReplace.addReassemble")) {
        initialValues.FailureMode = DefaultSelectedOptions.FailureMode.text;
        initialValues.FailureSource = DefaultSelectedOptions.FailureSource.text;
        return initialValues;
    }

    initialValues.SerialNumber = components[key].AddComponent.ComponentSerialNumber;
    initialValues.LotTrackingNumber = components[key].AddComponent.LotTrackingNumber ? components[key].AddComponent.LotTrackingNumber : components[key].RemoveComponent.LotTrackingNumber;
    initialValues.PartNumber = components[key].AddComponent.ComponentPartNumber ? components[key].AddComponent.ComponentPartNumber : components[key].RemoveComponent.ComponentPartNumber;
    initialValues.FailureMode = components[key].FailureMode ? components[key].FailureMode : DefaultSelectedOptions.FailureMode.text;
    initialValues.FailureSource = components[key].FailureSource ? components[key].FailureSource : DefaultSelectedOptions.FailureSource.text;
    return initialValues;
}

const isEmpty = function (str) {
    return (!str || 0 === str.length);
}

export const shouldDisableFinishButton = function (repairComponents) {
    if (repairComponents.length === 0) {
        return true;
    }
    return repairComponents.filter(element => {
        if (element.IsComponentRecorded) {
            return false;
        }
        if (isEmpty(element.FailureMode) || isEmpty(element.FailureSource)) {
            return true;
        }

        if (isEmpty(element.AddComponent.ComponentSerialNumber) && !isEmpty(element.RemoveComponent.ComponentSerialNumber))
            return true;

        if (isEmpty(element.AddComponent.LotTrackingNumber) && !isEmpty(element.RemoveComponent.LotTrackingNumber))
            return true;

        return false;
    }).length > 0;
}

export const getNavLinkGroup = function (repairComponents, toShowAddNonCriticalView, toShowReassembleView, t) {

    const navLinkGroups = [];
    navLinkGroups[0] = {
        links: []
    };
    repairComponents.forEach(function (value, i) {
        navLinkGroups[0].links.push({
            name: value.ComponentLocation,
            key: i,
        })
    });

    if (toShowAddNonCriticalView) {
        navLinkGroups[0].links.push({
            name: t("repairAndReplace.addNonCritical"),
            key: navLinkGroups[0].links.length,
        });
    }

    if (toShowReassembleView) {
        navLinkGroups[0].links.push({
            name: t("repairAndReplace.addReassemble"),
            key: navLinkGroups[0].links.length,
        });
    }

    return navLinkGroups;
}

export const getRepairInfoColumns = function (t) {
    const columns = [
        {
            key: "RepairInstruction",
            name: t("repairAndReplace.repairInstruction"),
            fieldName: "RepairActionName",
            isResizable: true,
            minWidth: 150
        },
        {
            key: "Location",
            name: t("repairAndReplace.location"),
            fieldName: "ComponentLocation",
            isResizable: true,
            minWidth: 150
        },
        {
            key: "DebugCode",
            name: t("repairAndReplace.debugCode"),
            fieldName: "ComponentType",
            isResizable: true,
            minWidth: 150
        }
    ];
    return columns;
}


export const FailureSourceOptions = [
    { key: "A", text: "Process" },
    { key: "B", text: "Material" },
    { key: "C", text: "Design" },
    { key: "D", text: "Test" },
    { key: "E", text: "Program" }
];

export const DefaultSelectedOptions = {
    FailureMode: { key: 'A', text: "Functional" },
    FailureSource: { key: "B", text: "Material" }
};

export const ComponentLocationOptions = [
    { key: "A", text: "CPU (U1001)" },
    { key: "B", text: "TPM (U3801)" },
    { key: "C", text: "Memory(U1601)" },
    { key: "D", text: "Memory (1602)" },
    { key: "E", text: "Memory (1603)" },
    { key: "F", text: "Memory (1604)" },
    { key: "G", text: "Memory (1701)" },
    { key: "H", text: "Memory (1702)" },
    { key: "I", text: "Memory (1703)" },
    { key: "J", text: "Memory (1704)" },
    { key: "K", text: "Wireless chip (U5001)" },
    { key: "L", text: "Codec (U4009)" },
    { key: "M", text: "Codec (U4301)" },
    { key: "N", text: "USB Controller (U7902)" },
    { key: "O", text: "Touch (U3002 )" },
    { key: "P", text: "Touch (U3003)" },
    { key: "Q", text: "Touch (3004)" },
    { key: "R", text: "Touch (3006)" },
    { key: "S", text: "ODD power cable" },
    { key: "T", text: "ODD SATA cable" },
    { key: "U", text: "BTB FPC Bottom housing" },
    { key: "V", text: "Top housing" },
    { key: "W", text: "ODD" },
    { key: "X", text: "Fan" },
    { key: "Y", text: "PSU" },
    { key: "Z", text: "Nexus" },
    { key: "AA", text: "WIFI" },
    { key: "AB", text: "ACCY" },
    { key: "AC", text: "ASSY Process" },
    { key: "AD", text: "Others" }
];

export const FailureModeOptions = [
    { key: 'A', text: 'Functional' },
    { key: 'B', text: 'Cosmetic' },
];
