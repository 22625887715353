import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { getAppInsights } from "../AppInsights/TelemetryService";

export const AccessDenied = () => {
    const appInsights = getAppInsights();
    appInsights && appInsights.trackTrace({
        message: "Access Denied for user.",
    });
    return (
        <div className="container mt-3" data-testid="access-denied-container">
            <div className="row justify-content-md-center">
                <div className="jumbotron jumbotron-fluid  p-2 pt-5 pb-5 mb-2 text-dark rounded-circle">
                    <div className="container text-center">
                        <h1>403</h1>
                        <p>Access Denied/Forbidden</p>
                    </div>
                </div>
            </div>
            <div className="text-center mt-sm-5">
                <p>
                    <Trans i18nKey="accessDenied.contactMessage" /> <a className="font-weight-bold" rel="noopener noreferrer" href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=9l3A2u">ICM ticket</a> <Trans i18nKey="accessDenied.assigneMessage" /> <span className="font-weight-bold"> Make Repair/Incident Manager </span>. <br />
                </p>
            </div>
        </div>
    );
};

export default withTranslation()(AccessDenied);
