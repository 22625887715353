import React from "react";
const Help = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="content-navigation col-lg-3 col-md-3 col-sm-4 col-xs-6">
          <nav
            style={{position: "fixed"}}
            className="docs-sidebar"
            data-spy="affix"
            data-offset-top="300"
            data-offset-bottom="200"
            role="navigation">
            <ul className="nav">
              <br />
              <li>
                <button className="btn btn-link" style={{color: "white"}}>Need Access?</button>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content col-lg-9 col-md-9 col-sm-8 col-xs-6 col-6">
          {/* <h1 id="rnext-documentation">RNext documentation</h1> */}
          <h2 id="needaccess">Need access?</h2>

          <p>
            Please raise an incident at the{" "}
                      <a href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=9l3A2u" target="_blank" rel="noopener noreferrer">
              IcM Portal
            </a>
            . <br />
            Assign the ticket to <strong>Make Repair/Incident Manager</strong>. <br />
            Please select the environment applicable as <code>PROD</code> for production or TEST for <code>UAT</code> testing
          </p>

          <p>
            After completion of access request, if you are still not able to access the RNext application.
            <br />
            Please follow the below troubleshooting steps :
          </p>
          <ul>
            <li>Please Log off from the session</li>

            <li>Clean up the browsing history</li>

            <li>Try to login back again.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Help;
