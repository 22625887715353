import {
    GET_DEBUG_DETAILS_SUCCESS,
    SAVE_REPAIR_ACTION_SUCCESS,
    SET_CURRENT_ERROR_CODE,
    UNASSIGN_REPAIR_ACTION_SUCCESS,
} from "../actions/types";

import { assingRAInitialState } from "../states/repairActionsState";

export function getRepairConditions(repairActions) {
    var allRepairConditions = repairActions.map((r) => r.RepairCondition);
    return allRepairConditions
        .filter(function (item, pos) {
            return allRepairConditions.indexOf(item) === pos;
        })
        .map((item) => {
            return { key: item, name: item };
        });
}

export function repairActions(state = assingRAInitialState, action) {
    switch (action.type) {
        case GET_DEBUG_DETAILS_SUCCESS: {
            if (
                action.payload.data.Response.RepairActionManagerResults &&
                action.payload.data.Response.RepairActionManagerResults.DebugDetails
            ) {
                var { Response } = action.payload.data;
                return Object.assign({}, state, {
                    debugDetails: Response.RepairActionManagerResults.DebugDetails.map(
                        (item) => {
                            return {
                                errorCode: item.ErrorCode,
                                operation: item.Operation,
                                errorCodeDetails: item,
                                repairConditions: getRepairConditions(item.RepairActions),
                                formValue:
                                    Response.AssignRepair.ErrorDetails !== undefined &&
                                    Response.AssignRepair.ErrorDetails.find(
                                        (savedDetail) =>
                                            savedDetail.ErrorCode.trim().toUpperCase() === item.ErrorCode.trim().toUpperCase() && savedDetail.Operation.toUpperCase() === item.Operation.toUpperCase()) || [],
                            };
                        }
                    ),
                });
            } else {
                return state;
            }
        }
        case SET_CURRENT_ERROR_CODE: {
            var currentDebug = state.debugDetails.find(
                (debugInfo) => debugInfo.errorCode.trim().toUpperCase() === action.errorCode.trim().toUpperCase() && debugInfo.operation.toUpperCase() === action.operation.toUpperCase()
            );
            if (currentDebug === undefined) {
                currentDebug = {
                    currentRepairConditions: [],
                };
            }
            return Object.assign({}, state, {
                currentErrorCode: currentDebug.errorCode,
                currentOperation: currentDebug.operation,
                currentDebugDetails: currentDebug.errorCodeDetails,
                currentDebugFormData: currentDebug.formValue,
                currentRepairConditions: currentDebug.repairConditions
            });
        }
        case SAVE_REPAIR_ACTION_SUCCESS: {
            const errorDetails = action.payload.data.Response.ErrorDetails;
            const newState = Object.assign({}, state);
            errorDetails.map((errorDetail) => {
                const filteredErrorCodeDetails = newState.debugDetails.find(
                    (debugDetail) => debugDetail.errorCode.toUpperCase() === errorDetail.ErrorCode.toUpperCase() && debugDetail.operation.toUpperCase() === errorDetail.Operation.toUpperCase()
                );
                if (filteredErrorCodeDetails !== undefined) {
                    filteredErrorCodeDetails.formValue.RepairActions =
                        errorDetail.RepairActions;
                    filteredErrorCodeDetails.formValue.DebugFlow = errorDetail.DebugFlow;
                    filteredErrorCodeDetails.formValue.DebugFlowName =
                        errorDetail.DebugFlowName;
                }
            });
            const currentDebug = newState.debugDetails.find(
                (debugDetail) => debugDetail.errorCode.toUpperCase() === newState.currentErrorCode.toUpperCase() && debugDetail.operation.toUpperCase() === newState.currentOperation.toUpperCase()
            );
            return Object.assign({}, newState, {
                currentDebugDetails: currentDebug.errorCodeDetails,
                currentDebugFormData: currentDebug.formValue,
                currentRepairConditions: currentDebug.repairConditions,
            });
        }

        case UNASSIGN_REPAIR_ACTION_SUCCESS: {
            return Object.assign({}, state, {
                currentDebugFormData: null,
                debugDetails: state.debugDetails.map((debug) => {
                    debug.formValue = {};
                    return debug;
                }),
            });
        }
        default:
            return state;
    }
}
