import React, { Component } from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { sfcsAckAction, sfcsAckActionNotReceived } from '../../../store/actions/details';

const intervalDelay = 1000;
const totalIntervalInSeconds = window.env.WAIT_TIME_FOR_SFCS_ACK;
const intervalIncrement = 1.0 / totalIntervalInSeconds;

class ProgressWithTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percentComplete: 0,
            isCompleted: false,
            incrementTimes: 0
        };
    }

    componentDidMount() {
        this.props.sfcsAckAction();
        const { sfcsAckActionState } = this.props;
        console.log("sfcsAckActionState", sfcsAckActionState);
        this.intervalId = setInterval(() => {
            this.setState((prevState) => {
                const newPercentComplete = (intervalIncrement + prevState.percentComplete) % 1;
                if (prevState.incrementTimes === totalIntervalInSeconds - 1 && prevState.percentComplete !== 0) {
                    clearInterval(this.intervalId);
                    this.props.sfcsAckActionNotReceived();
                    return { percentComplete: newPercentComplete, isCompleted: true };
                }
                return { percentComplete: newPercentComplete, incrementTimes: prevState.incrementTimes + 1 };
            });
        }, intervalDelay);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        const { sfcsAckActionState } = this.props;
        const { percentComplete, isCompleted } = this.state;

        if (isCompleted || sfcsAckActionState !== "LOAD_START") {
            return null;
        }

        return (
            <ProgressIndicator
                description={`${Math.round(totalIntervalInSeconds - totalIntervalInSeconds * percentComplete)} seconds remaining`}
                label={this.props?.title}
                percentComplete={percentComplete}
            />
        );
    }
}

const mapStateToProps =({ searchSettings, debugDetails }) => ({
    isD: searchSettings.isEventBased,
    sfcsAckActionState: debugDetails.sfcsAckActionState
});

export default withTranslation()(
    connect(mapStateToProps, {
        sfcsAckAction,
        sfcsAckActionNotReceived
    })(ProgressWithTime)
);

ProgressWithTime.propTypes = {
    title: PropTypes.string,
    sfcsAckAction: PropTypes.func,
    sfcsAckActionNotReceived: PropTypes.func,
    sfcsAckActionState: PropTypes.string
};
