import { Message } from "../../../constants/message";
import { RepairState } from "../../../constants/repairState";
import {
    GET_DEBUG_DETAILS_SUCCESS,
    LOAD_START,
    LOAD_END,
    POST_REPAIR_RECORD,
    POST_REPAIR_RECORD_FAIL,
    POST_REPAIR_RECORD_SUCCESS,
    FINISH_REPAIR,
    FINISH_REPAIR_FAIL,
    FINISH_REPAIR_SUCCESS,
    REMOVE_COMPONENT,
    REMOVE_COMPONENT_FAIL,
    REMOVE_COMPONENT_SUCCESS,
    RecordMessageType,
    ASSIGN_REPAIR_ACTION_SUCCESS,
    ErrorMessageForSource,
    ReplaceNonCriticalActionCode,
    Reassemble,
    SFCS_ACK_RECEIVED,
    LOAD_INITIAL,
    SFCS_ACK_NOT_RECEIVED,
    CLEAR_REPAIR_ACTION_STATE,
    ASSIGN_REPAIR_ACTION,
} from "../../actions/types";

import { getDefaultRepairRecord } from "../../states/repairRecordState"

const handleFailScenario = function (state, action) {
    const response = action.error.response;
    let message = Message.RecordRepairError;
    let messageSubtitle = null;

    if (response
        && response.data
        && response.data.Errors
        && response.data.Errors.length > 0) {
        const error = response.data.Errors[0];
        message = error.ErrorMessage;
        messageSubtitle = ErrorMessageForSource[error.Target]
    }
    const newState = Object.assign({}, state, {
        ...state,
        recordState: LOAD_END,
        finishState: LOAD_END,
        isRemoveLoading: false,
        message: message,
        messageSubtitle: messageSubtitle,
        messageType: RecordMessageType.ERROR
    });
    return newState;
}



const handleFailScenarioOkResponse = function (state, action) {
    const newState = Object.assign({}, state, {
        ...state,
        recordState: LOAD_END,
        isRemoveLoading: false,
        finishState: LOAD_END,
        message: action.payload.data.Errors[0].ErrorMessage,
        messageSubtitle: ErrorMessageForSource[action.payload.data.Errors[0].Target],
        messageType: RecordMessageType.ERROR,
        lastActionAssignRA: false
    });
    return newState;
}

const getRepairRecordState = function (state, action) {
    switch (action.type) {
        case POST_REPAIR_RECORD:
            {
                const newState = Object.assign({}, state, {
                    ...state,
                    recordState: LOAD_START,
                    message: null,
                    messageSubtitle: null,
                });

                return newState;
            }
        case POST_REPAIR_RECORD_FAIL:
            {
                return handleFailScenario(state, action);
            }

        case POST_REPAIR_RECORD_SUCCESS:
            {
                if (action.payload.data.Errors.length > 0) {
                    return handleFailScenarioOkResponse(state, action);
                } else {
                    const response = action.payload.data.Response;
                    const newState = Object.assign({}, state, {
                        recordState: LOAD_END,
                        repairComponents: (response && response.RecordRepair) ? response.RecordRepair : [],
                        message: Message.SuccessfullRecordRepair,
                        messageSubtitle: null,
                        messageType: RecordMessageType.SUCCESS
                    });
                    return newState;
                }
            }
    }

}



const getFinishRepairState = function (state, action) {
    switch (action.type) {
        case FINISH_REPAIR:
            {
                const newState = Object.assign({}, state, {
                    finishState: LOAD_START,
                    message: null,
                });
                return newState;
            }
        case FINISH_REPAIR_FAIL:
            {
                return handleFailScenario(state, action);
            }

        case FINISH_REPAIR_SUCCESS:
            {

                if (action.payload.data.Errors.length > 0) {
                    return handleFailScenarioOkResponse(state, action);
                } else {
                    const isRepairFinished = action.payload.data?.Response?.RepairState === RepairState.RepairComplete ? true : false;
                    const newState = Object.assign({}, state, {
                        ...state,
                        finishState: LOAD_END,
                        isRepairFinished: isRepairFinished,
                        message: isRepairFinished ? Message.RepairFinished: "",
                        messageType: isRepairFinished ? RecordMessageType.SUCCESS: "",
                        lastActionAssignRA: false
                    });
                    
                    return newState;
                }

            }
    }

}


const getRemoveComponentState = function (state, action) {
    switch (action.type) {
        case REMOVE_COMPONENT:
            {
                const newState = Object.assign({}, state, {
                    isRemoveLoading: true,
                    message: null,
                });
                return newState;
            }
        case REMOVE_COMPONENT_FAIL:
            {
                return handleFailScenario(state, action);
            }

        case REMOVE_COMPONENT_SUCCESS:
            {

                if (action.payload.data.Errors.length > 0) {
                    return handleFailScenarioOkResponse(state, action);
                } else {
                    const response = action.payload.data.Response;
                    const newState = Object.assign({}, state, {
                        isRemoveLoading: false,
                        repairComponents: (response && response.RecordRepair) ? response.RecordRepair : [],
                        message: Message.SuccessfullyRemovedComponent,
                        messageSubtitle: null,
                        messageType: RecordMessageType.SUCCESS
                    });
                    return newState;
                }

            }
    }

}


export function toShowAddNonCriticalView(response) {
    const errorDetails = response?.AssignRepair?.ErrorDetails;

    if (!errorDetails) return false;

    if (errorDetails
        .find(errorDetail =>
            errorDetail.RepairActions
                ?.find(repairAction =>
                    repairAction.RepairActionCode?.toUpperCase() === ReplaceNonCriticalActionCode?.toUpperCase()))) return true;
    return false;
}

export function getErrorMessage(errors) {
    let message = Message.SFCSAcknowledgementError + "\n";
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            message = message + error.Target+ " - " + error.ErrorMessage + "\n";
        });
    }
    return message;
}

export function processSignalRMessage(state, action) {
    const data = JSON.parse(action.name);
    const response = data.Response;
    const errors = data.Errors;
    if (state.lastActionAssignRA) {
        return state;
    }
    // Fail
    if (errors && errors.length > 0) {
        return Object.assign({}, state, {
            serialNumber: data.SerialNumber,
            recordState: LOAD_END,
            finishState: LOAD_END,
            isRepairFinished: false,
            message: getErrorMessage(errors),
            messageType: RecordMessageType.ERROR,
            repairComponents: (response && response.RecordRepair) ? response.RecordRepair : [],
            toShowAddNonCriticalView: toShowAddNonCriticalView(response),
            toShowReassembleView: toShowReassembleView(response)
        });
    }
    // Success
    const newState = Object.assign({}, state, {
        serialNumber: data.SerialNumber,
        recordState: LOAD_END,
        finishState: LOAD_END,
        isRepairFinished: response.RepairState === "RepairComplete" ? true : false,
        repairComponents: (response && response.RecordRepair) ? response.RecordRepair : [],
        toShowAddNonCriticalView: toShowAddNonCriticalView(response),
        toShowReassembleView: toShowReassembleView(response)
    });
    return newState;
}


export function toShowReassembleView(response) {
    const errorDetails = response?.AssignRepair?.ErrorDetails;

    if (!errorDetails) return false;

    if (errorDetails
        .find(errorDetail =>
            errorDetail.RepairActions
                ?.find(repairAction =>
                    repairAction.RepairActionCode && repairAction.RepairActionCode?.toUpperCase() === Reassemble))) return true;
    return false;
}

export function repairRecord(state = getDefaultRepairRecord(), action) {
    switch (action.type) {
        case GET_DEBUG_DETAILS_SUCCESS: {
            const response = action.payload.data.Response;
            return Object.assign({}, state, {
                serialNumber: action.payload.data.SerialNumber,
                recordState: LOAD_INITIAL,
                finishState: LOAD_INITIAL,
                isRepairFinished: false,
                repairComponents: (response && response.RepairDetails && response.RepairDetails.RecordRepair) ? response.RepairDetails.RecordRepair : [],
                toShowAddNonCriticalView: toShowAddNonCriticalView(response),
                toShowReassembleView: toShowReassembleView(response),
                message: null,
                messageSubtitle: null
            });
        }
        case ASSIGN_REPAIR_ACTION: {
            return Object.assign({}, state, {
                lastActionAssignRA: true,
            });
        }
        case ASSIGN_REPAIR_ACTION_SUCCESS: {
            const response = action.payload.data.Response;
            return Object.assign({}, state, {
                serialNumber: action.payload.data.SerialNumber,
                recordState: LOAD_INITIAL,
                finishState: LOAD_INITIAL,
                isRepairFinished: false,
                repairComponents: (response && response.RecordRepair) ? response.RecordRepair : [],
                toShowAddNonCriticalView: toShowAddNonCriticalView(response),
                toShowReassembleView: toShowReassembleView(response)
            });
        }
        case POST_REPAIR_RECORD:
        case POST_REPAIR_RECORD_FAIL:
        case POST_REPAIR_RECORD_SUCCESS:
            {
                return getRepairRecordState(state, action);
            }
        case FINISH_REPAIR:
        case FINISH_REPAIR_FAIL:
        case FINISH_REPAIR_SUCCESS:
            {
                return getFinishRepairState(state, action);
            }

        case REMOVE_COMPONENT:
        case REMOVE_COMPONENT_FAIL:
        case REMOVE_COMPONENT_SUCCESS:
            {
                return getRemoveComponentState(state, action);
            }
        case SFCS_ACK_RECEIVED: {
            return processSignalRMessage(state, action);
        }
        case SFCS_ACK_NOT_RECEIVED: {
            return Object.assign({}, state, {
                message: "No acknowledgement received from SFCS. Please try again.",
                messageType: RecordMessageType.ERROR,
            });
        }
        case CLEAR_REPAIR_ACTION_STATE: {
            return Object.assign({}, state, {
                recordState: LOAD_INITIAL,
                finishState: LOAD_INITIAL,
            });
        }
            
        default:
            return state;
    }
}