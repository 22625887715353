import React, { Component } from 'react';
import { PropTypes } from "prop-types";
import { Callout, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Trans } from 'react-i18next';
class ButtonPrimary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCallOutVisible: false,
        };
    }

    render() {


        const { isDisabled, isLoading, onClickEvent, loadingLabel, buttonLabel, disableMessage, dataTestid, ariaLabel } = this.props;

        return (
            <div
                onMouseEnter={() => { if (isDisabled) this.setState({ isCallOutVisible: true }) }}
                onMouseLeave={() => { this.setState({ isCallOutVisible: false }) }}
            >
                <button data-testid={dataTestid} aria-label={ariaLabel} type="button" className={'primary__button' + (isDisabled ? ' btn_disabled' : '') + (isLoading ? ' btn-loading' : '')}
                    aria-disabled={isDisabled}
                    onClick={() => { if (!isDisabled) { onClickEvent(); } }}
                    ref={e => (this.recordButton = e)}>
                    <span>{
                        isLoading ?
                            [<Spinner key="spinner" size={SpinnerSize.medium} />, <span key="loading-text" className="loading-text">{loadingLabel}</span>] :
                            <Trans i18nKey={buttonLabel} />
                    }
                    </span>
                </button>
                {
                    this.state.isCallOutVisible && (
                        <Callout key="record-button-callout" className="callout" role="alertdialog" target={this.recordButton} calloutMaxWidth={300}>
                            <p className="ms-font-s-plus mh-1">
                                <Trans i18nKey={disableMessage} />
                            </p>
                        </Callout>
                    )
                }
            </div >
        );
    }
}


ButtonPrimary.propTypes = {
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClickEvent: PropTypes.func,
    buttonLabel: PropTypes.string,
    loadingLabel: PropTypes.string,
    disableMessage: PropTypes.string,
    dataTestid: PropTypes.string,
    ariaLabel: PropTypes.string
};


export default ButtonPrimary;