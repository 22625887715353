import React from "react";
import * as ReactUltimatePagination from "react-ultimate-pagination";
import { PropTypes } from "prop-types";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";

Page.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.string
};
function Page(props) {
  return <DefaultButton primary={props.isActive} onClick={props.onClick} text={props.value} />;
}

Ellipsis.propTypes = {
    onClick: PropTypes.func,
};
function Ellipsis(props) {
  return <DefaultButton onClick={props.onClick} text="..." />;
}

FirstPageLink.propTypes = {
    onClick: PropTypes.func,
};
function FirstPageLink(props) {
  return (
    <DefaultButton
      onClick={props.onClick}
      iconProps={{ iconName: "DoubleChevronLeft", styles: { root: { color: "black" } } }}
    />
  );
}

PreviousPageLink.propTypes = {
    onClick: PropTypes.func,
};
function PreviousPageLink(props) {
  return (
    <DefaultButton
      onClick={props.onClick}
      iconProps={{ iconName: "ChevronLeft", styles: { root: { color: "black" } } }}
    />
  );
}

NextPageLink.propTypes = {
    onClick: PropTypes.func,
};
function NextPageLink(props) {
  return (
    <DefaultButton
      onClick={props.onClick}
      iconProps={{ iconName: "ChevronRight", styles: { root: { color: "black" } } }}
    />
  );
}

LastPageLink.propTypes = {
    onClick: PropTypes.func,
};
function LastPageLink(props) {
  return (
    <DefaultButton
      onClick={props.onClick}
      iconProps={{ iconName: "DoubleChevronRight", styles: { root: { color: "black" } } }}
    />
  );
}

Wrapper.propTypes = {
    children: PropTypes.object,
};
function Wrapper(props) {
  return <div className="pagination">{props.children}</div>;
}

var itemTypeToComponent = {
  PAGE: Page,
  ELLIPSIS: Ellipsis,
  FIRST_PAGE_LINK: FirstPageLink,
  PREVIOUS_PAGE_LINK: PreviousPageLink,
  NEXT_PAGE_LINK: NextPageLink,
  LAST_PAGE_LINK: LastPageLink,
};

var Pagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper,
});

export default Pagination;
