import { combineReducers } from "redux";
import { debugDetails } from './common/common';
import { repairActions } from "./repairActions";
import { searchSettings } from './search';
import { repairRecord } from './repairAndRecord/repairRecordReducer';

export default combineReducers({
    searchSettings,
    debugDetails,
    repairActions,
    repairRecord
});
