import React, { Component } from "react";
import "./common.scss";
import { SelectionMode, DetailsList } from "office-ui-fabric-react";
import { PropTypes } from "prop-types";
import Pagination from './Pagination/Pagination';
import moment from 'moment';

class Grid extends Component {
    constructor(props) {
        super(props);
        this.gridState = {};
        this.buildPageSizes = this.buildPageSizes.bind(this);
        this.processColumns = this.processColumns.bind(this);
        this.fetchPagedData = this.fetchPagedData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.initialize = this.initialize.bind(this);
        this.state = {
            refreshGrid: false
        };


        this.initialize();
    }

    initialize() {
        const { DEFAULT_GRID_PAGE_SIZE } = window.env.DEBUG_DETAILS;
        this.gridState = {
            pageState: {
                sort: { key: "", isDescending: false },
                pagable: false,
                currentPage: 1,
                pageSize: this.props.pageSize !== undefined ? parseInt(this.props.pageSize) : parseInt(DEFAULT_GRID_PAGE_SIZE),
                totalPages: 1,
            },
            gridData: {
                sortedItems: this.props.items,
                currentPageItems: []
            },
            refresh: true
        };
        this.buildPageSizes();
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.columns !== this.props.columns) {
            this.gridState.refresh = true;
        }
        return true;
    }


    render() {
        if (this.gridState.refresh === true) {
            this.processColumns();
            this.gridState.gridData.sortedItems.sort((a, b) => {
                const keyA = a[this.gridState.pageState.sort.key];
                const keyB = b[this.gridState.pageState.sort.key];
                const sortOrder = this.gridState.pageState.sort.isDescending ? -1 : 1;

                if (!keyB || keyA < keyB) return -1 * sortOrder;
                if (!keyA || keyA > keyB) return 1 * sortOrder;
                return 0;
            });
            this.gridState.refresh = false;
        }

        if (this.gridState.gridData.sortedItems) {
            this.fetchPagedData();
        }
        return (
            <div className="grid__container" data-testid="grid-container">
                <DetailsList
                    data-testid="grid-display"
                    items={this.gridState.gridData.currentPageItems}
                    compact={false}
                    columns={this.props.columns}
                    selectionMode={SelectionMode.none}
                    onRenderRow={(row, defaultRender) => {
                        if (row.item["isHighlighted"]) {
                            row.styles = {
                                root: {
                                    backgroundColor: "rgb(229 242 252)"
                                }
                            }
                        }
                        return defaultRender(row);
                    }}
                    setKey="none"
                    isHeaderVisible={true}
                />
                {this.gridState.pageState.pagable ?
                    <Pagination
                        currentPage={this.gridState.pageState.currentPage}
                        totalPages={this.gridState.pageState.totalPages}
                        onChange={(page) => { this.onPageChange(page); }}
                    />
                    : null}
            </div>);
    }

    onPageChange(page) {
        this.gridState.pageState.currentPage = page;
        this.setState({
            refreshGrid: !this.state.refreshGrid
        })
    }

    processColumns() {
        this.props.columns.forEach(column => {
            if (column.isSorted) {
                this.gridState.pageState.sort = { key: column.key, isDescending: column.isSortedDescending }
            }
            column.onColumnClick = this.onColumnClick;
        });
    }

    buildPageSizes() {
        const totalPages = Math.ceil(this.props.items.length / this.gridState.pageState.pageSize);
        this.gridState.pageState.totalPages = totalPages;
        this.gridState.pageState.pagable = this.props.isPaginationEnable && totalPages > 1;
    }

    onColumnClick(event, col) {
        let { sortedItems } = this.gridState.gridData;
        let columns = this.props.columns.slice();
        const column = columns.filter(currCol => col.key === currCol.key)[0];
        if (column.noSorting) {
            event.preventDefault();
        } else {
            if (sortedItems && columns) {
                columns.forEach((newCol) => {
                    if (newCol === column) {
                        column.isSortedDescending = !column.isSortedDescending;
                        column.isSorted = true;
                    } else {
                        newCol.isSorted = false;
                        newCol.isSortedDescending = true;
                    }
                });

                let columnData = sortedItems.filter(item => item[column.fieldName] !== '' &&
                    item[column.fieldName] !== null && item[column.fieldName] !== undefined);

                columnData = columnData.filter((item, pos, array) => {
                    return array
                        .map(mapItem => mapItem[column.fieldName]).indexOf(item[column.fieldName]) === pos;
                });

                if (columnData.length === 1) {
                    sortedItems = sortedItems.sort((a) => {
                        if (a[column.fieldName] === null || a[column.fieldName] === undefined ||
                            a[column.fieldName] === '') {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                } else if (columnData.length > 1) {
                    sortedItems = sortedItems.sort((a, b) => {
                        let firstValue, secondValue;
                        if (a[column.fieldName] === null || a[column.fieldName] === undefined ||
                            a[column.fieldName] === '') {
                            return 1;
                        } else if (b[column.fieldName] === null || b[column.fieldName] === undefined ||
                            b[column.fieldName] === '') {
                            return -1;
                        } else {
                            // To check the data if it is a boolean
                            if (typeof a[column.fieldName] === 'boolean' && typeof b[column.fieldName] === 'boolean') {
                                firstValue = a[column.fieldName];
                                secondValue = b[column.fieldName];
                                // To check the data is of Number type
                            } else if (!isNaN(a[column.fieldName]) && !isNaN(a[column.fieldName])) {
                                firstValue = a[column.fieldName];
                                secondValue = b[column.fieldName];
                                // To check the data is of Date type
                            } else if (moment(a[column.fieldName], moment.defaultFormat, true).isValid() &&
                                moment(b[column.fieldName], moment.defaultFormat, true).isValid()) {
                                firstValue = moment(a[column.fieldName]);
                                secondValue = moment(b[column.fieldName]);
                                // The rest is expected to be string
                            } else {
                                firstValue = a[column.fieldName].toLowerCase();
                                secondValue = b[column.fieldName].toLowerCase();
                            }
                        }
                        if (column.isSortedDescending) {
                            return firstValue > secondValue ? -1 : 1;
                        } else {
                            return firstValue > secondValue ? 1 : -1;
                        }
                    });
                } else {
                    event.preventDefault();
                }
                // Reset the items and columns to match the state.
                if (sortedItems.length > 0) {
                    this.gridState.pageState.sort = { key: column.key, isDescending: column.isSortedDescending };
                    this.gridState.gridData.sortedItems = sortedItems;

                    this.setState({
                        refreshGrid: !this.state.refreshGrid
                    })
                }
            }
        }
    }

    fetchPagedData() {
        if (this.gridState.pageState.pagable) {
            this.gridState.gridData.currentPageItems = this.gridState.gridData.sortedItems.slice((this.gridState.pageState.currentPage - 1) * this.gridState.pageState.pageSize, this.gridState.pageState.currentPage * this.gridState.pageState.pageSize);
        }
        else {
            this.gridState.gridData.currentPageItems = this.gridState.gridData.sortedItems.slice();
        }
    }
}

Grid.propTypes = {
    columns: PropTypes.array,
    isPaginationEnable: PropTypes.bool,
    pageSize: PropTypes.string,
    items: PropTypes.array
};

export default Grid;

