import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { detect } from 'detect-browser';
let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (instrumentationKey, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./TelemetryProvider.js')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
        const {
            ServiceOfferingName,
            ServiceLineName,
            ServiceName,
            ComponentName,
            ComponentId
        } = window.env.SERVICE_HIERARCHY;

        const browser = detect();

        const {
            ENVIRONMENT
        } = window.env;

        appInsights.addTelemetryInitializer(function (envelope) {
            envelope.data["ServiceOffering"] = ServiceOfferingName;
            envelope.data["ServiceLine"] = ServiceLineName;
            envelope.data["Service Name"] = ServiceName;
            envelope.data["ComponentName"] = ComponentName;
            envelope.data["EnvironmentName"] = ENVIRONMENT;
            envelope.data["ComponentId"] = ComponentId;
            envelope.data["Browser"] = browser.name;
            envelope.data["UserEmail"] = window.msal ? window.msal.account.userName: "Undefined user";
        });
    };

    return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => { return appInsights; };