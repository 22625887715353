
export const errorCodeDebugInfo = {
    errorCode: "",
    debugFlow: [],
    repairConditions: [],
    debugAction: {
        repairConditions: [],
        debugActions: [],
        debugFlow: "",
        comments: "",
        debonding: [],
        nextStep: "",
        nextStepList: [],
        fullNextStepList: []
    }

}

export const assingRAInitialState = {
    debugDetails: [],
    currentDebugDetails: null,
    currentDebugFormData: null,
    currentRepairConditions: []
}

export const debugFormDataInitialState = {
    ErrorCode: "",
    DebugFlowName: "",
    Comment: "",
    Debonding: "",
    NextStep: "",
    RepairActions: [],
    NextStepList: [],
    fullNextStepList: [],
}

export const debugDetailsResponse = [{
    ErrorCode: "PASS",
    Description: null,
    State: "Final Assembly",
    Operations: ["LC_AInLineCaAD"],
    DebugFlowName: "0x80f8083e_Lac_544fd6_debug_1",
    Attachments: [{
        lang: "en",
        url: "http://placehold.it/500x500?text=English-1"
    }, {
        lang: "zh-Hans",
        url: "http://placehold.it/500x500?text=Chinese-1"
    }],
    RepairActions: [{
        Name: "Replace TDM",
        RepairCondition: "Replace TDM",
        DebugFlow: "",
        Location: "Mother Board",
        ComponentType: "Defective",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Cover as COI",
        RepairCondition: "Replace MB",
        DebugFlow: "",
        Location: "TDM",
        ComponentType: "Collateral",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Keypad",
        RepairCondition: "Replace KP",
        DebugFlow: "",
        Location: null,
        ComponentType: "FalseFaliure",
        DebugFlowStep: ""
    }]
},
{
    ErrorCode: "BTFU1",
    Description: null,
    State: "Final Assembly",
    Operations: ["LC_AInLineCaAD"],
    DebugFlowName: "0x80f8083e_Lac_544fd6_debug_1",
    Attachments: [{
        lang: "en",
        url: "http://placehold.it/500x500?text=English-2"
    }, {
        lang: "zh-Hans",
        url: "http://placehold.it/500x500?text=Chinese-2"
    }],
    RepairActions: [{
        Name: "Replace MBA",
        RepairCondition: "Replace MB",
        DebugFlow: "",
        Location: "Mother Board A",
        ComponentType: "Defective",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Cover as COI-1",
        RepairCondition: "Replace TDM",
        DebugFlow: "",
        Location: "TDM",
        ComponentType: "Collateral",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Cover as COI-2",
        RepairCondition: "Replace TDM",
        DebugFlow: "",
        Location: "TDM",
        ComponentType: "Collateral",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Keypad-1",
        RepairCondition: "Replace KP",
        DebugFlow: "",
        Location: null,
        ComponentType: "FalseFaliure",
        DebugFlowStep: ""
    }]
}]


export const debugActionFormValue = [
{
    ErrorCode: "PASS",
    Comment: "Cooo",
    Debonding: ["FCC"],
    NextStep: "",
    NextStepList: [],
    fullNextStepList: [],

    DebugFlowName: "0x80f8083e_Lac_544fd6_debug_1",
    RepairActions: [{
        Name: "Replace MBA",
        RepairCondition: "Replace MB",
        DebugFlow: "",
        Location: "Mother Board A",
        ComponentType: "Defective",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Cover as COI-1",
        RepairCondition: "Replace TDM",
        DebugFlow: "",
        Location: "TDM",
        ComponentType: "Collateral",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Cover as COI-2",
        RepairCondition: "Replace TDM",
        DebugFlow: "",
        Location: "TDM",
        ComponentType: "Collateral",
        DebugFlowStep: ""
    },
    {
        Name: "Replace Keypad-1",
        RepairCondition: "Replace KP",
        DebugFlow: "",
        Location: null,
        ComponentType: "FalseFaliure",
        DebugFlowStep: ""
    }]
}]