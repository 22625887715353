import React, { Component } from "react";
import {
  msalApp,
  requiresInteraction,
  isIE,
  GRAPH_REQUESTS,
} from "./AuthUtils";
import { AuthConfig } from "./AuthConfig";
import { WindowUtils } from "msal";

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
//const useRedirectFlow = true;

export default (WrappedComponent) =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
        accessToken: null
      };
    }

    async acquireToken(request, redirect) {
      return msalApp.acquireTokenSilent(request).catch((error) => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        if (requiresInteraction(error.errorCode)) {
          return redirect
            ? msalApp.acquireTokenRedirect({
                ...request,
                redirectUri: AuthConfig.redirectUri,
              })
            : msalApp.acquireTokenPopup(request);
        } else {
          console.error("Non-interactive error:", error);
        }
      });
    }

    async onSignIn(redirect) {
      if (redirect) {
        return msalApp.loginRedirect({
          ...GRAPH_REQUESTS.LOGIN,
          redirectUri: AuthConfig.redirectUri,
        });
      }

      const loginResponse = await msalApp
        .loginPopup(GRAPH_REQUESTS.LOGIN)
        .catch((error) => {
          this.setState({
            error: error.message,
          });
        });

      if (loginResponse) {
        this.setState({
          account: loginResponse.account,
          error: null,
        });

        const tokenResponse = await this.acquireToken(
          GRAPH_REQUESTS.TOKEN
        ).catch((error) => {
          this.setState({
            error: error.message,
          });
        });
        if (tokenResponse) {
            this.setState({
                accessToken: tokenResponse.accessToken
            });
        }
      }
    }

    onSignOut() {
      msalApp.logout();
    }

    async componentDidMount() {
      msalApp.handleRedirectCallback((error) => {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : "Unable to acquire access token.";
          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage,
          });
        }
      });

      const account = msalApp.getAccount();

      if (account && !WindowUtils.isInIframe()) {
        const tokenResponse = await this.acquireToken(
          GRAPH_REQUESTS.TOKEN,
          useRedirectFlow
          );
          this.setState({
              accessToken: tokenResponse.accessToken
          });
      }
    }

    render() {
        const account = msalApp.getAccount();
        const { accessToken } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          account={account}
          accessToken={accessToken}
          error={this.state.error}
          // graphProfile={this.state.graphProfile}
          onSignIn={() => this.onSignIn(useRedirectFlow)}
          onSignOut={() => this.onSignOut()}
          // onRequestEmailToken={() => this.onRequestEmailToken()}
        />
      );
    }
  };
