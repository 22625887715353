import React, { Component } from "react";
import { getAppInsights } from "../AppInsights/TelemetryService";
import PropTypes from "prop-types";
import "./DebugDetails.scss";
import DutInfo from "../DutInfo/DutInfo";
import { withTranslation } from "react-i18next";
import OperationResult from "../OperationResult/OperationResult";
import DebugFlow from "../DebugFlow/DebugFlow";
import DebugAction from "../DebugAction/DebugAction";
import RepairResults from "../RepairResults/RepairResults";
import RepairAndRecord from "../RepairAndRecord/RepairAndRecord";
import { RepairState } from "../../constants/repairState";
import {
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize
} from "office-ui-fabric-react/lib/Pivot";
import i18n from "../../locate/i18n";
import { UserConsumer } from "../Authentication/UserContext";
import { UserRoles } from "../../constants/userRoles";
import conn from "../../signalR1";
import { onCloseConnection } from "../../signalR1";

const tabs = {
  Debug: "debug",
  AssignRA: "assign",
  RepairAndRecord: "repair"
}

export class DebugDetails extends Component {

  constructor(props) {
    super(props);
    this.defaultSelectedTab = tabs.Debug;
  }

  componentDidMount() {
    // Start a connection as soon as debug details are available
    conn(this.props.repairGuid);
  }

  componentWillUnmount() {
    onCloseConnection();
  }

  render() {
    const appInsights = getAppInsights();
    appInsights &&
      appInsights.trackTrace({
        message: "Debug Details Loaded"
      });

    const headerDisableState = {
      disabled: true
    };

    const { repairState } = this.props;

    return (
      <UserConsumer>
        {userContext => {
          if (
            userContext.roles.length >= 1 &&
            !userContext.roles.includes(UserRoles.SuperUser) &&
            !userContext.roles.includes(UserRoles.DebugEngineer) &&
            userContext.roles.includes(UserRoles.RepairEngineer)
          ) {
            this.defaultSelectedTab = tabs.RepairAndRecord;
          }

          return (
            <div data-testid="div-debug-details">
              <Pivot
                linkFormat={PivotLinkFormat.tabs}
                linkSize={PivotLinkSize.large}
                defaultSelectedKey={this.defaultSelectedTab}
              >
                <PivotItem
                  itemKey={tabs.Debug}
                  headerButtonProps={
                    userContext.roles.includes(UserRoles.SuperUser) ||
                    userContext.roles.includes(UserRoles.DebugEngineer)
                      ? null
                      : headerDisableState
                  }
                  onRenderItemLink= {() => <div role="heading" aria-level="2">{i18n.t("homeNavigations.debug")}</div>}
                >
                  <div>
                    <DutInfo />
                    <OperationResult />
                    <RepairResults />
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey={tabs.AssignRA}
                  headerButtonProps={
                    userContext.roles.includes(UserRoles.SuperUser) ||
                    userContext.roles.includes(UserRoles.DebugEngineer)
                      ? null
                      : headerDisableState
                  }
                  onRenderItemLink= {() => <div role="heading" aria-level="2">{i18n.t("homeNavigations.assignra")}</div>}
                >
                  <div>
                    <DutInfo displayMode={"repairAction"} />
                    <div className="action__repair__container">
                      <DebugFlow />
                      <DebugAction />
                    </div>
                  </div>
                </PivotItem>
                <PivotItem
                  itemKey={tabs.RepairAndRecord}
                  headerButtonProps={
                   (userContext.roles.includes(UserRoles.SuperUser) ||
                    userContext.roles.includes(UserRoles.RepairEngineer)) &&
                    ([RepairState.DebugComplete, RepairState.RepairSave, RepairState.RepairComplete].includes(repairState))
                      ? null
                      : headerDisableState
                  }
                  onRenderItemLink= {() => <div role="heading" aria-level="2">{i18n.t("homeNavigations.repairrecord")}</div>}
                >
                  <div className="record__repair__container">
                    <DutInfo displayMode={"recordAndRepair"}/>
                    <RepairAndRecord />
                  </div>
                </PivotItem>
              </Pivot>
            </div>
          );
        }}
      </UserConsumer>
    );
  }
}

const propTypes = {
  repairState: PropTypes.string,
  repairGuid: PropTypes.string
}

DebugDetails.propTypes = propTypes;

export default withTranslation()(DebugDetails);
