import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "../../styles/default.scss";
import "./RepairAndRecord.scss";
import PropTypes, { func } from "prop-types";
import { Nav } from "office-ui-fabric-react/lib/Nav";
import { Formik } from "formik";
import { SelectionMode, DetailsList } from "office-ui-fabric-react";
import RepairAndRecordForm from "./Forms/RepairAndRecordForm";
import CurrentComponentDetails from "./Forms/CurrentComponentDetails";
import AlertDialog, { AlertDialogButtonType as ButtonType } from "../Common/AlertDialog/AlertDialog";
import { postRecord, finishRepair, removeComponent, clearRepairActionStates } from "../../store/actions/repairRecord";
import { ResponseHandler } from "../Common/responseHandler";
import { MessageBarType } from "office-ui-fabric-react";
import { LOAD_END, LOAD_START, Reassemble, RecordMessageType } from "../../store/actions/types";
import { t } from "../../test-setup/i18next-test";
import { Message } from "../../constants/message";
import { RepairModeEnum } from "../../constants/repairMode";

import {
  getRepairInfoColumns,
  getInitial,
  isAlreadyRecorded,
  shouldDisableFinishButton,
  getNavLinkGroup,
  getCriticalComponentsList,
  ComponentLocationOptions,
} from "./RepairAndRecordUtils";
import ButtonPrimary from "../Common/ButtonPrimary";
import MessageView from "../Common/MessageView/MessageView";
import ProgressWithTime from "../Common/ProgressWithTime/ProgressWithTime";

export class RepairAndRecord extends Component {
  constructor(props) {
    super(props);
    this.formik = {};
    this.state = {
      selectedKey: 0,
      nextKey: 0,
      isRecordDisabled: true,
      isFinishDisabled: true,
      dialog: {
        showDialog: false,
        title: "",
        message: "",
        okButtonLabel: "ok",
        okButtonType: ButtonType.Default,
        okButtonIconName: "",
        cancelButtonLabel: null,
        onClickOk: func,
        onClickCancel: func,
      },
    };
  }

  showNotification(message, messageType) {
    if (messageType == RecordMessageType.SUCCESS) {
      ResponseHandler.handleSuccess("Success", true, message);
    } else {
      ResponseHandler.handleError("", true, message, "");
    }
  }

  prepareRepairFinishDialog() {
    const titleString = "Repair Finished";
    if (this.state.dialog.showDialog === true && this.state.dialog.title === titleString) return;
    this.setState({
      dialog: {
        title: titleString,
        message: "Repair another device?",
        okButtonLabel: "Ok",
        cancelButtonLabel: null,
        okButtonType: ButtonType.Success,
        showDialog: true,
        onClickOk: () => {
          document.querySelector("*[data-testid=navlink-home]").click();
        },
      },
    });
  }

  prepareShowFinishConfirmationDialog(finishRepair, serialNumber) {
    this.setState({
      dialog: {
        datatestid: "Finish Repair Confirmation dialog",
        title: t("messages.repairFinishDialogHeader"),
        message: t("messages.repairFinishConfirmationMessage"),
        okButtonLabel: t("popupButton.yes"),
        cancelButtonLabel: t("popupButton.cancel"),
        okButtonType: ButtonType.Success,
        showDialog: true,
        onClickOk: () => {
          this.cancelDialog();
          finishRepair(getFinishRepairRequest(serialNumber));
        },
        onClickCancel: () => {
          this.cancelDialog();
        },
      },
    });
  }

  prepareRemoveComponentDialog(removeComponent, serialNumber, location) {
    this.setState({
      dialog: {
        datatestid: "Remove Component Confirmation dialog",
        title: "Remove Component",
        message: "Are you sure, you want to remove this component?",
        okButtonLabel: "Yes",
        cancelButtonLabel: "Cancel",
        okButtonType: ButtonType.Success,
        showDialog: true,
        onClickOk: () => {
          this.cancelDialog();
          removeComponent(getRemoveComponentRequest({ serialNumber, location }));
        },
        onClickCancel: () => {
          this.cancelDialog();
        },
      },
    });
  }

  cancelDialog() {
    this.setState({ dialog: { ...this.state.dialog, showDialog: false } });
  }

  componentWillUnmount() {
    this.props.clearRepairActionStates();
  }


  componentDidUpdate(prevProps) {
    const { repairRecords } = this.props;
    const { message, messageType, recordState, repairComponents } = repairRecords;
    const { message: prevMessage, recordState: prevRecordState } = prevProps.repairRecords;

    if (message && message !== prevMessage) {
      this.showNotification(message, messageType);
    }

    if (recordState != prevRecordState) {
      if (
        !this.state.isRecordDisabled &&
        (!this.formik || isAlreadyRecorded(repairComponents, this.state.selectedKey))
      ) {
        this.setState({ isRecordDisabled: true });
      }
    }
  }

  render() {
    const {
      repairRecords,
      postRecord,
      finishRepair,
      removeComponent,
      t,
      genealogyDetails,
      componentListWithReassembleRAC,
      componentListWithoutReassembleRAC,
      componentDetailsInputRegex,
      isEventBased,
      sfcsAckActionState
    } = this.props;

    const {
      recordState,
      isRemoveLoading,
      repairComponents,
      finishState,
      serialNumber,
      isRepairFinished,
      toShowAddNonCriticalView,
      toShowReassembleView,
    } = repairRecords;
    const { dialog, selectedKey, isFinishDisabled, isRecordDisabled } = this.state;

    if (isRepairFinished) this.prepareRepairFinishDialog();

    const goToNextKey = (key) => {
      this.formik.resetForm();
      const dialog = this.state.dialog;
      return {
        selectedKey: key,
        dialog: { ...dialog, showDialog: false },
        isRecordDisabled: true,
      };
    };

    const shouldDisableFinish = shouldDisableFinishButton(repairComponents) || (isEventBased && sfcsAckActionState === LOAD_START) || (isRepairFinished);
    if (isFinishDisabled !== shouldDisableFinish) {
      this.setState({ isFinishDisabled: shouldDisableFinish });
    }

    const navLinkGroups = getNavLinkGroup(repairComponents, toShowAddNonCriticalView, toShowReassembleView, t);
    const columns = getRepairInfoColumns(t);

    const reassembleComponentList = componentListWithReassembleRAC?.filter(
      (r) => !componentListWithoutReassembleRAC.includes(r)
    );
    const selectedTab = navLinkGroups[0].links.find((link) => link.key === selectedKey)?.name;

    const isReassemble = selectedTab !== t("repairAndReplace.addNonCritical") && (selectedTab === t("repairAndReplace.addReassemble") || reassembleComponentList?.includes(selectedTab) || !(componentListWithoutReassembleRAC?.includes(selectedTab) || ComponentLocationOptions.map(r => r.text).includes(selectedTab)));
    const isRecorded = isAlreadyRecorded(repairComponents, selectedKey);

    if (isReassemble && !isRecorded && !(selectedTab === t("repairAndReplace.addReassemble")) && isRecordDisabled) {
      this.setState({
        isRecordDisabled: false,
      });
    }

    if (!toShowAddNonCriticalView && !toShowReassembleView && repairComponents.length == 0) {
      return (
        <div>
          <MessageView
            title={"No Components are present to perform Replace."}
            subTitle={null}
            messageBarType={MessageBarType.error}
          />
        </div>
      );
    } else {
      return (
        <Fragment>
          <div className="repair-and-record" data-testid="repair-record-test">
            <fieldset className="section__header">
              <legend role="heading" aria-level="3">
                <Trans i18nKey="repairAndReplace.title" />
              </legend>
            </fieldset>
            <AlertDialog
              title={dialog.title}
              message={dialog.message}
              okButtonLabel={dialog.okButtonLabel}
              okButtonType={dialog.okButtonType}
              okButtonIconName={dialog.title}
              open={dialog.showDialog}
              cancelButtonLabel={dialog.cancelButtonLabel}
              onClickOk={() => {
                dialog.onClickOk();
              }}
              onClickCancel={() => {
                dialog.onClickCancel();
              }}
            />
            <div>
              <div className="repair-record-content">
                <div className="repair-record-nav-container">
                  <div className="repair-record-nav-pane">
                    <Nav
                      className={"repair-record-nav"
                        .concat(toShowAddNonCriticalView ? " repair-record-nav-add-non-critical" : "")
                        .concat(toShowReassembleView ? " repair-record-nav-add-reassemble" : "")}
                      data-testid="repair-record-nav-testid"
                      onLinkClick={(ev, item) => {
                        if (Object.keys(this.formik.touched).length === 0 || isRecorded) {
                          this.setState(goToNextKey(item.key));
                        } else {
                          this.setState({
                            dialog: {
                              showDialog: true,
                              title: t("messages.unsavedChangesPopupHeader"),
                              message: t("messages.unsavedChangesPopupMessage"),
                              okButtonLabel: t("popupButton.discardChanges"),
                              okButtonType: ButtonType.Danger,
                              okButtonIconName: "Delete",
                              cancelButtonLabel: t("popupButton.cancel"),
                              onClickOk: () => {
                                this.setState(goToNextKey(this.state.nextKey));
                              },
                              onClickCancel: () => {
                                this.cancelDialog();
                              },
                            },
                            nextKey: item.key,
                          });
                        }
                      }}
                      selectedKey={selectedKey}
                      ariaLabel="Repair Components Navigation"
                      groups={navLinkGroups}
                    />
                  </div>

                  <div className="repair-record-form">
                    {repairComponents[selectedKey] && (
                      <CurrentComponentDetails
                        isRemoveLoading={isRemoveLoading}
                        repairRecord={repairComponents[selectedKey]}
                        removeComponent={() => {
                          this.prepareRemoveComponentDialog(
                            removeComponent,
                            serialNumber,
                            repairComponents[selectedKey].ComponentLocation
                          );
                        }}
                        isReassemble={isReassemble}
                        isRecorded={isRecorded}
                      />
                    )}
                    <Formik
                      enableReinitialize={true}
                      innerRef={(element) => {
                        this.formik = element;
                      }}
                      initialValues={getInitial(
                        repairComponents,
                        selectedKey,
                        navLinkGroups[0].links.find((link) => link.key === selectedKey).name,
                        t
                      )}
                      validate={(values) => {
                        const errors = {};
                        const regex = new RegExp(componentDetailsInputRegex);

                        if (!values.FailureMode && !isReassemble) {
                          errors.FailureMode = Message.RequiredErrorMessage;
                        }

                        if (!values.FailureSource && !isReassemble) {
                          errors.FailureSource = Message.RequiredErrorMessage;
                        }

                        if (!repairComponents[selectedKey]) {
                          if (!values.ComponentLocation) {
                            errors.ComponentLocation = Message.RequiredErrorMessage;
                          }
                        } else {
                          if (
                            repairComponents[selectedKey].RemoveComponent.ComponentSerialNumber &&
                            !values.SerialNumber
                          ) {
                            errors.SerialNumber = Message.RequiredErrorMessage;
                          } else if (regex && !regex.test(values.SerialNumber)) {
                            errors.SerialNumber = Message.IncorrectFormatErrorMessage;
                          }
                          if (
                            repairComponents[selectedKey].RemoveComponent.LotTrackingNumber &&
                            !values.LotTrackingNumber
                          ) {
                            errors.LotTrackingNumber = Message.RequiredErrorMessage;
                          } else if (regex && !regex.test(values.LotTrackingNumber)) {
                            {
                              errors.LotTrackingNumber = Message.IncorrectFormatErrorMessage;
                            }
                          }


                          if (values.PartNumber && regex && !regex.test(values.PartNumber)) {
                            errors.PartNumber = Message.IncorrectFormatErrorMessage;
                          }
                        }

                        this.setState({ isRecordDisabled: Object.keys(errors).length > 0 });
                        return errors;
                      }}>
                      {({ values, errors, touched, setFieldValue, setFieldTouched, validateForm }) => {
                        return (
                          <RepairAndRecordForm
                            handleChange={(key, newValue) => {
                              setFieldTouched(key, true, false);
                              setFieldValue(key, newValue, true);
                            }}
                            currentComponent={repairComponents[selectedKey]}
                            isLoading={
                              (recordState != undefined && recordState == LOAD_START) ||
                              (finishState != undefined && finishState == LOAD_START)
                            }
                            toShowAddNonCriticalView={toShowAddNonCriticalView}
                            toShowReassembleView={toShowReassembleView}
                            reassembleComponentList={reassembleComponentList}
                            isReassemble={isReassemble}
                            isAlreadyRecorded={isRecorded}
                            criticalComponentsForReassembly={getCriticalComponentsList(
                              genealogyDetails,
                              repairComponents
                            )}
                            values={values}
                            selectedTab={navLinkGroups[0].links.find((link) => link.key === selectedKey).name}
                            errors={errors}
                            touched={touched}
                            validateForm={validateForm}
                          />
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="repair-details-container grid__container" data-testid="repair-Details-Grid">
                  <DetailsList
                    data-testid="repair-details-grid"
                    items={repairComponents}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    setKey="none"
                    isHeaderVisible={true}
                  />
                </div>
              </div>
              <div className="repair-record-button-container">
                <ButtonPrimary
                  dataTestid="record-button"
                  isDisabled={isRecordDisabled}
                  isLoading={recordState != undefined && recordState === LOAD_START}
                  buttonLabel="repairAndReplace.record"
                  loadingLabel="Recording..."
                  disableMessage="Please provide the required values for recording the repair."
                  ariaLabel="Record repair button"
                  onClickEvent={() => {
                    if (!isRecordDisabled && recordState !== LOAD_START) {
                      const newValues = this.formik.values;
                      const location = [t("repairAndReplace.addNonCritical"), t("repairAndReplace.addReassemble")].includes(
                        navLinkGroups[0].links.find((link) => link.key === selectedKey).name
                      )
                        ? newValues.ComponentLocation
                        : repairComponents[selectedKey].ComponentLocation;
                      postRecord(getRecordRepairRequest({ newValues, serialNumber, location, isReassemble }));
                    }
                  }}
                />
                <ButtonPrimary
                  dataTestid="finish-button"
                  isDisabled={isFinishDisabled}
                  isLoading={finishState != undefined && finishState === LOAD_START}
                  buttonLabel={isEventBased ? "repairAndReplace.initiateFinishRepair" : "repairAndReplace.finishRepair"}
                  loadingLabel="Finishing..."
                  disableMessage="You must record all the components before finishing this repair."
                  ariaLabel="Finish repair button"
                  onClickEvent={() => {
                    if (!isFinishDisabled && recordState != LOAD_START) {
                      this.prepareShowFinishConfirmationDialog(finishRepair, serialNumber);
                    }
                  }}
                />
              </div>

              {repairRecords.message && repairRecords.messageType === RecordMessageType.ERROR && (
                <MessageView
                  title={repairRecords.message}
                  subTitle={repairRecords.messageSubtitle}
                  messageBarType={MessageBarType.error}
                />
              )}
              {finishState === LOAD_END && sfcsAckActionState !== LOAD_END && isEventBased && (
                <ProgressWithTime title={"Waiting for SFCS Finish Repair Acknowledgement"} />
              )}

            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const getRecordRepairRequest = function (repairDetails) {
  return {
    SerialNumber: repairDetails.serialNumber,
    ComponentLocation: repairDetails.location,
    ComponentSerialNumber: repairDetails.newValues.SerialNumber,
    LotTrackingNumber: repairDetails.newValues.LotTrackingNumber,
    ComponentPartNumber: repairDetails.newValues.PartNumber,
    FailureMode: repairDetails.newValues.FailureMode,
    FailureSource: repairDetails.newValues.FailureSource,
    RepairMode: repairDetails.isReassemble ? RepairModeEnum.Reassemble : RepairModeEnum.ReplaceComponent,
  };
};

const getFinishRepairRequest = function (serialNumber) {
  return {
    SerialNumber: serialNumber,
  };
};

const getRemoveComponentRequest = function (removeComponent) {
  return {
    SerialNumber: removeComponent.serialNumber,
    ComponentLocation: removeComponent.location,
    RepairMode: RepairModeEnum.RemoveComponent
  };
};

RepairAndRecord.propTypes = {
  repairRecords: PropTypes.object,
  isEventBased: PropTypes.bool,
  sfcsAckActionState: PropTypes.string,
  postRecord: PropTypes.func,
  finishRepair: PropTypes.func,
  removeComponent: PropTypes.func,
  genealogyDetails: PropTypes.object,
  t: PropTypes.func,
  componentListWithReassembleRAC: PropTypes.array,
  componentListWithoutReassembleRAC: PropTypes.array,
  componentDetailsInputRegex: PropTypes.string,
  clearRepairActionStates: PropTypes.func,
};

const mapStateToProps = ({ repairRecord, debugDetails, repairActions, searchSettings }) => ({
  repairRecords: repairRecord,
  isEventBased: searchSettings.isEventBased,
  sfcsAckActionState: debugDetails.sfcsAckActionState,
  genealogyDetails: debugDetails.genealogyDetails,
  componentDetailsInputRegex: searchSettings.componentDetailsInputRegex,
  componentListWithReassembleRAC: repairActions.debugDetails
    .map((debugDetail) =>
      debugDetail.formValue.RepairActions.filter((r) => r.repairActionCode?.toUpperCase() === Reassemble && r.Location !== null)
        .map((r) => r.Location)
        .flat()
    )
    .flat(),
  componentListWithoutReassembleRAC: repairActions.debugDetails
    .map((debugDetail) =>
      debugDetail.formValue.RepairActions.filter((r) => r.repairActionCode?.toUpperCase() !== Reassemble && r.Location !== null)
        .map((r) => r.Location)
        .flat()
    )
    .flat(),
});

export default withTranslation()(
  connect(mapStateToProps, { postRecord, finishRepair, removeComponent, clearRepairActionStates })(RepairAndRecord)
);
