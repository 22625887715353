import React from "react";
import "./Header.scss";
import logo from "../../microsoft_logo.svg";

import {
    DefaultButton,
    initializeIcons,
    setIconOptions,
    CommandButton
} from "office-ui-fabric-react";
import { PropTypes } from "prop-types";
import { NavLink } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { clearDutDetails } from "../../store/actions/details";
import ButtonPrimary from "../Common/ButtonPrimary";
import {FeedbackIcon} from "@fluentui/react-icons-mdl2"

export const Header = (props) => {
    const { t, i18n } = useTranslation();
    const { clearDutDetails: clearPage } = props;
    const langProps = {
        items: [
            {
                key: "en",
                text: i18n.getResource("en", 'translations', "language"),
                onClick: () => {
                    props.changeLanguage("en");
                }
            },
            {
                key: "zh-Hans",
                text: i18n.getResource("zh-Hans", 'translations', "language"),
                onClick: () => {
                    props.changeLanguage("zh-Hans");
                }
            },
        ],
        shouldFocusOnMount: false
    };
    initializeIcons();
    setIconOptions({ disableWarnings: true });
    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand" href="/">
                <img src={logo} alt="" width="130" aria-label="Microsoft logo" data-testid="microsoft_logo" />
            </a>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav nav-left mr-auto">
                    <li className="nav-item">
                        <NavLink
                            exact={true}
                            className="nav-link"
                            to="/"
                            activeClassName="is-active"
                            data-testid="navlink-home"
                            onClick={clearPage}
                        >
                            <Trans i18nKey="navlinks.home" />
                        </NavLink>
                    </li>
                </ul>
                <ButtonPrimary
                    dataTestid="self-help-button"
                    isDisabled={false}
                    isLoading={false}
                    buttonLabel="navlinks.selfHelpButtonLabel"
                    ariaLabel="navlinks.selfHelpButtonLabel"
                    onClickEvent={() => {
                        window.OpenFDA(window.env.SELF_HELP_BOT_PARAM, true, true)
                    }}
                />
                <ul className="navbar-nav nav-right">

                    {props.userName !== undefined && (
                        <li className="nav-item">
                            <span className="nav-link" data-testid="persona">
                                <Trans i18nKey="navlinks.welcome" />, {props.userName}
                            </span>
                        </li>
                    )}
                </ul>
                {props.userName !== undefined ? (
                    <DefaultButton
                        data-testid="button-logout"
                        className="navbar__action__button"
                        onClick={() => {
                            props.logout();
                        }}
                    >
                        <Trans i18nKey="navlinks.logout" />
                    </DefaultButton>
                ) : (
                    <DefaultButton text="Login" />
                )}
                <CommandButton
                    data-testid="button-localization"
                    className="navbar__action__button"
                    id="langSelector"
                    text={t("language")}
                    menuProps={langProps}
                    checked={false}
                    ariaLabel={"Language Selection button " + t("language")}
                    ariaDescription={t("language")} />
                
                <span className="mh-2" aria-hidden="true" data-testid="user-feedback" onClick={props.feedbackClick} style={{ cursor: "pointer" }}>
                        <FeedbackIcon style={{width:35,height:25,color:"white"}} ></FeedbackIcon>
                </span>

            </div>
        </nav>
    );
};

Header.propTypes = {
    userName: PropTypes.string,
    logout: PropTypes.func,
    changeLanguage: PropTypes.func,
    clearDutDetails: PropTypes.func,
    feedbackClick: PropTypes.func
};
export default connect(null, { clearDutDetails })(Header);
