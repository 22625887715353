import { ComponentType } from "../../constants/componentType";
import { Reassemble } from "../../store/actions/types";
import { DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';


const debondingEnumData = [

    {
        key: "Debonding",
        text: "Debonding",
    },
    {
        key: "Rollback",
        text: "Rollback",
    },
    {
        key: "FCC",
        text: "FCC",
    },
    
    {
        key: "Replace Critical",
        text: "Replace Critical",
    },
    {
        key: "Replace Non-Critical",
        text: "Replace Non-Critical",
    },
    { 
        key: 'divider_1', 
        text: '-',
        itemType: DropdownMenuItemType.Divider
    },
    {
        key: "Retest",
        text: "Retest",
    },
    {
        key: "Quarantine",
        text: "Quarantine",
    }, 
    {
        key: "Salvage",
        text: "Salvage",
    },
];

/**
 * Returns defective and collateral component list
 * @param {} filteredRepairActions - repairActionsArray
 */
const fetchDefectiveCollateralComponentsList = (filteredRepairActions) => {
    const defectiveComponentList = [];
    const collateralComponentList = [];
    const reassembleComponentList = [];
    filteredRepairActions &&
        filteredRepairActions.forEach((repairAction) => {
            if (
                repairAction &&
                repairAction.ComponentType === ComponentType.Defective &&
                repairAction.Location !== null && repairAction.Location.length !== 0 &&
                repairAction.RepairActionCode?.toUpperCase() !== Reassemble &&
                !defectiveComponentList.some((e) => e.key === repairAction.Location)
            ) {
                defectiveComponentList.push({
                    key: repairAction.Location,
                    name: repairAction.Location,
                });
            } else if (
                repairAction &&
                repairAction.ComponentType === ComponentType.Collateral &&
                repairAction.Location !== null && repairAction.Location.length !== 0 &&
                repairAction.RepairActionCode?.toUpperCase() !== Reassemble &&
                !collateralComponentList.some((e) => e.key === repairAction.Location) &&
                !defectiveComponentList.some((e) => e.key === repairAction.Location)
            ) {
                collateralComponentList.push({
                    key: repairAction.Location,
                    name: repairAction.Location,
                });
            } else if (
                repairAction &&
                repairAction.Location &&
                repairAction.RepairActionCode?.toUpperCase() === Reassemble
            ) {
                reassembleComponentList.push({
                    key: repairAction.Location,
                    name: repairAction.Location,
                });
            }
        });
    const filteredDefectiveComponentList = defectiveComponentList.filter(r => r.key != "NULL")
    const uniqueDefectiveComponentList = filteredDefectiveComponentList.map(r => r.key);
    const filteredCollateralComponentList = collateralComponentList.filter(r => r.key != "NULL" && !uniqueDefectiveComponentList.includes(r.key));
    const uniqueCollateralComponentList = filteredCollateralComponentList.map(r => r.key);
    const filteredReassembleComponentList = reassembleComponentList.filter(r => r.key != "NULL" && !uniqueDefectiveComponentList.includes(r.key) && !uniqueCollateralComponentList.includes(r.key));
    return { defectiveComponentList: filteredDefectiveComponentList, collateralComponentList: filteredCollateralComponentList, reassembleComponentList: filteredReassembleComponentList };
};

/**
 * Map repair actions
 * @param {*} repairActions - repair actions array
 */
const mapRepairActions = (repairActions) => {
    var currentDebug = {
        repairConditions: [],
        repairActions: [],
        repairActionCodes: [],
        defectiveComponent: [],
        collateralComponent: [],
        reassembleComponent: [],
    };

    repairActions &&
        repairActions.forEach((repairAction) => {
            if (!currentDebug.repairActions.some((e) => e.key === repairAction.Name)) {
                currentDebug.repairActions.push({
                    key: repairAction.Name,
                    name: repairAction.Name,
                });
            }

            if (!currentDebug.repairConditions.some((e) => e.key === repairAction.RepairCondition)) {
                currentDebug.repairConditions.push({
                    key: repairAction.RepairCondition,
                    name: repairAction.RepairCondition,
                });
            }

            if (!currentDebug.repairActionCodes.some((e) => e.key === repairAction.RepairActionCode)) {
                currentDebug.repairActionCodes.push({
                    key: repairAction.RepairActionCode,
                    name: repairAction.RepairActionCode,
                });
            }

            if (
                repairAction.ComponentType === ComponentType.Defective &&
                repairAction.RepairActionCode?.toUpperCase() !== Reassemble
            ) {
                if (
                    !currentDebug.defectiveComponent.some((e) => e.key === repairAction.Location) &&
                    repairAction.Location !== null && repairAction.Location.length !== 0
                ) {
                    currentDebug.defectiveComponent.push({
                        key: repairAction.Location,
                        name: repairAction.Location,
                    });
                }
            } else if (
                repairAction.ComponentType === ComponentType.Collateral &&
                repairAction.RepairActionCode?.toUpperCase() !== Reassemble
            ) {
                if (
                    !currentDebug.collateralComponent.some((e) => e.key === repairAction.Location) &&
                    repairAction.Location !== null && repairAction.Location.length !== 0
                ) {
                    currentDebug.collateralComponent.push({
                        key: repairAction.Location,
                        name: repairAction.Location,
                    });
                }
            } else if (repairAction.Location && repairAction.RepairActionCode?.toUpperCase() === Reassemble) {
                if (
                    !currentDebug.reassembleComponent.some((e) => e.key === repairAction.Location) &&
                    repairAction.Location !== null
                ) {
                    currentDebug.reassembleComponent.push({
                        key: repairAction.Location,
                        name: repairAction.Location,
                    });
                }
            }

        });

    return currentDebug;
};

export default { debondingEnumData, fetchDefectiveCollateralComponentsList, mapRepairActions };
