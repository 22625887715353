import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { getAppInsights } from "./TelemetryService";
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SeverityLevel } from '@microsoft/applicationinsights-web'

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            hasError: false
        };
    }

    componentDidCatch(error, errorInfo) {

        this.setState({ hasError: true })
        const appInsights = getAppInsights();
        appInsights.appInsights.trackException({
            error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: { ...errorInfo },
        })
    }

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }

        this.props.after();
    }

    render() {
        const { hasError } = this.state
        const { onError, children } = this.props;

        if (hasError) {
            return typeof onError === 'function'
                ? onError()
                : React.createElement(onError)
        }
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

TelemetryProvider.propTypes = {
    history: PropTypes.object,
    children: PropTypes.node,
    onError: PropTypes.func,
    after: PropTypes.func,
    instrumentationKey: PropTypes.string
}
export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));