import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import createRootReducer from "./reducers";
import {GRAPH_REQUESTS} from "../components/Authentication/AuthUtils";

let store;

const axiosConfig = {
    axios: {
        // follow up to 10 HTTP 3xx redirects
        maxRedirects: 10,

        // cap the maximum content length we'll accept
        // to 50MBs
        maxContentLength: 50 * 1000 * 1000,

        xsrfCookieName: "XSRF-TOKEN",

        xsrfHeaderName: "RequestVerificationToken",

        /**
         * Set default BASE API URL Prefix
         */
        get baseURL() {
            return window.location.origin;
        }
    }
};

const axiosinstance = axios.create(axiosConfig.axios);
axiosinstance.interceptors.request.use(async (request) => {
    if (window.msal) {
        window.msal.accessToken = (await window.msal.acquireTokenSilent(GRAPH_REQUESTS.TOKEN))?.accessToken;
        const token = window.msal.accessToken;
        request.headers.Authorization = `Bearer ${token}`;
        request.headers.CorrelationId = request.headers.CorrelationId ?? uuid();
    }
    return request;
});

export default function configureStore(initialState = {}) {
    const middleware = [thunk, axiosMiddleware(axiosinstance)];

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /*
          const composeEnhancers = composeWithDevTools({
              // Specify name here, actionsBlacklist, actionsCreators and other options if needed
          });
      */
    store = createStore(
        createRootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );

    // axiosinstance.interceptors.response.use(response => response, (error) => {
    //     if (error.response && error.response.status === 401) { store.dispatch({ type: AUTH_SHOW_UNAUTHZ_PROMPT }); }
    //     return Promise.reject(error);
    // });

    return store;
}

export function getStore() {
    return store;
}