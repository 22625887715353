import { Message } from "../../constants/message";
import {
    GET_INIT_INFO,
    GET_INIT_INFO_FAIL,
    GET_INIT_INFO_SUCCESS,
    LOAD_END,
    LOAD_INITIAL,
    LOAD_START,
    SET_PRODUCT_LINE,
} from "../actions/types";

export const searchSettingsInitialState = {
    selectedProductLine: "",
    productLines: [],
    error: null,
    getProductsActionState: LOAD_INITIAL,
    serialNumberRegex: ""
};

export function searchSettings(state = searchSettingsInitialState, action) {
    switch (action.type) {
        case GET_INIT_INFO:
            return Object.assign({}, state, {
                getProductsActionState: LOAD_START,
            });

        case GET_INIT_INFO_SUCCESS:
            return Object.assign({}, state, {
                productLines: action.payload.data.ProductLines.map((product) => ({
                    key: product,
                    text: product,
                })),
                serialNumberRegex: action.payload.data.SerialNumberRegex,
                ramUrl: action.payload.data.RamUrl,
                selectedProductLine: state.selectedProductLine ? state.selectedProductLine : (action.payload.data.ProductLines ? action.payload.data.ProductLines[0] : ""),
                error: action.payload.data.Error,
                getProductsActionState: LOAD_END,
                componentDetailsInputRegex: action.payload.data.ComponentDetailsInputRegex,
                isEventBased: action.payload.data.IsEventBased,
            });

        case GET_INIT_INFO_FAIL:
            return Object.assign({}, state, {
                error: Object.assign({}, action.error.response?.data?.error, {
                    ErrorCode: action.error.response?.status,
                    ErrorMessage:
                        action.error.response?.data?.Errors !== undefined
                            ? action.error.response.data.Errors[0].ErrorMessage
                            : Message.GetProductLineOptionsFailMessage,
                }),
                getProductsActionState: LOAD_END,
            });

        case SET_PRODUCT_LINE: {
            return Object.assign({}, state, {
                selectedProductLine: action.productLine,
            });
        }
        default:
            return state;
    }
}
