import * as signalR from "@microsoft/signalr";
import { sfcsAckActionReceived, signalRConnectionFailed, signalRConnectionInProgress, signalRConnectionSuccess } from "./store/actions/details";
import { getStore } from "./store/configureStore";
import { GRAPH_REQUESTS } from "./components/Authentication/AuthUtils";

let connection = null;
let storedRepairGuid = "";
function onConnectionStart(connection, repairGuid) {
    storedRepairGuid = repairGuid;
    connection.start()
        .then(() => onConnected(connection, repairGuid))
        .catch(error => setTimeout(onConnectionReconnecting(error), 100));
}

function onConnectionReconnecting(error) {
    console.log(error);
    getStore().dispatch(signalRConnectionInProgress());
}

function onConnectionReconnected() {
    console.log('connection reconnected');
    getStore().dispatch(signalRConnectionSuccess());
    connection.send('joinGroup', storedRepairGuid);
}

const conn = (repairGuid) => {
    connection = new signalR.HubConnectionBuilder()
        .withUrl("/sfcsack", {
            accessTokenFactory: async () => {
                window.msal.accessToken = (await window.msal.acquireTokenSilent(GRAPH_REQUESTS.TOKEN))?.accessToken;
                return window.msal.accessToken;
            }
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect([0,0,1000])
        .build();
    console.log("build done");
    bindConnectionMessage(connection);
    onConnectionStart(connection, repairGuid);
}

export function onCloseConnection() {
    getStore().dispatch(signalRConnectionSuccess());
    console.log('connection stopped');
    connection.stop();
}

function bindConnectionMessage(connection) {
    var messageCallback = function (name) {
        console.log('messageCallback');
        console.log(name);
        getStore().dispatch(sfcsAckActionReceived(name));
    };
    connection.on('RepairInstructionAck', messageCallback);
    connection.on('FinishRepairAck', messageCallback);
    connection.onclose(onConnectionError);
    connection.onreconnecting(onConnectionReconnecting);
    connection.onreconnected(onConnectionReconnected);
}

function onConnected(connection, repairGuid) {
    console.log('connection started');
    getStore().dispatch(signalRConnectionSuccess());
    connection.send('joinGroup', repairGuid);
}

function onConnectionError(error) {
    storedRepairGuid = "";
    if (error && error.message) {
        getStore().dispatch(signalRConnectionFailed(error?.message));
        console.error(error.message);  
    }
}
export default conn;