import React, { Fragment, Component } from "react";
import "./App.scss";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { AccessDenied } from "./components/AccessDenied/AccessDenied";
import AuthProvider from "./components/Authentication/AuthProvider";
import PropTypes from "prop-types";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Help from "./components/Help/Help";
import Home from "./components/Home/Home";
import TelemetryProvider from "./components/AppInsights/TelemetryProvider";
import { getAppInsights } from "./components/AppInsights/TelemetryService";
import { UserProvider } from "./components/Authentication/UserContext";
import { withTranslation } from "react-i18next";
import PopupBlockerStep1 from "./assets/repairtool-popupblocker-Step1.png";
import PopupBlockerStep2 from "./assets/repairtool-popupblocker-Step2.png";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import {makeFloodgateFeedback} from "@ms-ofb/officebrowserfeedbacknpm";
import {chineseUIStrings} from "./locate/chinese/chineseFeedbackStrings";
import {englishUIStrings} from "./locate/english/englishFeedbackStrings";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInsights: null,
      language: "en"
    };
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onFeedbackButtonClick = this.onFeedbackButtonClick.bind(this);
  }

  async componentDidMount() {
    const { account, onSignIn } = this.props;
    if (account === null) {
      await onSignIn();
    }
  }

  onLanguageChange(lang) {
    this.setState({
      language: lang,
    });
    this.props.i18n.changeLanguage(lang);
  }

  onFeedbackButtonClick () {
    const {language}=this.state;
    const officeBrowserFeedback = makeFloodgateFeedback();

    officeBrowserFeedback.initOptions = {
        appId: Number(window.env.USER_FEEDBACK_APPID),
        stylesUrl: "styles/officebrowserfeedback.css",
        intlUrl: "intl/",
        environment: Number(window.env.USER_FEEDBACK_ENVIRONMENT),
        locale:language,
        intlFilename: "officebrowserfeedbackstrings.js",
        onError: (error) => {  this.state.appInsights && this.state.appInsights.trackException({ exception: error }); },
        primaryColour: "#008272",
        secondaryColour: "#004B50",
        onDismiss: () => {
        }
    }

    if (language === "zh-Hans") {
      officeBrowserFeedback.setUiStrings(chineseUIStrings);
    }else {
      officeBrowserFeedback.setUiStrings(englishUIStrings);
    }

    officeBrowserFeedback.multiFeedback().then();
  }

  render() {
    const { account, onSignOut, accessToken, error } = this.props;
      const { language } = this.state;
      const ai = getAppInsights();
      
    return (
      <BrowserRouter>
        <TelemetryProvider
                instrumentationKey={window.env.INSTRUMENTATIONKEY}
                onError={() => <h3>Error occurred while rendering, please use correlation ID to know more: {ai.context.telemetryTrace.traceID}</h3>}
          after={() => {
            this.setState({ appInsights: getAppInsights() });
          }}>
          {account !== null && accessToken !== null ? (
            <div className="app" lang={language}>
              <Fragment>
                <header data-testid="header" className="app-header">
                  <SkipNavLink>Skip to main content</SkipNavLink>
                  <Header userName={account.name} logout={onSignOut} changeLanguage={this.onLanguageChange} feedbackClick={this.onFeedbackButtonClick} />
                </header>
                  <SkipNavContent>
                    {account.idToken.roles !== undefined && account.idToken.roles.length > 0 ? (
                      <UserProvider value={Object.assign({}, { name: account.name, roles: account.idToken.roles })}>
                        <div data-testid="body" className="app-body-container" role="main">
                          <Switch>
                            <Route path="/help">
                              <Help />
                            </Route>
                            <Route path="/">
                              <Home />
                            </Route>
                          </Switch>
                        </div>
                      </UserProvider>
                    ) : (
                      <AccessDenied />
                    )}
                  </SkipNavContent>
              </Fragment>
              <footer data-testid="footer" className="app-footer border-top text-muted">
                <Footer />
              </footer>
            </div>
          ) : (
            <div style={{ padding: "20px" }}>
              <h2 style={{ color: "green" }}>Logging you in...</h2>
              {error && error.includes("Error opening popup window.") && (
                <Fragment>
                  <div>Please make sure that popup blocker is disabled.</div>
                  <div>
                    To disable popup blocker, please follow these steps.
                    <div>
                      <h4>Step 1.</h4> 
                      <p>Click on the Popup blocker icon in the Address bar as shown in the image.</p>
                      <img src={PopupBlockerStep1} width={700} alt="Popup Blocker-Step 1" />
                    </div>
                    <div>
                      <h4>Step 2.</h4>
                      <p>Select the option to allow popups and click on Done.</p>
                      <img src={PopupBlockerStep2} width={700} alt="Popup Blocker-Step 2" />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </TelemetryProvider>
      </BrowserRouter>
    );
  }
}
App.propTypes = {
  account: PropTypes.object,
  emailMessages: PropTypes.object,
  error: PropTypes.string,
  graphProfile: PropTypes.object,
  onSignIn: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  t: PropTypes.func,
  i18n: PropTypes.object,
  accessToken: PropTypes.string,
};

export default withTranslation()(AuthProvider(App));
