import { Message } from "../../../constants/message";
import { RepairState } from "../../../constants/repairState";
import {
    GET_DEBUG_DETAILS,
    GET_DEBUG_DETAILS_FAIL,
    GET_DEBUG_DETAILS_SUCCESS,
    CLEAR_DEBUG_DETAILS,
    LOAD_INITIAL,
    LOAD_START,
    LOAD_END,
    SET_CURRENT_ERROR_CODE,
    ErrorSource,
    SAVE_REPAIR_ACTION_SUCCESS,
    ASSIGN_REPAIR_ACTION_SUCCESS,
    ASSIGN_REPAIR_ACTION_FAIL,
    ASSIGN_REPAIR_ACTION,
    UNASSIGN_REPAIR_ACTION,
    UNASSIGN_REPAIR_ACTION_SUCCESS,
    UNASSIGN_REPAIR_ACTION_FAIL,
    SAVE_REPAIR_ACTION_FAIL,
    SAVE_REPAIR_ACTION,
    SET_UNSAVED_CHANGES,
    CLEAR_ACTION_STATES,
    POST_REPAIR_RECORD_SUCCESS,
    ErrorMessageForSource,
    USER_CANCELS_NFF_POPUP,
    NFF_REPAIR_ERROR_CODE,
    CREATE_GENERIC_REPAIR_ACTION,
    CREATE_GENERIC_REPAIR_ACTION_SUCCESS,
    CREATE_GENERIC_REPAIR_ACTION_FAIL,
    FILTER_NEXTSTEP_LIST,
    SFCS_ACK,
    SFCS_ACK_RECEIVED,
    SFCS_ACK_NOT_RECEIVED,
    FINISH_REPAIR,
    SIGNALR_CONNECTION_IN_PROGRESS,
    SIGNALR_CONNECTION_SUCCESS,
    SIGNALR_CONNECTION_FAIL
} from "../../actions/types";

export const debugDetailsInitialState = {
    serialNumber: "",
    dutInfo: {},
    testResults: [],
    genealogyDetails: { CriticalComponents: [] },
    repairResults: {},
    currentDebugErrorCode: null,
    operation: null,
    componentState: LOAD_INITIAL,
    errors: [],
    debonding: "",
    nextStep: "",
    nextStepList: [],
    fullNextStepList: [],
    repairComment: "",
    repairState: "",
    repairGuid: "",
    assignRAActionState: LOAD_INITIAL,
    unAssignRAActionState: LOAD_INITIAL,
    saveActionState: LOAD_INITIAL,
    createGenericRepairActionState: LOAD_INITIAL,
    actionError: "",
    errorMessageSubtitle: null,
    hasUnsavedChanges: false,
    showCreateGenericRepairActionOption: false,
    showRepairActionSuccessMessage: false,
    nextStepRegex: "",
    sfcsAckActionState: LOAD_INITIAL,
    signalRConnectionState: LOAD_INITIAL,
    signalRConnectionSuccess: true,
    signalRFailureErrorMessage: ""
};

const getErrorDetailsOptions = (errorDetails) => {
    var errorCodeOperationSet = new Set();
    errorDetails.forEach((errorOption) => {
        errorCodeOperationSet.add(errorOption["ErrorCode"]?.concat(errorOption["TestOperation"]).trim());
    });

    const firstOnlineIndex = errorDetails.findIndex(
        (test) => test["OperatorMode"] === "Online"
    );

    let sortedErrorOptions = [];
    sortedErrorOptions.push(errorDetails[firstOnlineIndex]);
    errorCodeOperationSet.delete(errorDetails[firstOnlineIndex]["ErrorCode"]?.concat(errorDetails[firstOnlineIndex]["TestOperation"]).trim())

    errorDetails.slice(0, firstOnlineIndex)?.forEach((errorOption) => {
        if (errorCodeOperationSet.has(errorOption["ErrorCode"]?.concat(errorOption["TestOperation"]).trim())) {
            sortedErrorOptions.push(errorOption);
            errorCodeOperationSet.delete(errorOption["ErrorCode"]?.concat(errorOption["TestOperation"]).trim());
        }
    });

    if (sortedErrorOptions.some(e => e.ErrorCode === NFF_REPAIR_ERROR_CODE)) {
        let NFFErrorCode = sortedErrorOptions.filter(e => e.ErrorCode === NFF_REPAIR_ERROR_CODE);
        sortedErrorOptions = sortedErrorOptions.filter(e => e.ErrorCode !== NFF_REPAIR_ERROR_CODE);
        sortedErrorOptions.push(NFFErrorCode[0]);
    }


    const sortedErrorOptionsJson = JSON.parse(
        JSON.stringify(
            sortedErrorOptions
        )
    );

    sortedErrorOptionsJson.forEach((errorOption, index) => {
        errorOption["key"] = index + 1;
        errorOption["text"] = errorOption["ErrorCode"];
    });
    return sortedErrorOptionsJson;
};

const getErrorDetailsOptionsForNFF = (errorDetails) => {
    errorDetails.forEach((errorOption, index) => {
        errorOption["key"] = index + 1;
        errorOption["text"] = errorOption["ErrorCode"];
        errorOption["OperatorMode"] = "Online";
        errorOption["TestOperation"] = errorOption["Operation"];
    });
    return errorDetails;
}


const getNextStepOptions = (nextStep, currentNextStepValue) => {
    var nextStepList = [];
    var valueExist = false
    nextStep.forEach((nextStepOption) => {
        var JSONObj = { "key": nextStepOption, "text": nextStepOption };
        nextStepList.push(JSONObj);
        if (currentNextStepValue == nextStepOption)
            valueExist = true
    });
    if (!valueExist && currentNextStepValue)
        nextStepList.push({ "key": currentNextStepValue, "text": currentNextStepValue });
    return nextStepList;
};

const getErrorSubtitle = (action) => {
    const errors = action.error?.response?.data?.Errors;
    if (errors
        && errors.length > 0) {
        return ErrorMessageForSource[errors[0].Target];
    }
    return null;
}

const processDebugDetailsSuccess = (state, action) => {
    const data = action.payload.data;
    const response = data.Response;

    if (response.DisplayNFFPopup) {
        return Object.assign({}, state, {
            displayNFFPopup: true,
            componentState: LOAD_END,
            errors: [],
        });
    } else {
        return Object.assign({}, state, {
            serialNumber: data.SerialNumber,
            dutInfo:
                response.AssignRepair != null && response.AssignRepair.ErrorDetails &&
                    response.AssignRepair.ErrorDetails.length > 0
                    ? {
                        ...response.DUTInfo,
                        ErrorDetails: response.IsNFFRepair
                            ? getErrorDetailsOptionsForNFF([...response.AssignRepair.ErrorDetails])
                            : getErrorDetailsOptions([...response.TestResults]),
                        SerialNumber: data.SerialNumber,
                    }
                    : response.DUTInfo,
            testResults: response.TestResults?.filter(e => e?.ErrorCode !== NFF_REPAIR_ERROR_CODE) || [],
            genealogyDetails: response.GenealogyDetails || [],
            repairResults: {
                error:
                    data.Errors.filter(
                        (error) => error.Target === ErrorSource.RepairResultService
                    ).length > 0
                        ? data.Errors.filter(
                            (error) => error.Target === ErrorSource.RepairResultService
                        )[0].ErrorMessage
                        : null,
                results: response.RepairResults,
            },
            debonding: response && response.AssignRepair ? response.AssignRepair.Debonding : [],
            nextStep: response && response.AssignRepair ? response.AssignRepair.NextStep : "",
            repairComment: response && response.AssignRepair ? response.AssignRepair.RepairComment : "",
            repairState: response ? response.RepairState : "",
            repairGuid: response ? response.RepairId : "",
            isNFFRepair: response.IsNFFRepair,
            componentState: LOAD_END,
            errors: data.Errors || [],
            nextStepRegex: response && response.NextStepInputRegex ? response.NextStepInputRegex : "",
            showCreateGenericRepairActionOption: response.ShowCreateGenericRepairActionOption,
            nextStepList: response && response.NextSteps ? getNextStepOptions([...response.NextSteps], response.AssignRepair.NextStep) : [],
            fullNextStepList: response && response.NextSteps ? getNextStepOptions([...response.NextSteps], response.AssignRepair.NextStep) : []
        });
    }
}

const processSignalRMessage = (state, action) => {
    const data = JSON.parse(action.name);
    const response = data.Response;
    const errors = data.Errors;
    if (errors && errors.length > 0) {
        return Object.assign({}, state, {
            errorMessageSubtitle: getErrorSubtitle(action),
            actionError: errors[0]?.ErrorMessage ?? "Failed to assign the repair actions. Please try again.",
            sfcsAckActionState: LOAD_END,
            repairState: response && response.RepairState ? response.RepairState : state.repairState,
        });
    }
    return Object.assign({}, state, {
        debonding: response && response.AssignRepair ? response.AssignRepair.Debonding : [],
        nextStep: response && response.AssignRepair ? response.AssignRepair.NextStep : "",
        repairComment: response && response.AssignRepair ? response.AssignRepair.RepairComment : "",
        repairState: response && response.RepairState ? response.RepairState : state.repairState,
        assignRAActionState: LOAD_END,
        actionError: "",
        hasUnsavedChanges: false,
        sfcsAckActionState: LOAD_END
    });
}

export function debugDetails(state = debugDetailsInitialState, action) {
    switch (action.type) {
        case SFCS_ACK: {
            return Object.assign({}, state, {
                sfcsAckActionState: LOAD_START
            });
        }

        case SFCS_ACK_RECEIVED:
            return processSignalRMessage(state, action);

        case SFCS_ACK_NOT_RECEIVED: {
            const error = state.assignRAActionState === LOAD_END ? "No Acknowledgement received from SFCS. Please try again." : ""
            return Object.assign({}, state, {
                sfcsAckActionState: LOAD_END,
                actionError: error
            });
        }

        case USER_CANCELS_NFF_POPUP: {
            const error = {
                Target: ErrorSource.NFF,
                ErrorMessage: Message.NFFPopupCancelledErrorMessage,
            };
            return Object.assign({}, state, {
                componentState: LOAD_END,
                errors: [error],
                displayNFFPopup: false,
            });
        }

        case GET_DEBUG_DETAILS:
            return Object.assign({}, state, {
                componentState: LOAD_START,
                errors: [],
                displayNFFPopup: false,
                showRepairActionSuccessMessage: false,
                showCreateGenericRepairActionOption: false
            });
        case GET_DEBUG_DETAILS_SUCCESS:
            return processDebugDetailsSuccess(state, action);
        case GET_DEBUG_DETAILS_FAIL: {
            const error = {
                Target: ErrorSource.API,
                ErrorCode: action.error.response.status,
                ErrorMessage:
                    action.error.response.data.Errors !== undefined
                        ? action.error.response.data.Errors[0].ErrorMessage
                        : "An error occurred. Please refresh the page to continue.",
                InnerError: action.error.stack,
            };
            return Object.assign({}, state, {
                componentState: LOAD_END,
                errors: [error],
                showRepairActionSuccessMessage: false,
                displayNFFPopup: false,
            });
        }

        case SET_CURRENT_ERROR_CODE:
            return Object.assign({}, state, {
                currentDebugErrorCode: action.errorCode,
                operation: action.operation,
                unAssignRAActionState: LOAD_INITIAL,
                assignRAActionState: LOAD_INITIAL,
                saveActionState: LOAD_INITIAL,
                hasUnsavedChanges: false
            });

        case SAVE_REPAIR_ACTION: {
            return Object.assign({}, state, {
                saveActionState: LOAD_START,
                assignRAActionState: LOAD_INITIAL,
                unAssignRAActionState: LOAD_INITIAL,
            });
        }

        case SAVE_REPAIR_ACTION_SUCCESS: {
            return Object.assign({}, state, {
                debonding: action.payload.data.Response.Debonding,
                nextStep: action.payload.data.Response.NextStep,
                repairComment: action.payload.data.Response.RepairComment,
                repairState: action.payload.data.Response.RepairState,
                actionError: "",
                saveActionState: LOAD_END,
                hasUnsavedChanges: false
            });
        }

        case POST_REPAIR_RECORD_SUCCESS: {
            return Object.assign({}, state, {
                repairState: RepairState.RepairSave,
            });
        }

        case SAVE_REPAIR_ACTION_FAIL: {
            return Object.assign({}, state, {
                errorMessageSubtitle: getErrorSubtitle(action),
                actionError: action?.error?.response !== undefined && action.error.response.data.Errors !== undefined
                    ? action.error.response.data.Errors[0].ErrorMessage
                    : "Failed to save the data. Please try again.",
                saveActionState: LOAD_END,
            });
        }

        case ASSIGN_REPAIR_ACTION: {
            return Object.assign({}, state, {
                assignRAActionState: LOAD_START,
                unAssignRAActionState: LOAD_INITIAL,
                saveActionState: LOAD_INITIAL,
                sfcsAckActionState: LOAD_INITIAL
            });
        }

        case ASSIGN_REPAIR_ACTION_SUCCESS: {
            return Object.assign({}, state, {
                debonding: action.payload.data.Response.AssignRepair ? action.payload.data.Response.AssignRepair.Debonding : [],
                nextStep: action.payload.data.Response.AssignRepair ? action.payload.data.Response.AssignRepair.NextStep : "",
                repairComment: action.payload.data.Response.AssignRepair ? action.payload.data.Response.AssignRepair.RepairComment : "",
                repairState: action.payload.data.Response.RepairState,
                assignRAActionState: LOAD_END,
                actionError: "",
                hasUnsavedChanges: false
            });
        }

        case ASSIGN_REPAIR_ACTION_FAIL: {
            return Object.assign({}, state, {
                assignRAActionState: LOAD_END,
                errorMessageSubtitle: getErrorSubtitle(action),
                actionError: action?.error?.response !== undefined && action.error.response.data.Errors !== undefined
                    ? action.error.response.data.Errors[0].ErrorMessage
                    : "Failed to assign the repair actions. Please try again.",
            });
        }

        case UNASSIGN_REPAIR_ACTION: {
            return Object.assign({}, state, {
                unAssignRAActionState: LOAD_START,
                assignRAActionState: LOAD_INITIAL,
                saveActionState: LOAD_INITIAL,
            });
        }

        case UNASSIGN_REPAIR_ACTION_SUCCESS: {
            return Object.assign({}, state, {
                debonding: [],
                nextStep: "",
                repairComment: "",
                repairState: action.payload.data.Response.RepairState,
                unAssignRAActionState: LOAD_END,
                actionError: "",
                hasUnsavedChanges: false
            });
        }

        case UNASSIGN_REPAIR_ACTION_FAIL: {
            return Object.assign({}, state, {
                unAssignRAActionState: LOAD_END,
                errorMessageSubtitle: getErrorSubtitle(action),
                actionError: action?.error?.response !== undefined && action.error.response.data.Errors !== undefined
                    ? action.error.response.data.Errors[0].ErrorMessage
                    : "Failed to unassign the repair actions. Please try again.",
            });
        }

        case SET_UNSAVED_CHANGES: {
            return Object.assign({}, state, {
                hasUnsavedChanges: action.hasUnsavedChanges
            });
        }

        case CLEAR_ACTION_STATES: {
            return Object.assign({}, state, {
                saveActionState: LOAD_INITIAL,
                assignRAActionState: LOAD_INITIAL,
                unAssignRAActionState: LOAD_INITIAL,
                hasUnsavedChanges: false,
                sfcsAckActionState: LOAD_INITIAL
            })
        }

        case CREATE_GENERIC_REPAIR_ACTION: {
            return Object.assign({}, state, {
                createGenericRepairActionState: LOAD_START,
                showRepairActionSuccessMessage: false,
                errors: []
            });
        }

        case CREATE_GENERIC_REPAIR_ACTION_SUCCESS: {
            return Object.assign({}, state, {
                createGenericRepairActionState: LOAD_END,
                showRepairActionSuccessMessage: true
            });
        }

        case CREATE_GENERIC_REPAIR_ACTION_FAIL: {
            const error = {
                Target: ErrorSource.API,
                ErrorCode: action.error.response.status,
                ErrorMessage:
                    action.error.response.data.Errors !== undefined
                        ? action.error.response.data.Errors[0].ErrorMessage
                        : "Failed to create Generic Repair Action Profile.",
                InnerError: action.error.stack,
            };
            return Object.assign({}, state, {
                createGenericRepairActionState: LOAD_END,
                errors: [error],
                showRepairActionSuccessMessage: false
            });
        }

        case FILTER_NEXTSTEP_LIST: {
            var data = action.inputValue;
            const { nextStepList } = state;
            const { fullNextStepList } = state;

            if (data != undefined) {
                var input = data.trim();
                if (input === "" || input === null) {
                    return Object.assign({}, state, {
                        nextStepList: fullNextStepList
                    });
                }
                let fullNextStepListTemp;
                if (input.length > 2) {
                    fullNextStepListTemp = fullNextStepList.filter(r => r.key.toLowerCase().includes(input.toLowerCase()));
                } else {
                    fullNextStepListTemp = fullNextStepList.filter(r => r.key.toLowerCase().startsWith(input.toLowerCase()));
                }
                return Object.assign({}, state, {
                    nextStepList: fullNextStepListTemp
                });
            }
            return Object.assign({}, state, {
                nextStepList: nextStepList
            });
        }

        case CLEAR_DEBUG_DETAILS:
            return Object.assign({}, state, debugDetailsInitialState);

        case FINISH_REPAIR:
            return Object.assign({}, state, {
                sfcsAckActionState: LOAD_INITIAL
            });

        case SIGNALR_CONNECTION_IN_PROGRESS:
            return Object.assign({}, state, {
                signalRConnectionState: LOAD_START,
                signalRConnectionSuccess: false,
                signalRFailureErrorMessage: ""
            });

        case SIGNALR_CONNECTION_SUCCESS:
            return Object.assign({}, state, {
                signalRConnectionState: LOAD_END,
                signalRConnectionSuccess: true,
                signalRFailureErrorMessage: ""
            });

        case SIGNALR_CONNECTION_FAIL:
            return Object.assign({}, state, {
                signalRConnectionState: LOAD_END,
                signalRConnectionSuccess: false,
                signalRFailureErrorMessage: "Failed to connect to SignalR. Please refresh the page."
            });

        default:
            return state;
    }
}
