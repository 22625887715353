import i18n from "../locate/i18n";

const changeLanguage= function () {
    const lang = i18n.language === "en" ? "zh-Hans" : "en"
    i18n.changeLanguage(lang);
}

const getResource = function (key, lang = null) {
    lang = lang == null ? i18n.language : lang;
    return i18n.getResource(lang, 'translations', key);
}

const setDefaultLanguage = function () {
    i18n.changeLanguage("en");
}

const t = function (key) {
    return i18n.t(key);
}

export { changeLanguage, getResource, setDefaultLanguage, t}
