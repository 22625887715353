import React from "react";
import { getAppInsights } from "../AppInsights/TelemetryService";
import toaster from "toasted-notes";
import "./common.scss";

class NotificationHandler {
    constructor() {
        this.appInsights = getAppInsights();
    }

    handleSuccess(eventName, alertOnUi = false, content = '') {
        this.appInsights && this.appInsights.trackEvent({ name: eventName });
        if (alertOnUi) {
            if (content === '') {
                content = eventName;
            }

            toaster.notify(({ onClose }) => (
                <div
                    data-testid="response-message-container"
                    className="Toaster__alert bg-success">
                    <div className="Toaster__alert_header text-white"><strong>Success</strong></div>
                    <div className="Toaster__alert_text font-weight-light text-white">
                        {content}
                    </div>
                    <button className="Toaster__alert_close text-white" onClick={onClose} type="button" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            ), { position: "top-right" });
        }
    }

    handleError(eventName, alertOnUi = false, message = '', stack = '') {
        var error = new Error(message);
        error.name = eventName;
        error.stack = stack;
        this.appInsights && this.appInsights.trackException({ exception: error });
        if (alertOnUi) {
            if (message === '') {
                message = eventName;
            }

            toaster.notify(({ onClose }) => (
                <div className="Toaster__alert bg-danger">
                    <div className="Toaster__alert_header text-white"><strong>Error</strong></div>
                    <div className="Toaster__alert_text font-weight-light text-white">
                        {message}
                    </div>
                    <button className="Toaster__alert_close text-white" onClick={onClose} type="button" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            ), { position: "top-right" });
        }
    }
}


export const ResponseHandler = new NotificationHandler();