import React, { Component, Fragment } from "react";
import {
    getInitInfo,
    getDebugDetails,
    userCancelsNFFPopup,
    setProductLine,
} from "../../store/actions/details";
import { SearchBox, Dropdown, MessageBarType } from "office-ui-fabric-react";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ResponseHandler } from "../Common/responseHandler";
import i18n from "../../locate/i18n";
import "./Search.scss";
import { ErrorMessageForSource, LOAD_START } from "../../store/actions/types";
import MessageView from "../Common/MessageView/MessageView";
import { Message } from "../../constants/message";
import AlertDialog, { AlertDialogButtonType } from "../Common/AlertDialog/AlertDialog";


export class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTextError: "",
            searchText: "",
        };

        this.searchSubmit = this.searchSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getInitInfo();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.error) !== JSON.stringify(prevProps.error)) {
            ResponseHandler.handleError(
                this.props.error.ErrorCode,
                true,
                this.props.error.ErrorMessage
            );
        }
    }

    searchSubmit(searchText) {
        const {
            productOptions,
            selectedProductLine,
            getDebugDetails: getDebugDetailsAction
        } = this.props;
        const { searchTextError } = this.state;

        if (searchTextError !== true) {
            const product = selectedProductLine ? selectedProductLine : productOptions[0].key;
            getDebugDetailsAction(searchText, product, false);
        }
    }

    render() {
        const {
            displayNFFPopup,
            productOptions,
            selectedProductLine,
            getProductsIsLoading,
            setProductLine: setProductLineAction,
            getDebugDetails: getDebugDetailsAction,
            userCancelsNFFPopup: userCancelsNFFPopupAction,
            error,
            serialNumberRegex
        } = this.props;
        const { searchTextError, searchText } = this.state;
        const INPUT_MAX_LENGTH = parseInt(window.env.INPUT_MAX_LENGTH);
        const product = selectedProductLine ? selectedProductLine : productOptions[0]?.key;
        return (
            <div className="search">
                <AlertDialog
                    title={i18n.t("messages.NFFPopupTitle")}
                    message={i18n.t("messages.NFFPopupMessage")}
                    okButtonLabel={"Yes"}
                    okButtonType={AlertDialogButtonType.Success}
                    open={displayNFFPopup}
                    cancelButtonLabel={"No"}
                    onClickOk={() => {
                        getDebugDetailsAction(searchText, selectedProductLine, true);
                    }}
                    onClickCancel={() => {
                        userCancelsNFFPopupAction();
                    }}
                />
                {productOptions.length !== 0 ? (
                    <Fragment>
                        <div className="search__container" role="search">
                            <SearchBox
                                placeholder={i18n.t("home.searchPlaceHolder")}
                                onSearch={this.searchSubmit}
                                disabled={productOptions.length === 0}
                                onChange={(event, searchText) => {
                                    this.setState({ searchText: searchText });
                                    if (searchText.length > INPUT_MAX_LENGTH) {
                                        event.target.value = searchText.slice(0, INPUT_MAX_LENGTH);
                                    } else if (searchText.length === 0 || searchText.trim() === "") {
                                        this.setState({ searchTextError: true });
                                    }
                                    else {
                                        const regex = new RegExp(serialNumberRegex);
                                        if (regex) {
                                            const invalidCharError = !regex.test(
                                                searchText
                                            );
                                            this.setState({ searchTextError: invalidCharError });
                                        }
                                        else {
                                            this.setState({ searchTextError: false });
                                        }
                                    }
                                }}
                                ariaLabel="Serial number input search box"
                            />
                            <span className="search__separator" />
                            <Dropdown
                                className="dropdown__select"
                                notifyOnReselect={true}
                                data-testid="homePage--productDropdown"
                                defaultSelectedKey={selectedProductLine}
                                options={productOptions}
                                onChanged={(option) => setProductLineAction(option.key)}
                                disabled={productOptions.length === 0}
                                ariaLabel={"Product list drop down " + product}
                                role="combobox"
                            />
                        </div>

                        {searchTextError && (
                            <div className="search-error-text" data-testid="search-error-text">
                                {Message.SearchInvalidCharactersError}
                            </div>
                        )}
                    </Fragment>
                ) : getProductsIsLoading ? (
                    <Fragment>Fetching details related to Product...</Fragment>
                ) : (
                    error && (
                        <Fragment>
                            <MessageView
                                dataTestId="error-message-bar"
                                title={error.ErrorMessage}
                                subTitle={ErrorMessageForSource[error.Target]}
                                messageBarType={MessageBarType.error}
                            />
                        </Fragment>
                    )
                )}
            </div>
        );
    }
}

Search.propTypes = {
    getDebugDetails: PropTypes.func,
    userCancelsNFFPopup: PropTypes.func,
    productOptions: PropTypes.array,
    error: PropTypes.object,
    getInitInfo: PropTypes.func,
    getProductsIsLoading: PropTypes.bool,
    selectedProductLine: PropTypes.string,
    setProductLine: PropTypes.func,
    serialNumberRegex: PropTypes.string,
    displayNFFPopup: PropTypes.bool
};

const mapStateToProps = ({ searchSettings, debugDetails }) => {
    return {
        productOptions: searchSettings.productLines,
        displayNFFPopup: debugDetails?.displayNFFPopup ?? false,
        error: searchSettings.error,
        getProductsIsLoading: searchSettings.getProductsActionState === LOAD_START,
        selectedProductLine: searchSettings.selectedProductLine,
        serialNumberRegex: searchSettings.serialNumberRegex
    };
};

export default withTranslation()(
    connect(mapStateToProps, { getInitInfo, getDebugDetails, userCancelsNFFPopup, setProductLine })(Search)
);
