import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "office-ui-fabric-react";

FormItem.propTypes = {
    value: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    labelName: PropTypes.string,
    handleChange: PropTypes.func,
    validateForm: PropTypes.func,
    isRequired: PropTypes.bool,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    textFieldAriaLabel: PropTypes.string
};

function FormItem(props) {

    const { handleChange, value, error, labelName, isRequired, touched, dataTestId, disabled, textFieldAriaLabel } = props;
    const  INPUT_MAX_LENGTH = parseInt(window.env.INPUT_MAX_LENGTH);

    return (
        <div data-testid={dataTestId} className="form__field__datapairs" >
            <label className={"form__field__label" + (isRequired ? " required" : "")}>{labelName}</label>
            <div className={"form__field__input__container" + (error !== undefined && touched ? ' error' : '')}>
                <TextField maxLength={INPUT_MAX_LENGTH} data-testid={dataTestId + "-input"} disabled={disabled} name={labelName} onChange={handleChange} value={value === undefined ? "" : value} ariaLabel={textFieldAriaLabel} required={isRequired}/>
                {touched && error && <span className="text-danger">{error}</span>}
            </div>
        </div>
    );
}

export default FormItem;