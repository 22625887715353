import {
    GET_DEBUG_DETAILS,
    CLEAR_DEBUG_DETAILS,
    SET_CURRENT_ERROR_CODE,
    GET_INIT_INFO,
    SAVE_REPAIR_ACTION,
    ASSIGN_REPAIR_ACTION,
    UNASSIGN_REPAIR_ACTION,
    SET_UNSAVED_CHANGES,
    CLEAR_ACTION_STATES,
    SET_PRODUCT_LINE,
    USER_CANCELS_NFF_POPUP,
    CREATE_GENERIC_REPAIR_ACTION,
    FILTER_NEXTSTEP_LIST,
    SFCS_ACK,
    SFCS_ACK_NOT_RECEIVED,
    SFCS_ACK_RECEIVED,
    SIGNALR_CONNECTION_IN_PROGRESS,
    SIGNALR_CONNECTION_FAIL,
    SIGNALR_CONNECTION_SUCCESS
} from "./types";

export const getDebugDetails = (searchText, product, userConfirmedNFF) => (dispatch) => {
    const encodedSearchText = encodeURIComponent(searchText.trim());
    const searchUrl = `/search?deviceSerialNumber=${encodedSearchText}&productLine=${product}&userConfirmedNFF=${userConfirmedNFF}&isRefresh=false`;
    return dispatch({
        type: GET_DEBUG_DETAILS,
        payload: {
            request: {
                method: "GET",
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                },
                url: searchUrl,
            },
        },
    });
};

export const userCancelsNFFPopup = () => (dispatch) => {
    return dispatch({
        type: USER_CANCELS_NFF_POPUP,
    })
}

export const filterNextStepList = (option, index, inputValue) => (dispatch) => {
    return dispatch({
        type: FILTER_NEXTSTEP_LIST,
        option,
        index,
        inputValue
    });
};

export const clearActionStates = () => (dispatch) => {
    return dispatch({
        type: CLEAR_ACTION_STATES,
    });
};

export const clearDutDetails = () => (dispatch) => {
    return dispatch({
        type: CLEAR_DEBUG_DETAILS,
    });
};

export const setCurrentErrorCode = (errorCode, operation) => (dispatch) => {
    return dispatch({
        type: SET_CURRENT_ERROR_CODE,
        errorCode, operation
    });
};

export const setUnsavedChanges = (hasUnsavedChanges) => (dispatch) => {
    return dispatch({
        type: SET_UNSAVED_CHANGES,
        hasUnsavedChanges,
    });
};

export const getInitInfo = () => (dispatch) => {
    return dispatch({
        type: GET_INIT_INFO,
        payload: {
            request: {
                method: "GET",
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                },
                url: `/init`,
            },
        },
    });
};

export const saveRepairActions = (saveRequest) => (dispatch) => {
    return dispatch({
        type: SAVE_REPAIR_ACTION,
        payload: {
            request: {
                method: "POST",
                url: "/Debug/Save",
                data: saveRequest,
            },
        },
    });
};

export const assignRepairActions = (assignRARequest) => (dispatch) => {
    return dispatch({
        type: ASSIGN_REPAIR_ACTION,
        payload: {
            request: {
                method: "POST",
                url: "/Debug/AssignRA",
                headers: {
                    "bypassSfcs": window.env.BYPASS_SFCS,
                    "mockSfcsEventSuccess": window.env.MOCK_SFCS_EVENT_SUCCESS,
                },
                data: assignRARequest,
            },
        },
    });
};

export const unassignRepairActions = (unassignRARequest) => (dispatch) => {
    return dispatch({
        type: UNASSIGN_REPAIR_ACTION,
        payload: {
            request: {
                method: "POST",
                url: "/Debug/UnassignRA",
                data: unassignRARequest,
            },
        },
    });
};

export const setProductLine = (productLine) => (dispatch) => {
    return dispatch({
        type: SET_PRODUCT_LINE,
        productLine
    })
}

export const callGenericRepairActionProfile = (request) => (dispatch) => {
    return dispatch({
        type: CREATE_GENERIC_REPAIR_ACTION,
        payload: {
            request: {
                method: "POST",
                url: "createGenericRepairActionProfile",
                data: request
            },
        }
    });
};

export const sfcsAckAction = () => (dispatch) => {
    return dispatch({
        type: SFCS_ACK
    });
}

export const sfcsAckActionNotReceived = () => (dispatch) => {
    return dispatch({
        type: SFCS_ACK_NOT_RECEIVED
    });
}

export const sfcsAckActionReceived = (name) => (dispatch) => {
    return dispatch({
        type: SFCS_ACK_RECEIVED,
        name
    });
}

export const signalRConnectionInProgress = () => (dispatch) => {
    return dispatch({
        type: SIGNALR_CONNECTION_IN_PROGRESS
    });
}

export const signalRConnectionFailed = (errorMessage) => (dispatch) => {
    return dispatch({
        type: SIGNALR_CONNECTION_FAIL,
        errorMessage
    });
}

export const signalRConnectionSuccess = () => (dispatch) => {
    return dispatch({
        type: SIGNALR_CONNECTION_SUCCESS
    });
}