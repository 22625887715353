import React, { Component, Fragment } from "react";
import { getAppInsights } from "../AppInsights/TelemetryService";
import "./Home.scss";
import Search from "../Search/Search";
import { connect } from "react-redux";
import { ProgressIndicator, MessageBarType } from "office-ui-fabric-react";
import { PropTypes } from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import {
  LOAD_START,
  LOAD_END,
  ErrorSource,
  ErrorMessageForSource,
  LOAD_INITIAL,
} from "../../store/actions/types";
import { callGenericRepairActionProfile } from "../../store/actions/details";
import { DebugDetails } from "../DebugDetails/DebugDetails";
import { ResponseHandler } from "../Common/responseHandler";
import MessageView from "../Common/MessageView/MessageView";
import ButtonPrimary from "../Common/ButtonPrimary";
import AlertDialog from "../Common/AlertDialog/AlertDialog";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.showNotification = this.showNotification.bind(this);
  }

  showNotification(errors) {
    errors.forEach((error) => {
      ResponseHandler.handleError(
        error.ErrorCode,
        true,
        error.ErrorMessage,
        error.InnerError
      );
    });
  }

  render() {
    const appInsights = getAppInsights();
    const {
      displayNFFPopup,
      componentState,
      isErrorState,
      errorMessageTitle,
      errorMessageSubTitle,
      repairState,
      showRepairActionSuccessMessage,
      showCreateGenericRepairActionOption,
      createGenericRepairActionState,
      callGenericRepairActionProfile: callGenericRepairActionProfileAction,
      dutInfo,
      repairGuid,
      signalRConnectionSuccess,
      signalRFailureErrorMessage
    } = this.props;

    const { ErrorCode, ErrorDescription, TestOperation } = dutInfo.ErrorDetails ? dutInfo.ErrorDetails.filter(err => err.OperatorMode === "Online") ? dutInfo.ErrorDetails.filter(err => err.OperatorMode === "Online")[0] : [] : {};

    const request = {
      ProductName: dutInfo.ProductName,
      LifeCycle: dutInfo.ProductLifeCycle,
      Operation: TestOperation,
      ErrorCode,
      ErrorDescription
    };
    appInsights &&
      appInsights.trackTrace({
        message: "Home Page Loaded",
      });

    return (
      <div className="home-container">
        <h1
          role="heading"
          aria-level="1"
          style={{ fontWeight: "2px", fontSize: "2px", color: "white" }}
        >
          Repair Next - Debug Analysis and Repair execution
        </h1>
        {componentState === LOAD_END &&
        (createGenericRepairActionState === LOAD_END || createGenericRepairActionState === LOAD_INITIAL) &&
        !isErrorState &&
        !displayNFFPopup &&
        !showRepairActionSuccessMessage ? (
          <DebugDetails repairState={repairState} repairGuid={repairGuid} />
        ) : (
          <Fragment>
            <div className="home-screen">
              <h2 role="heading" aria-level="1">
                <Trans i18nKey="home.landingText" />
              </h2>
              <Search />
              <br />
              {componentState === LOAD_END && isErrorState ? (
                <MessageView
                  dataTestId="error-message-bar"
                  title={errorMessageTitle}
                  subTitle={errorMessageSubTitle}
                  messageBarType={MessageBarType.error}
                />
              ) : null}
              {componentState === LOAD_END &&
              showCreateGenericRepairActionOption &&
              !showRepairActionSuccessMessage ? (
                <ButtonPrimary
                  dataTestid="create-generic-repair-action"
                  isDisabled={createGenericRepairActionState == LOAD_START}
                  isLoading={createGenericRepairActionState == LOAD_START}
                  buttonLabel="Associate generic Repair Actions"
                  loadingLabel="Creating..."
                  disableMessage="Creation in Progress..."
                  ariaLabel="Create Generic Repair Action"
                  onClickEvent={() => {
                    if (createGenericRepairActionState !== LOAD_START) {
                      callGenericRepairActionProfileAction(request);
                    }
                  }}
                />
              ) : null}
              {componentState === LOAD_END && showRepairActionSuccessMessage ? (
                <MessageView
                  dataTestId="repair-action-success-message-bar"
                  title={
                      "Generic Repair Action created, please search again to continue."
                  }
                  messageBarType={MessageBarType.success}
                />
              ) : null}
              {componentState === LOAD_START ? <ProgressIndicator /> : <hr />}
            </div>
          </Fragment>
        )}
        { !signalRConnectionSuccess && signalRFailureErrorMessage && <AlertDialog title="SignalR Connection Error" open={true} message={signalRFailureErrorMessage} />}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.errors) !== JSON.stringify(prevProps.errors) &&
      this.props.errors.length > 0
    ) {
      this.showNotification(this.props.errors);
    }
  }
}

Home.propTypes = {
  errors: PropTypes.array,
  isErrorState: PropTypes.bool,
  errorMessageTitle: PropTypes.string,
  errorMessageSubTitle: PropTypes.string,
  componentState: PropTypes.string,
  getDebugDetails: PropTypes.func,
  repairState: PropTypes.string,
  displayNFFPopup: PropTypes.bool,
  callGenericRepairActionProfile: PropTypes.func,
  createGenericRepairActionState: PropTypes.string,
  showCreateGenericRepairActionOption: PropTypes.bool,
  showRepairActionSuccessMessage: PropTypes.bool,
  dutInfo: PropTypes.object,
  repairGuid: PropTypes.string,
  signalRConnectionSuccess: PropTypes.bool,
  signalRFailureErrorMessage: PropTypes.string,
};

const mapStateToProps = ({ debugDetails }) => {
  let isErrorState = false;
  let errorMessageTitle = "";
  let errorMessageSubTitle = "";
  debugDetails.errors.forEach((error) => {
    if (
      error.Target === ErrorSource.NFF ||
      error.Target === ErrorSource.SFCS ||
      error.Target === ErrorSource.API ||
      error.Target === ErrorSource.TestResultService ||
      error.Target === ErrorSource.GenealogyV2Service ||
      error.Target === ErrorSource.RepairActionManagerService
    ) {
      isErrorState = true;
      errorMessageTitle = error.ErrorMessage;
      errorMessageSubTitle = ErrorMessageForSource[error.Target];
    }
  });
  return {
    errors: debugDetails.errors,
    isErrorState: isErrorState,
    errorMessageTitle: errorMessageTitle,
    errorMessageSubTitle: errorMessageSubTitle,
    componentState: debugDetails.componentState,
    repairState: debugDetails.repairState,
    displayNFFPopup: debugDetails.displayNFFPopup,
    createGenericRepairActionState: debugDetails.createGenericRepairActionState,
    showCreateGenericRepairActionOption:
    debugDetails.showCreateGenericRepairActionOption,
    showRepairActionSuccessMessage: debugDetails.showRepairActionSuccessMessage,
    dutInfo: debugDetails.dutInfo,
    repairGuid: debugDetails.repairGuid,
    signalRConnectionSuccess: debugDetails.signalRConnectionSuccess,
    signalRFailureErrorMessage: debugDetails.signalRFailureErrorMessage,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { callGenericRepairActionProfile })(Home)
);
