export const englishUIStrings = {
    "FeedbackSubtitle": "Send Feedback to Microsoft",
    "PrivacyStatement": "Privacy Statement",
    "Form": {
      "CommentPlaceholder": "Please do not include any confidential or personal information in your comment",
      "CategoryPlaceholder": "Select a category (optional)",
      "EmailPlaceholder": "Email (optional)",
      "RatingLabel": "Rating",
      "ScreenshotLabel": "Include screenshot",
      "Submit": "Submit",
      "Cancel": "Cancel",
      "EmailCheckBoxLabel": "You can contact me about this feedback",
      "PrivacyConsent": "IT admins for your organization will be able to view and manage your feedback data.",
      "PrivacyLabel": "By pressing submit, your feedback will be used to improve Microsoft products and services. ",
      "ScreenshotImgAltText": "Screenshot Preview"
    },
    "SingleForm": { "Title": "Please provide feedback" },
    "SmileForm": { "Anchor": "I like something", "Title": "What did you like?" },
    "FrownForm": {
      "Anchor": "I don't like something",
      "Title": "What did you not like?"
    },
    "IdeaForm": {
      "Anchor": "I have a suggestion",
      "Title": "What do you suggest?"
    },
    "BugForm": { "Anchor": "File a bug", "Title": "What is the bug?" },
    "ThanksPanel": {
      "Title": "Thank you!",
      "AppreciateText": "We appreciate your feedback. Your comments will help us improve.",
      "MyFeedbackDescription": "Want to get more engaged? Visit our Feedback forum to create, view and vote on community feedback.",
      "MyFeedbackLinkText": "My Feedback",
      "FeedbackPortalDescription": "Track your feedback and see how it is making a difference. Please allow some time for your latest feedback to show up.",
      "FeedbackPortalLinkText": "Feedback Forum",
      "Close": "Close"
    },
    "Floodgate": {
      "Nps": {
        "Prompt": {
          "Title": "We'd love your feedback!",
          "Question": "We have just two questions for you.",
          "Yes": "Sure",
          "No": "Not now"
        },
        "Comment": {
          "Question": "Please tell us more. Why did you choose that answer?"
        },
        "Rating": {
          "Question": "How likely are you to recommend this site to a friend or colleague?",
          "Points11Value0": "0 - Not at all likely",
          "Points11Value1": "1",
          "Points11Value2": "2",
          "Points11Value3": "3",
          "Points11Value4": "4",
          "Points11Value5": "5",
          "Points11Value6": "6",
          "Points11Value7": "7",
          "Points11Value8": "8",
          "Points11Value9": "9",
          "Points11Value10": "10 - Extremely likely",
          "Points5Value1": "1 - Not at all likely",
          "Points5Value2": "2",
          "Points5Value3": "3",
          "Points5Value4": "4",
          "Points5Value5": "5 - Extremely likely"
        }
      }
    },
    "CloseLabel": "Close"
  };
  