import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import "./Footer.scss";

Footer.propTypes = {
    ramUrl: PropTypes.string,
};


export function Footer({ ramUrl }) {
    return (
        <div className="container-fluid">
            <a className="ramurl" href={ramUrl}>{ramUrl && "Author Repair Actions using RAM Portal"}</a>

            <div className="privacy-notice-div">

                <span className="privacy-notice-anchor">
                    <a href="https://go.microsoft.com/fwlink/?LinkId=518021">
                        Privacy & Cookies
                    </a>
                </span>
                <span className="copyright-text"> © 2020 - Microsoft</span>
            </div>
        </div>
    );
}



const mapStateToProps = ({ searchSettings }) => {
    return {
        ramUrl: searchSettings.ramUrl,
    };
};

export default withTranslation()(connect(mapStateToProps, {})(Footer));
