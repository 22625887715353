import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { MessageBarType, TextField } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import ButtonPrimary from "../../Common/ButtonPrimary";
import MessageView from "../../Common/MessageView/MessageView";

CurrentComponentDetails.propTypes = {
  repairRecord: PropTypes.object,
  isRemoveLoading: PropTypes.bool,
  removeComponent: PropTypes.func,
  isReassemble: PropTypes.bool,
  isRecorded: PropTypes.bool,
  t: PropTypes.func
};

export function CurrentComponentDetails(props) {
  const { repairRecord, isRemoveLoading, removeComponent, isReassemble, isRecorded, t } = props;

  return (
    <div className="form__fields__container">
      <div className="form__field__datapairs" data-testid="old-serial-number">
        <label className="form__field__label">
          <Trans i18nKey="repairAndReplace.oldSerialNumber" />
        </label>
        <div className="form__field__input__container">
          <TextField
            data-testid="old-serial-number-input"
            name="SerialNumber"
            disabled
            value={repairRecord.RemoveComponent.ComponentSerialNumber}
            ariaLabel="Old serial number text box"
          />
        </div>
      </div>
      <div className="form__field__datapairs" data-testid="old-part-number">
        <label className="form__field__label">
          <Trans i18nKey="repairAndReplace.oldPartNumber" />
        </label>
        <div className="form__field__input__container">
          <TextField
            name="PartNumber"
            data-testid="old-part-number-input"
            disabled
            value={repairRecord.RemoveComponent.ComponentPartNumber}
            ariaLabel="Old part number text box"
          />
        </div>
      </div>
      <div className="form__field__datapairs" data-testid="old-lot-tracking-number">
        <label className="form__field__label">
          <Trans i18nKey="repairAndReplace.oldLotTrackingNumber" />
        </label>
        <div className="form__field__input__container">
          <TextField
            data-testid="old-lot-tracking-number-input"
            name="LotTrackingNumber"
            disabled
            value={repairRecord.RemoveComponent.LotTrackingNumber}
            ariaLabel="Old lot tracking number text box"
          />
        </div>
      </div>

      <div className="remove-component-details-container">
        {repairRecord.IsComponentRemoved && !isRecorded && (
          <MessageView
            dataTestId={"remove-component-message"}
            title={t("messages.removedSuccessfully")}
            subTitle={null}
            messageBarType={MessageBarType.success}
          />
        )}
        {
            isReassemble && !isRecorded && (
                <MessageView
                  dataTestId={"remove-component-message"}
                  title={t("messages.toBeReassembled")}
                  subTitle={null}
                  messageBarType={MessageBarType.warning}
                />
              )
        }
        {
          isReassemble && isRecorded && (
            <MessageView
              dataTestId={"remove-component-message"}
              title={t("messages.reassembledSuccessfully")}
              subTitle={null}
              messageBarType={MessageBarType.success}
            />
          )
        }
        {!isReassemble && (
          <ButtonPrimary
            dataTestid="remove-component-button"
            isLoading={isRemoveLoading}
            isDisabled={repairRecord.IsComponentRemoved || isRecorded}
            buttonLabel="Remove Component"
            loadingLabel="Removing..."
            disableMessage={
              isRecorded ? "The component details are already recorded." : "The component is already removed."
            }
            ariaLabel="Remove component button"
            onClickEvent={() => {
              if (!isRemoveLoading && !repairRecord.IsComponentRemoved) {
                removeComponent();
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

export default withTranslation()(CurrentComponentDetails);
