import { LOAD_INITIAL } from "../actions/types";


export const getDefaultRepairRecord = function () {
    return {
        serialNumber: "XXX",
        recordState: LOAD_INITIAL,
        finishState: LOAD_INITIAL,
        isRepairFinished: false,
        toShowAddNonCriticalView: true,
        toShowReassembleView: false,
        repairComponents: [],
        isRemoveLoading: false,
        lastActionAssignRA: false
    };
}

