import { AuthConfig } from "./AuthConfig";
import { UserAgentApplication } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};


export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};


export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.USER_READ
        ]
    },
    TOKEN: {
        scopes: [
            window.env.TOKEN_SCOPE
        ]
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: AuthConfig.appId,
        authority: AuthConfig.authority,
        redirectUri: AuthConfig.redirectUri,
        validateAuthority: true,
        postLogoutRedirectUri: AuthConfig.redirectUri,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        navigateFrameWait: 500,
        telemetry: {
            applicationName: "repair-tool",
            applicationVersion: "1.0.0",
            telemetryEmitter: () => {
                // console.log('Telemetry Events:', events);
            }
        }
    }
});