import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react/lib-commonjs/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { withTranslation } from "react-i18next";
import { t } from "../../../test-setup/i18next-test";

import "./AlertDialog.scss";

export const AlertDialogButtonType = {
    Default: 0,
    Danger: 1,
    Success: 2,
};

class AlertDialog extends Component {
    
    render() {
        const {
            title,
            message,
            okButtonLabel,
            okButtonType,
            okButtonIconName,
            cancelButtonLabel,
            cancelButtonType,
            open,
        } = this.props;

        const functionString = "function";

        
        const onClickOk = async () => {
            const { onClickOk } = this.props;
            if (typeof onClickOk === functionString) { await onClickOk(); }
        };
    
        const onClickCancel = async () => {
            const { onClickCancel } = this.props;
            if (typeof onClickCancel === functionString) { await onClickCancel(); }
        };

        
        return (
            <Fragment>
                <Dialog
                    minWidth="30rem"
                    hidden={!open}
                    onDismiss={this.onClickCancel}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title,
                        subText: message,
                        showCloseButton: false,
                        className: "alert-dialog",
                        styles: { subText: { fontSize: "0.9375rem" }, title: { fontSize: "1.5rem" } }
                    }}
                    modalProps={{ className: "alert-dialog-modal" }}
                >
                    <DialogFooter>
                        {okButtonLabel && <PrimaryButton iconProps={{ iconName: okButtonIconName }} className={`dialog-btn-primary${okButtonType != null ? ` btn-${Object.keys(AlertDialogButtonType).find((v, i) => i === okButtonType).toLowerCase()}` : ""}`} onClick={onClickOk} text={okButtonLabel} />}
                        {cancelButtonLabel && <DefaultButton className={`dialog-btn-secondary${cancelButtonType != null ? ` btn-${Object.keys(AlertDialogButtonType).find((v, i) => i === cancelButtonType).toLowerCase()}` : ""}`} primary={!okButtonType} onClick={onClickCancel} text={cancelButtonLabel} />}
                    </DialogFooter>
                </Dialog>
            </Fragment>
        );
    }
}

AlertDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onShow: PropTypes.func,
    okButtonLabel: PropTypes.string,
    okButtonType: PropTypes.oneOf([AlertDialogButtonType.Default, AlertDialogButtonType.Danger, AlertDialogButtonType.Success]),
    okButtonIconName: PropTypes.string,
    onClickOk: PropTypes.func,
    cancelButtonLabel: PropTypes.string,
    cancelButtonType: PropTypes.oneOf([AlertDialogButtonType.Default, AlertDialogButtonType.Danger, AlertDialogButtonType.Success]),
    onClickCancel: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

AlertDialog.defaultProps = {
    cancelButtonLabel: t("popupButton.cancel"),
    cancelButtonType: AlertDialogButtonType.Default,
    title: t("messages.confirmationMessage"),
    okButtonType: AlertDialogButtonType.Default,
};

export default withTranslation()(AlertDialog);