const getDataColumns = function (t) {

        return [
            {
                key: "TestOperation",
                name: t("testResultDetails.testOperation"),
                fieldName: "TestOperation",
                minWidth: 200,
                isResizable: true,
            },
            {
                key: "ErrorName",
                name: t("testResultDetails.errorCode"),
                fieldName: "ErrorCode",
                minWidth: 200,
                isResizable: true,
            },
            {
                key: "ErrorDescription",
                name: t("testResultDetails.errorDescription"),
                fieldName: "ErrorDescription",
                minWidth: 250,
                isResizable: true,
            },
            {
                key: "ErrorDetails",
                name: t("testResultDetails.errorDetail"),
                fieldName: "ErrorDetails",
                minWidth: 250,
                isResizable: true,
            },
            {
                key: "TestStation",
                name: t("testResultDetails.testStation"),
                fieldName: "TestStation",
                minWidth: 200,
                isResizable: true,
            },
            {
                key: "TestTime",
                name: t("testResultDetails.testTime"),
                fieldName: "TestTime",
                minWidth: 200,
                isResizable: true,
                isSorted: true,
                isSortedDescending: true
            },
            {
                key: "TestResult",
                name: t("testResultDetails.testResult"),
                fieldName: "TestResult",
                minWidth: 200,
                isResizable: true,
            },
            {
                key: "OperatorMode",
                name: t("testResultDetails.onlineOffline"),
                fieldName: "OperatorMode",
                minWidth: 200,
                isResizable: true,
            }
        ];
}

export default { getDataColumns }