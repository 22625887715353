import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../styles/default.scss";
import "./RepairResults.scss";
import Grid from "../Common/grid";
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getAppInsights } from "../AppInsights/TelemetryService";
import { MessageBarType } from "office-ui-fabric-react";
import RepairUtils from "./RepairUtils"
import MessageView from "../Common/MessageView/MessageView";

export class RepairResults extends Component {
    componentDidMount() {
        const appInsights = getAppInsights();
        appInsights && appInsights.trackTrace({ message: "Repair Results View loaded" });
    }

    render() {
        const { repairResults, t } = this.props;
        const { RepairResultsPageSize: pageSize } = window.env.DEBUG_DETAILS;
        const columns = RepairUtils.getDataColumns(t);
        return (
            <React.Fragment>
                <div className="repair-results" data-testid="repair-results">
                    <fieldset className="section__header">
                        <legend data-testid="repair-result-title" role="heading" aria-level="3">
                            <Trans i18nKey="repairResultDetails.title" />
                        </legend>
                        {repairResults.error !== null
                            ? (
                                <div className="grid__container">
                                    <MessageView
                                        title={repairResults.error}
                                        subTitle={null}
                                        messageBarType={MessageBarType.error}
                                    />
                                </div>
                            ) : (
                                <Grid items={repairResults.results} columns={columns} isPaginationEnable={true} pageSize={pageSize} />
                            )
                        }
                    </fieldset>
                </div>
            </React.Fragment>
        );
    }
}

RepairResults.propTypes = {
    repairResults: PropTypes.object,
    t: PropTypes.func
};

const mapStateToProps = ({ debugDetails }) => ({
    repairResults: debugDetails.repairResults,
});

export default withTranslation()(
    connect(
        mapStateToProps,
        {}
    )(RepairResults)
);
