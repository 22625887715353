import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { MessageBar } from "office-ui-fabric-react";


import "./MessageView.scss";
class MessageView extends Component {
    constructor(props) {
        super(props);
        this.errorCodeDropdownRef = React.createRef();
    }

    render() {
        const { messageBarType, title, subTitle, dataTestId } = this.props;

        return (
            <MessageBar
                messageBarType={messageBarType}
                data-testid={dataTestId}
                isMultiline={true}
            >
                <div data-testid={dataTestId + "-title"} className="messageView-title" > {title} </div>
                {subTitle &&
                    <div className="messageView-subTitle" >  {subTitle} </div>
                }
            </MessageBar>
        );
    }
}

MessageView.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    messageBarType: PropTypes.number,
    dataTestId: PropTypes.string,
};

export default withTranslation()(MessageView);
