import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import AppWithAuthProvider from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bluebird";
import { I18nextProvider } from "react-i18next";
import i18n from "../src/locate/i18n";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

const store = configureStore();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <AppWithAuthProvider />
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
