/**
 * Message
 */
export const Message = {
    RequiredErrorMessage: "Required",
    IncorrectFormatErrorMessage: "Only alphabets, digits and '-' are allowed as valid characters",
    RecordRepairError: "There was some error while recording this repair.",
    SuccessfullRecordRepair: "Repair Successfully Recorded",
    SuccessfullyRemovedComponent: "Removed Component Successfully",
    RepairFinished: "Repair Successfully Finished",
    GetProductLineOptionsFailMessage: "An error occurred. Please refresh the page to continue.",
    SearchInvalidCharactersError: "Only alphabets, digits and '-' are allowed as valid characters in search.",
    NFFPopupCancelledErrorMessage: "Test data doesn't exist for this SN, a non NFF scenario",
    NFFTestOperation: "Not Applicable",
    NextStepInvalidCharactersError: "Only alphanumeric, '_' and '-' are allowed as valid characters in NextStep.",
    NextStepLengthCharactersError: "NextStep can't be empty and should be of minimum length 2 and maximum 30.",
    SFCSAcknowledgementError: "There was some error received from SFCS.",
}