import React, { Component } from "react";
import PropTypes, { func } from "prop-types";
import "./DutInfo.scss";
import "../../styles/default.scss";
import { setCurrentErrorCode, setUnsavedChanges } from "../../store/actions/details";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAppInsights } from "../AppInsights/TelemetryService";
import { Dropdown } from "office-ui-fabric-react";
import AlertDialog, { AlertDialogButtonType as ButtonType } from "../Common/AlertDialog/AlertDialog";
import { RepairState } from "../../constants/repairState";
import { t } from "../../test-setup/i18next-test";
import { Message } from "../../constants/message";

const propTypes = {
    data: PropTypes.object,
    displayMode: PropTypes.string,
    setCurrentErrorCode: PropTypes.func,
    currentDebugErrorCode: PropTypes.string,
    currentOperation: PropTypes.string,
    hasUnsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
    repairState: PropTypes.string,
    isNFFRepair: PropTypes.bool
};

export class DutInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultSelectedKey: 1,
            dialog: {
                showDialog: false,
                title: "",
                message: "",
                okButtonLabel: "ok",
                okButtonType: ButtonType.Default,
                okButtonIconName: "",
                cancelButtonLabel: null,
                onClickOk: func,
                onClickCancel: func,
            },
            repairCompleted: false
        };
        this.onErrorCodeChange = this.onErrorCodeChange.bind(this);
        this.errorCodeDropdownRef = React.createRef();
        this.cancelDialog = this.cancelDialog.bind(this);
        this.onRepairComplete = this.onRepairComplete.bind(this);
    }

    componentDidMount() {
        const { currentDebugErrorCode, currentOperation, setCurrentErrorCode: setCurrentErrorCodeAction, data } = this.props;
        const appInsights = getAppInsights();
        appInsights && appInsights.trackTrace({ message: "Dut Info page loaded" });

        const errorCodeDropdown = this.errorCodeDropdownRef.current;
        if (errorCodeDropdown && errorCodeDropdown.props.options.length > 0) {
            if (currentDebugErrorCode) {
                setCurrentErrorCodeAction(currentDebugErrorCode, currentOperation);

                this.setState({
                    defaultSelectedKey: errorCodeDropdown.props.options.find(
                        (option) => option.ErrorCode.toUpperCase() === currentDebugErrorCode.toUpperCase()
                    ).key,
                });
            } else {
                const operation = data && data.ErrorDetails ? data.ErrorDetails.find((test) => test.key === 1).TestOperation : "";
                setCurrentErrorCodeAction(errorCodeDropdown.props.options.find((option) => option.key === 1).ErrorCode, operation);
            }
        }
    }

    cancelDialog() {
        this.setState({ dialog: { ...this.state.dialog, showDialog: false } });
    }

    onErrorCodeChange(e, selectedOption) {
        const { hasUnsavedChanges, setUnsavedChanges: setUnsavedChangesAction, data } = this.props;
        if (hasUnsavedChanges) {
            this.setState({
                dialog: {
                    showDialog: true,
                    title: t("messages.unsavedChangesPopupHeader"),
                    message: t("messages.unsavedChangesPopupMessage"),
                    okButtonLabel: t("popupButton.discardChanges"),
                    okButtonType: ButtonType.Danger,
                    okButtonIconName: "Delete",
                    cancelButtonLabel: t("popupButton.cancel"),
                    onClickOk: () => {
                        const operation = data && data.ErrorDetails ? data.ErrorDetails.find((test) => test.key === selectedOption.key).TestOperation : "";
                        this.props.setCurrentErrorCode(selectedOption.ErrorCode, operation);
                        this.setState({
                            defaultSelectedKey: selectedOption.key,
                        });
                        setUnsavedChangesAction(false);
                        this.cancelDialog();
                    },
                    onClickCancel: () => {
                        this.cancelDialog();
                    },
                },
            });
        } else {
            const operation = data && data.ErrorDetails ? data.ErrorDetails.find((test) => test.key === selectedOption.key).TestOperation : "";
            this.props.setCurrentErrorCode(selectedOption.ErrorCode, operation);
            this.setState({
                defaultSelectedKey: selectedOption.key,
            });
        }
    }

    onRepairComplete() {
        if (this.state.repairCompleted === false) {
            this.setState({
                dialog: {
                    showDialog: true,
                    title: t("messages.repairCompleteHeader"),
                    message: t("messages.repairComplete"),
                    okButtonLabel: t("popupButton.yes"),

                    cancelButtonLabel: null,
                    okButtonType: ButtonType.Success,
                    onClickOk: () => {
                        document.querySelector("*[data-testid=navlink-home]").click();
                    }
                },
                repairCompleted: true
            });
        }
    }

    render() {
        const { isNFFRepair, data, displayMode, repairState } = this.props;
        const { defaultSelectedKey, dialog } = this.state;
        const selectedErrorDetails = data && data.ErrorDetails ? data.ErrorDetails.find((test) => test.key === defaultSelectedKey) : [];
        let { ErrorCode, ErrorDescription, TestOperation, TestTime, OperatorMode, ErrorDetails } = data.ErrorDetails ? data.ErrorDetails[0] : {};

        if (repairState === RepairState.RepairComplete && displayMode !== "recordAndRepair") {
            this.onRepairComplete();
        }

        const { SerialNumber, RepairCount, RepairType, ProjectName, ProductName } = data;

        if (displayMode === "repairAction") {
            ErrorCode = selectedErrorDetails.ErrorCode;
            ErrorDescription = selectedErrorDetails.ErrorDescription;
            ErrorDetails = selectedErrorDetails.ErrorDetails;
            TestOperation = selectedErrorDetails.TestOperation;
            TestTime = selectedErrorDetails.TestTime;
            OperatorMode = selectedErrorDetails.OperatorMode;
        }

        const showErrorDetail = selectedErrorDetails && selectedErrorDetails.ErrorDetails !== undefined && selectedErrorDetails.ErrorDetails !== null && selectedErrorDetails.ErrorDetails !== "" ? true : false;

        if (isNFFRepair) {
            TestOperation = Message.NFFTestOperation;
        }

        return (
            <React.Fragment>
                <AlertDialog
                    title={dialog.title}
                    message={dialog.message}
                    okButtonLabel={dialog.okButtonLabel}
                    okButtonType={dialog.okButtonType}
                    okButtonIconName={dialog.title}
                    open={dialog.showDialog}
                    cancelButtonLabel={dialog.cancelButtonLabel}
                    onClickOk={() => {
                        dialog.onClickOk();
                    }}
                    onClickCancel={() => {
                        dialog.onClickCancel();
                    }}
                />
                <fieldset className="section__header">
                    <legend role="heading" aria-level="3">
                        <Trans i18nKey="dutInfo.title" />
                    </legend>
                    <div className="dutinfo__datacontainer">
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.serialNumber" />
                            </label>

                            <span data-testid="serial-number">{SerialNumber}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.errorCode" />
                            </label>
                            {displayMode && displayMode === "repairAction" ? (
                                <Dropdown
                                    className="error__code__dropdown dropdown__select"
                                    componentRef={this.errorCodeDropdownRef}
                                    notifyOnReselect={true}
                                    selectedKey={defaultSelectedKey}
                                    options={data.ErrorDetails}
                                    onChange={this.onErrorCodeChange}
                                    ariaLabel="Error code list dropdown"
                                />
                            ) : (
                                <span data-testid="error-code-text">{ErrorCode}</span>
                            )}
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.errorDescription" />
                            </label>
                            <span data-testid="error-description" style={{ cursor: "pointer" }} title={ErrorDescription}>{ErrorDescription}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.lastOperation" />
                            </label>
                            <span data-testid="last-operation">{TestOperation}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.errorTimeStamp" />
                            </label>
                            <span data-testid="error-timestamp">{TestTime}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.repairCount" />
                            </label>
                            <span data-testid="repair-count">{RepairCount}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.repairType" />
                            </label>
                            <span data-testid="repair-type">{RepairType}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.projectName" />
                            </label>
                            <span data-testid="project-name">{ProjectName}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.productName" />
                            </label>
                            <span data-testid="productName">{ProductName}</span>
                        </div>
                        <div className="dutinfo__datapairs text-truncate">
                            <label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.testType" />
                            </label>
                            <span data-testid="test-type">{OperatorMode}</span>
                        </div>
                        <div className="dutinfo__datapairs__errordetail text-truncate" >
                            < label className="dutinfo__datakey">
                                <Trans i18nKey="dutInfo.errorDetail" />
                            </label>
                            <span data-testid="test-type" style={{ cursor: "pointer" }} title={ErrorDetails}>{showErrorDetail ? ErrorDetails : <Trans i18nKey="dutInfo.errorDetailNoDataFound" />}</span>
                        </div>
                    </div>
                </fieldset>
            </React.Fragment >
        );
    }
}

DutInfo.propTypes = propTypes;

const mapStateToProps = ({ debugDetails }) => ({
    data: debugDetails.dutInfo,
    currentDebugErrorCode: debugDetails.currentDebugErrorCode,
    currentOperation: debugDetails.operation,
    hasUnsavedChanges: debugDetails.hasUnsavedChanges,
    repairState: debugDetails.repairState,
    isNFFRepair: debugDetails.isNFFRepair
});
export default withTranslation()(connect(mapStateToProps, { setCurrentErrorCode, setUnsavedChanges })(DutInfo));
