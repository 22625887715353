const getDataColumns = function (t) {
    return [
        {
            key: "RepairInstruction",
            name: t("repairResultDetails.repairInstruction"),
            fieldName: "RepairInstruction",
            minWidth: 180,
            isResizable: true,
            isSorted: false
        },
        {
            key: "ErrorCode",
            name: t("repairResultDetails.errorCode"),
            fieldName: "ErrorCode",
            minWidth: 180,
            isResizable: true,
        },
        {
            key: "ErrorDescription",
            name: t("repairResultDetails.errorDescription"),
            fieldName: "ErrorDescription",
            minWidth: 180,
            isResizable: true,
        },
        {
            key: "RepairAction",
            name: t("repairResultDetails.repairAction"),
            fieldName: "RepairAction",
            minWidth: 180,
            isResizable: true,
        },
        {
            key: "ComponentReplaced",
            name: t("repairResultDetails.componentReplaced"),
            fieldName: "ComponentReplaced",
            minWidth: 180,
            isResizable: true,
        },
        {
            key: "DebugCode",
            name: t("repairResultDetails.debugCode"),
            fieldName: "DebugCode",
            minWidth: 180,
            isResizable: true,
        },
        {
            key: "RepairTime",
            name: t("repairResultDetails.repairTime"),
            fieldName: "RepairTime",
            minWidth: 180,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true
        },
        {
            key: "FailureMode",
            name: t("repairResultDetails.failureMode"),
            fieldName: "FailureMode",
            minWidth: 180,
            isResizable: true,
        }
    ];
}




export default { getDataColumns }