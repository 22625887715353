import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../styles/default.scss";
import Grid from "../Common/grid";
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getAppInsights } from "../AppInsights/TelemetryService";
import OperationUtils from "./OperationUtils"
import "./OperationResult.scss";

export class OperationResult extends Component {
    componentDidMount() {
        const appInsigts = getAppInsights();
        appInsigts && appInsigts.trackTrace({ message: "Operation details loaded" });
    }
    render() {
        const { data, t } = this.props;
        const { TestResultsPageSize: pageSize } = window.env.DEBUG_DETAILS;
        const firstOnlineError = data.find(ele => ele.OperatorMode === "Online");
        if (firstOnlineError) {
            firstOnlineError["isHighlighted"] = true;
        }
        const columns = OperationUtils.getDataColumns(t);

        return (
            <React.Fragment>

                <div className="operation-results" data-testid="test-results">
                    <fieldset className="section__header">
                        <legend role="heading" aria-level="3">
                            <Trans i18nKey="testResultDetails.title" />
                        </legend>
                        <Grid items={data} columns={columns} isPaginationEnable={true} pageSize={pageSize} />
                    </fieldset>
                </div>

            </React.Fragment>
        );
    }
}

OperationResult.propTypes = {
    data: PropTypes.array,
    t: PropTypes.func
};

const mapStateToProps = ({ debugDetails }) => ({
    data: debugDetails.testResults,
});
export default withTranslation()(connect(mapStateToProps, {})(OperationResult));
