export const GET_DATA = "GET_DATA";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const CLEAR_DATA = "CLEAR_DATA";
export const GET_DEBUG_DETAILS = "GET_DEBUG_DETAILS";
export const GET_DEBUG_DETAILS_FAIL = "GET_DEBUG_DETAILS_FAIL";
export const GET_DEBUG_DETAILS_SUCCESS = "GET_DEBUG_DETAILS_SUCCESS";
export const CLEAR_DEBUG_DETAILS = "CLEAR_DEBUG_DETAILS";
export const LOAD_INITIAL = "LOAD_INITIAL";
export const LOAD_START = "LOAD_START";
export const LOAD_END = "LOAD_END";
export const POST_REPAIR_RECORD = "POST_REPAIR_RECORD";
export const POST_REPAIR_RECORD_FAIL = "POST_REPAIR_RECORD_FAIL";
export const POST_REPAIR_RECORD_SUCCESS = "POST_REPAIR_RECORD_SUCCESS";
export const FINISH_REPAIR = "FINISH_REPAIR";
export const FINISH_REPAIR_FAIL = "FINISH_REPAIR_FAIL";
export const FINISH_REPAIR_SUCCESS = "FINISH_REPAIR_SUCCESS";
export const REMOVE_COMPONENT = "REMOVE_COMPONENT";
export const REMOVE_COMPONENT_FAIL = "REMOVE_COMPONENT_FAIL";
export const REMOVE_COMPONENT_SUCCESS = "REMOVE_COMPONENT_SUCCESS";
export const SET_CURRENT_ERROR_CODE = "SET_CURRENT_ERROR_CODE";
export const GET_REPAIR_ACTIONS = "GET_REPAIR_ACTIONS";
export const GET_REPAIR_ACTIONS_FAIL = "GET_REPAIR_ACTIONS_FAIL";
export const GET_REPAIR_ACTIONS_SUCCESS = "GET_REPAIR_ACTIONS_SUCCESS";
export const SAVE_REPAIR_ACTION = "SAVE_REPAIR_ACTION";
export const SAVE_REPAIR_ACTION_SUCCESS = "SAVE_REPAIR_ACTION_SUCCESS";
export const SAVE_REPAIR_ACTION_FAIL = "SAVE_REPAIR_ACTION_FAIL";

export const CREATE_GENERIC_REPAIR_ACTION = "CREATE_GENERIC_REPAIR_ACTION";
export const CREATE_GENERIC_REPAIR_ACTION_SUCCESS = "CREATE_GENERIC_REPAIR_ACTION_SUCCESS";
export const CREATE_GENERIC_REPAIR_ACTION_FAIL = "CREATE_GENERIC_REPAIR_ACTION_FAIL";

export const ASSIGN_REPAIR_ACTION = "ASSIGN_REPAIR_ACTION";
export const ASSIGN_REPAIR_ACTION_SUCCESS = "ASSIGN_REPAIR_ACTION_SUCCESS";
export const ASSIGN_REPAIR_ACTION_FAIL = "ASSIGN_REPAIR_ACTION_FAIL";

export const UNASSIGN_REPAIR_ACTION = "UNASSIGN_REPAIR_ACTION";
export const UNASSIGN_REPAIR_ACTION_SUCCESS = "UNASSIGN_REPAIR_ACTION_SUCCESS";
export const UNASSIGN_REPAIR_ACTION_FAIL = "UNASSIGN_REPAIR_ACTION_FAIL";

export const GET_INIT_INFO = "GET_INIT_INFO";
export const GET_INIT_INFO_SUCCESS = "GET_INIT_INFO_SUCCESS";
export const GET_INIT_INFO_FAIL = "GET_INIT_INFO_FAIL";

export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";

export const CLEAR_ACTION_STATES = "CLEAR_ACTION_STATES";
export const CLEAR_REPAIR_ACTION_STATE = "CLEAR_REPAIR_ACTION_STATE";

export const SET_PRODUCT_LINE = "SET_PRODUCT_LINE";

export const ReplaceNonCriticalActionCode = "Replace Non-Critical";
export const Reassemble = "REASSEMBLE";

export const USER_CANCELS_NFF_POPUP = "USER_CANCELS_NFF_POPUP";
export const NFF_REPAIR_ERROR_CODE = "NFFREPAIR";

export const FILTER_NEXTSTEP_LIST = "FILTER_NEXTSTEP_LIST";

export const SFCS_ACK = "SFCS_ACK";
export const SFCS_ACK_RECEIVED = "SFCS_ACK_RECEIVED";
export const SFCS_ACK_NOT_RECEIVED = "SFCS_ACK_NOT_RECEIVED";
export const SIGNALR_CONNECTION_IN_PROGRESS = "SIGNALR_CONNECTION_IN_PROGRESS";
export const SIGNALR_CONNECTION_FAIL = "SIGNALR_CONNECTION_FAIL";
export const SIGNALR_CONNECTION_SUCCESS = "SIGNALR_CONNECTION_SUCCESS";



export const RecordMessageType = {
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
};

export const ErrorSource = {
    SFCS: 'SFCS',
    TestResultService: 'OperationResultService',
    RepairResultService: 'RepairResultService',
    GenealogyV2Service: 'GenealogyV2Service',
    RepairActionManagerService: 'RepairActionManagerService',
    API: 'API',
    NFF: 'NFF'
};

export const ErrorMessageForSource = {
    SFCS: "This error is raised by SFCS",
    OperationResultService: "This error is raised by OperationResultService",
    RepairResultService: "This error is raised by RepairResultService",
    GenealogyV2Service: "This error is raised by GenealogyV2Service",
    RepairActionManagerService: "This error is raised by RepairActionManagerService",
    API: "This error is raised by API",
}