import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import FormItem from "./FormItem";
import { Dropdown } from "office-ui-fabric-react";
import "../../../styles/default.scss";
import { ComponentLocationOptions, DefaultSelectedOptions, FailureModeOptions, FailureSourceOptions } from "../RepairAndRecordUtils";

class RepairAndRecordForm extends Component {
  constructor(props) {
    super(props);
    this.errorCodeDropdownRef = React.createRef();
  }

  getKey(selectedOption, options) {
    let key;
    options.forEach((element) => {
      if (element.text === selectedOption) key = element.key;
    });
    return key !== undefined ? key : null;
  }

  render() {
    const {
      handleChange,
      values,
      errors,
      touched,
      t,
      currentComponent,
      isLoading,
      selectedTab,
      criticalComponentsForReassembly,
      isReassemble
    } = this.props;

    const disabled = isLoading || (currentComponent?.FailureMode ? true : false);

    return (
      <Fragment>
        {(!isReassemble || selectedTab === t("repairAndReplace.addReassemble") || selectedTab === t("repairAndReplace.addNonCritical")) && (
          <div className="form__fields__container">
            <Fragment>
              {!currentComponent && selectedTab === t("repairAndReplace.addNonCritical") && (
                <div className="form__field__datapairs">
                  <label className="form__field__label required">{t("repairAndReplace.componentLocation")}</label>
                  <div className="form__field__input__container">
                    <Dropdown
                      placeholder="Select an option"
                      notifyOnReselect={true}
                      disabled={disabled}
                      onChange={(e, selectedKey) => {
                        handleChange("ComponentLocation", selectedKey.text);
                      }}
                      options={ComponentLocationOptions}
                      ariaLabel="Component Location drop down"
                    />
                  </div>
                </div>
              )}

              {!currentComponent && selectedTab === t("repairAndReplace.addReassemble") && (
                <div className="form__field__datapairs">
                  <label className="form__field__label required">{t("repairAndReplace.componentLocation")}</label>
                  <div className="form__field__input__container">
                    <Dropdown
                      placeholder="Select an option"
                      notifyOnReselect={true}
                      disabled={disabled}
                      onChange={(e, selectedKey) => {
                        handleChange("ComponentLocation", selectedKey.text);
                      }}
                      options={criticalComponentsForReassembly}
                      ariaLabel="Component Location drop down"
                    />
                  </div>
                </div>
              )}

              {(!isReassemble || selectedTab === t("repairAndReplace.addNonCritical")) && (
                <Fragment>
                  <FormItem
                    dataTestId="serial-number"
                    handleChange={(e, newValue) => {
                      handleChange("SerialNumber", newValue);
                    }}
                    disabled={disabled}
                    isRequired={currentComponent && currentComponent.RemoveComponent.ComponentSerialNumber !== null && currentComponent.RemoveComponent.ComponentSerialNumber !== ""}
                    value={values.SerialNumber}
                    touched={touched.SerialNumber !== undefined && touched.SerialNumber}
                    error={errors.SerialNumber}
                    labelName={t("repairAndReplace.serialNumber")}
                    textFieldAriaLabel="New serial number text input"
                  />

                  <FormItem
                    dataTestId="part-number"
                    handleChange={(e, newValue) => {
                      handleChange("PartNumber", newValue);
                    }}
                    disabled={disabled}
                    isRequired={false}
                    value={values.PartNumber}
                    error={errors.PartNumber}
                    touched={touched.PartNumber !== undefined && touched.PartNumber}
                    labelName={t("repairAndReplace.partNumber")}
                    textFieldAriaLabel="New part number text input"
                  />

                  <FormItem
                    dataTestId="lot-tracking-number"
                    handleChange={(e, newValue) => {
                      handleChange("LotTrackingNumber", newValue);
                    }}
                    disabled={disabled}
                    isRequired={currentComponent && currentComponent.RemoveComponent.LotTrackingNumber !== null && currentComponent.RemoveComponent.LotTrackingNumber !== ""}
                    touched={touched.LotTrackingNumber !== undefined && touched.LotTrackingNumber}
                    value={values.LotTrackingNumber}
                    error={errors.LotTrackingNumber}
                    labelName={t("repairAndReplace.lotTrackingNumber")}
                    textFieldAriaLabel="New serial number text input"
                  />

                  <div className="form__field__datapairs">
                    <label className="form__field__label required">{t("repairAndReplace.failureMode")}</label>
                    <div className="form__field__input__container">
                      <Dropdown
                        disabled={disabled}
                        data-testid="failure-mode-dropdown"
                        className="dropdown__select"
                        placeholder="Select an option"
                        notifyOnReselect={true}
                        selectedKey={this.getKey(values.FailureMode, FailureModeOptions)}
                        onChange={(e, selectedKey) => {
                          handleChange("FailureMode", selectedKey.text);
                        }}
                        defaultSelectedKey={DefaultSelectedOptions.FailureMode.key}
                        options={FailureModeOptions}
                        ariaLabel="Failure mode drop down"
                        required
                      />
                    </div>
                  </div>

                  <div className="form__field__datapairs">
                    <label className="form__field__label required">{t("repairAndReplace.failureSource")}</label>
                    <div className="form__field__input__container">
                      <Dropdown
                        disabled={disabled}
                        data-testid="failure-source-dropdown"
                        className="dropdown__select"
                        placeholder="Select an option"
                        defaultSelectedKey={DefaultSelectedOptions.FailureSource.key}
                        notifyOnReselect={true}
                        selectedKey={this.getKey(values.FailureSource, FailureSourceOptions)}
                        onChange={(e, selectedKey) => {
                          handleChange("FailureSource", selectedKey.text);
                        }}
                        options={FailureSourceOptions}
                        ariaLabel="Failure source drop down"
                        required
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        )}
      </Fragment>
    );
  }
}

RepairAndRecordForm.propTypes = {
  selectedTab: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  currentComponent: PropTypes.object,
  handleChange: PropTypes.func,
  validateForm: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  t: PropTypes.func,
  toShowNonCriticalView: PropTypes.bool,
  toShowReassembleView: PropTypes.bool,
  criticalComponentsForReassembly: PropTypes.array,
  reassembleComponentList: PropTypes.array,
  isReassemble: PropTypes.bool
};

export default withTranslation()(RepairAndRecordForm);
