import React, { Component, Fragment } from "react";
import PropTypes, { func } from "prop-types";
import "../../styles/default.scss";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAppInsights } from "../AppInsights/TelemetryService";
import { TagPicker, TextField, Dropdown, MessageBarType, MessageBar, ComboBox } from "office-ui-fabric-react";
import "./DebugAction.scss";
import { Formik, ErrorMessage, Form } from "formik";
import { Message } from "../../constants/message";
import DebugActionUtils from "./DebugActionUtils";
import ButtonPrimary from "../Common/ButtonPrimary";
import {
    assignRepairActions,
    saveRepairActions,
    unassignRepairActions,
    setUnsavedChanges,
    clearActionStates,
    filterNextStepList
} from "../../store/actions/details";
import { LOAD_END, LOAD_START, NFF_REPAIR_ERROR_CODE } from "../../store/actions/types";
import { ResponseHandler } from "../Common/responseHandler";
import { RepairState } from "../../constants/repairState";
import { RepairToolActions } from "../../constants/repairToolActions";
import { OperatorMode } from "../../constants/operatorMode";
import AlertDialog, {
    AlertDialogButtonType as ButtonType
} from "../Common/AlertDialog/AlertDialog";
import { t } from "../../test-setup/i18next-test";
import MessageView from "../Common/MessageView/MessageView";
import ProgressWithTime from "../Common/ProgressWithTime/ProgressWithTime";

/**
 * PropTypes for DebugAction
 */
const propTypes = {
    currentErrorCode: PropTypes.string,
    currentOperation: PropTypes.string,
    currentDebugDetails: PropTypes.object,
    currentRepairConditions: PropTypes.array,
    currentDebugFormData: PropTypes.object,
    savedDebugDetails: PropTypes.array,
    debonding: PropTypes.array,
    nextStep: PropTypes.string,
    repairComment: PropTypes.string,
    repairState: PropTypes.string,
    serialNumber: PropTypes.string,
    saveRepairActions: PropTypes.func,
    filterNextStepList: PropTypes.func,
    saveIsLoading: PropTypes.bool,
    assignRepairActions: PropTypes.func,
    assignRAIsLoading: PropTypes.bool,
    unassignRepairActions: PropTypes.func,
    unassignRAIsLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    errorMessageSubtitle: PropTypes.string,
    isActionCompleted: PropTypes.bool,
    saveActionState: PropTypes.string,
    assignRAActionState: PropTypes.string,
    unAssignRAActionState: PropTypes.string,
    setUnsavedChanges: PropTypes.func,
    clearActionStates: PropTypes.func,
    onlineErrorCode: PropTypes.string,
    nextStepList: PropTypes.array,
    fullNextStepList: PropTypes.array,
    nextStepRegex: PropTypes.string,
    isEventBased: PropTypes.bool,
    sfcsAckActionState: PropTypes.string,
};

/**
 * DebugAction Class
 */
export class DebugAction extends Component {
    /**
     * ctor
     * @param {} props - Props
     */
    constructor(props) {
        super(props);
        this.state = {
            isOverlayVisible: [],
            initialFormValues: {},
            dialog: {
                showDialog: false,
                title: "",
                message: "",
                okButtonLabel: null,
                okButtonType: ButtonType.Default,
                okButtonIconName: "",
                cancelButtonLabel: null,
                onClickOk: func,
                onClickCancel: func,
            },
            nextStepErrorMessage: ""
        };

        this.filterRepairConditions = this.filterRepairConditions.bind(this);
        this.transformFormData = this.transformFormData.bind(this);
        this.repairConditionsChange = this.repairConditionsChange.bind(this);
        this.repairConditionRef = React.createRef();
        this.resetForm = this.resetForm.bind(this);
        this.createSaveRequest = this.createSaveRequest.bind(this);
        this.createAssignRARequest = this.createAssignRARequest.bind(this);
        this.postSaveorAssignRA = this.postSaveorAssignRA.bind(this);
        this.formRef = React.createRef();
    }

    /**
     * reset the form to initial values
     */
    resetForm() {
        this.setState({
            initialFormValues: Object.assign(
                {},
                {
                    repairConditions: [],
                    repairActions: [],
                    repairActionCodes: [],
                    debugFlow: "",
                    defectiveComponent: [],
                    collateralComponent: [],
                    reassembleComponent: [],
                    comment: "",
                    debonding: [],
                    nextStep: "",
                }
            ),
        });
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        const appInsights = getAppInsights();
        appInsights && appInsights.trackTrace({ message: "Debug action loaded" });
        this.resetForm();
        if (this.props.currentErrorCode) {
            this.transformFormData(this.props);
        }
    }

    /**
     * should component update
     * @param {} nextProps - Next Props
     */
    shouldComponentUpdate(nextProps) {
        const tagPickerElement = document.querySelector(
            "*[data-testid='repairConditionsTagPicker'] .ms-FocusZone"
        );

        if (tagPickerElement) {
            tagPickerElement.removeAttribute("role");
        }
        if ((nextProps.currentErrorCode !== this.props.currentErrorCode) || (JSON.stringify(this.props.currentDebugFormData) !== JSON.stringify(nextProps.currentDebugFormData))) {
            this.resetForm();
            this.transformFormData(nextProps);
        }
        return true;
    }

    /**
     * Filter Repair Conditions based on the text
     * @param {} filterText - Filter Text
     * @param {} selectedItemsList - Items selected
     */
    filterRepairConditions(filterText, selectedItemsList) {
        const selectedItemsKeys = selectedItemsList.map((item) => item.key);
        const { currentRepairConditions } = this.props;
        return filterText
            ? currentRepairConditions?.filter(
                (tag) =>
                    tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !selectedItemsKeys.includes(tag.key)
            )
            : currentRepairConditions?.filter((tag) => !selectedItemsKeys.includes(tag.key));
    }

    /**
     * onChange for debonding
     * @param {*} item - Item
     * @param {*} selectedItems - Selected Items 
     * @param {*} setFieldValue - Formik method to set field value
     */
    debondingChange(item, selectedItems, setFieldValue, setFieldTouched) {
        let newSelectedItems = [];
        if (item.selected) {
            const group2 = ["Retest", "Quarantine", "Salvage"];
            if ((selectedItems.filter(r => group2.includes(r)).length !== 0) && group2.includes(item.key)) {
                selectedItems = selectedItems.filter(r => !group2.includes(r));
            }
            newSelectedItems = [...selectedItems, item.key];  
        } else {
            var index = selectedItems.indexOf(item.key);
            selectedItems.splice(index, 1);
            newSelectedItems = [...selectedItems];
        }
        setFieldValue("debonding", Object.assign([], newSelectedItems), false);
        setFieldTouched("debonding", true, false);
    }

    /**
     * onChange for Repair Conditions
     * @param {*} items - Items
     * @param {*} setFieldValue - Formik method to set field value
     * @param {*} setFieldTouched - Formik method to set field touched
     */
    repairConditionsChange(items, setFieldValue, setFieldTouched) {
        const { savedDebugDetails, currentErrorCode } = this.props;

        let allRepairActions = [];

        items.forEach((item) => {
            allRepairActions = allRepairActions.concat(
                this.props.currentDebugDetails.RepairActions.filter((repairAction) => repairAction.RepairCondition === item.key)
            );
        });
        var currentDebug = DebugActionUtils.mapRepairActions(allRepairActions);
        const filteredRepairActions = savedDebugDetails
            .map((debugDetail) => debugDetail.formValue)
            .filter((f) => f.ErrorCode !== currentErrorCode)
            .flat()
            .map((f) => f.RepairActions)
            .filter((f) => f !== null)
            .flat();
        const { defectiveComponentList, collateralComponentList, reassembleComponentList } = DebugActionUtils.fetchDefectiveCollateralComponentsList(
            filteredRepairActions
        );
        setFieldValue("repairConditions", Object.assign([], currentDebug.repairConditions), true);
        setFieldValue("repairActions", Object.assign([], currentDebug.repairActions), false);
        setFieldValue("repairActionCodes", Object.assign([], currentDebug.repairActionCodes), false);
        const allDefectiveList = [...defectiveComponentList, ...currentDebug.defectiveComponent].filter(c => c.key != "NULL");
        const uniqueDefectiveKeysList = [...new Set(allDefectiveList.map((val) => val.key).flat())];
        const uniqueDefectiveList = uniqueDefectiveKeysList.map((r) => {
            return { key: r, name: r };
        });
        setFieldValue("defectiveComponent", uniqueDefectiveList, false);
        const allCollateralList = [...collateralComponentList, ...currentDebug.collateralComponent].filter(c => c.key != "NULL");
        const uniqueCollateralKeysList = [...new Set(allCollateralList.map((val) => val.key).flat())];
        const uniqueCollateralList = uniqueCollateralKeysList.map((r) => {
            return { key: r, name: r };
        });

        setFieldValue(
            "collateralComponent",
            Object.assign(
                [],
                uniqueCollateralList.filter((val) => !uniqueDefectiveKeysList.includes(val.key))
            ),
            false
        );

        const allReassembleList = [...reassembleComponentList, ...currentDebug.reassembleComponent].filter(c => c.key != "NULL");
        const uniqueReassembleKeysList = [...new Set(allReassembleList.map((val) => val.key).flat())];
        const uniqueReassembleList = uniqueReassembleKeysList.map((r) => {
            return { key: r, name: r };
        });
        setFieldValue(
            "reassembleComponent",
            Object.assign(
                [],
                uniqueReassembleList.filter((val) => !uniqueDefectiveKeysList.includes(val.key) && !uniqueCollateralKeysList.includes(val.key))
            ),
            false
        );
        setFieldTouched("repairConditions", true, false);
    }

    /**
     * Transform data for the form
     * @param {*} currentDebugSavedData - Current debug Form data 
     */
    transformFormData(props) {
        const currentDebugSavedData = props.currentDebugFormData;
        const { debonding, nextStep, repairComment, savedDebugDetails } = props;
        const savedRepairActions = savedDebugDetails
            .map((debugDetail) => debugDetail.formValue)
            .flat()
            .map((f) => f.RepairActions)
            .filter((f) => f !== null)
            .flat();
        const {
            defectiveComponentList,
            collateralComponentList,
            reassembleComponentList
        } = DebugActionUtils.fetchDefectiveCollateralComponentsList(savedRepairActions);
        if (currentDebugSavedData) {
            var currentDebug = DebugActionUtils.mapRepairActions(currentDebugSavedData.RepairActions);
            this.setState({
                initialFormValues: Object.assign({}, this.state.initialFormValues, {
                    repairConditions: currentDebug.repairConditions || [],
                    repairActions: currentDebug.repairActions || [],
                    repairActionCodes: currentDebug.repairActionCodes || [],
                    debugFlow: currentDebugSavedData.DebugFlow,
                    defectiveComponent: defectiveComponentList || [],
                    collateralComponent: collateralComponentList || [],
                    reassembleComponent: reassembleComponentList || [],
                    comment: repairComment,
                    debonding: Object.assign([], debonding) || [],
                    nextStep: nextStep,
                }),
            });
            if (this.formRef && this.formRef.current && this.formRef.current.resetForm) {
                this.formRef.current.resetForm();
            }
        }
        else {
            this.setState({
                initialFormValues: Object.assign({}, this.state.initialFormValues, {
                    repairConditions: [],
                    repairActions: [],
                    repairActionCodes: [],
                    debugFlow: "",
                    defectiveComponent: defectiveComponentList || [],
                    collateralComponent: collateralComponentList || [],
                    reassembleComponent: reassembleComponentList || [],
                    comment: repairComment,
                    debonding: Object.assign([], debonding) || [],
                    nextStep: nextStep,
                }),
            });
        }
    }

    /**
     * Component will unmount
     */
    componentWillUnmount() {
        this.props.clearActionStates();
    }

    /**
     * Post action to be performed after Save or Assign RA
     * @param {*} values Values
     * @param {*} reset Reset function
     */
    postSaveorAssignRA(values, reset, setTouched) {
        const { errorMessage } = this.props;
        if (values.touched) {
            setTouched(values.touched);
        }
        if (!errorMessage) {
            reset({ values });
        }
    }

    /**
     * Render method
     */
    render() {
        const { initialFormValues, dialog, nextStepErrorMessage } = this.state;
        const INPUT_MAX_LENGTH = parseInt(window.env.INPUT_MAX_LENGTH);

        const {
            saveIsLoading,
            savedDebugDetails,
            repairState,
            saveRepairActions: saveRepairActionsAction,
            assignRepairActions: assignRepairActionsAction,
            assignRAIsLoading,
            unassignRepairActions: unassignRepairActionsAction,
            unassignRAIsLoading,
            errorMessage,
            errorMessageSubtitle,
            isActionCompleted,
            saveActionState,
            assignRAActionState,
            unAssignRAActionState,
            setUnsavedChanges: setUnsavedChangesAction,
            onlineErrorCode,
            currentRepairConditions,
            nextStepList,
            fullNextStepList,
            filterNextStepList,
            nextStepRegex,
            isEventBased,
            sfcsAckActionState
        } = this.props;

        let actionName = "";
        if (saveActionState === LOAD_END) actionName = RepairToolActions.Save;
        else if (assignRAActionState === LOAD_END) actionName = RepairToolActions.AssignRA;
        else if (unAssignRAActionState === LOAD_END) actionName = RepairToolActions.UnassignRA;

        const dataSavedForAllErrorCodes =
            savedDebugDetails.filter(
                (debugDetail) =>
                    debugDetail.errorCode.toUpperCase() !== NFF_REPAIR_ERROR_CODE &&
                    (debugDetail.formValue === undefined ||
                        debugDetail.formValue.RepairActions === null ||
                        debugDetail.formValue.RepairActions === undefined ||
                        debugDetail.formValue.RepairActions.length === 0)
            ).length === 0;

        const dataSavedForOnlineErrorCode =
            savedDebugDetails.filter(
                (debugDetail) =>
                    debugDetail.errorCode.toUpperCase() === onlineErrorCode?.toUpperCase() &&
                    debugDetail.formValue !== undefined &&
                    debugDetail.formValue.RepairActions !== null &&
                    debugDetail.formValue.RepairActions !== undefined &&
                    debugDetail.formValue.RepairActions.length !== 0
            ).length !== 0;

        const errorCodesWithRepairActionNotAssigned =
            savedDebugDetails.filter(
                (debugDetail) =>
                    debugDetail.errorCode.toUpperCase() !== NFF_REPAIR_ERROR_CODE &&
                    (debugDetail.formValue === undefined ||
                        debugDetail.formValue.RepairActions === null ||
                        debugDetail.formValue.RepairActions === undefined ||
                        debugDetail.formValue.RepairActions.length === 0)
            ).map(debugDetail => debugDetail.errorCode);

        const allDebugControlsDisabled =
            ![RepairState.Init, RepairState.DebugSave].includes(repairState) ||
            saveIsLoading ||
            assignRAIsLoading ||
            unassignRAIsLoading;

        const repairConditionsNotAvailable = currentRepairConditions === undefined;

        if (errorMessage && isActionCompleted) {
            ResponseHandler.handleError(actionName, true, errorMessage, null);
        } else if (!errorMessage && isActionCompleted && (!isEventBased || sfcsAckActionState === LOAD_END)) {
            switch (actionName) {
                case RepairToolActions.Save:
                    ResponseHandler.handleSuccess("Assign RA Page - Save", true, "Repair Actions Saved Successfully.");
                    break;
                case RepairToolActions.AssignRA:
                    ResponseHandler.handleSuccess("Assign RA Page - AssignRA", true, "Repair Actions Assigned Successfully.");
                    break;
                case RepairToolActions.UnassignRA:
                    ResponseHandler.handleSuccess("Assign RA Page - UnassignRA", true, "Repair Actions Unassigned Successfully.");
                    break;
                default:
                    break;
            }
            this.props.clearActionStates();
        }

        return (
            <React.Fragment>
                <div className="debug__action__container">
                    <fieldset className="section__header">
                        <legend>
                            <Trans i18nKey="currentDebugAction.title" />
                        </legend>
                        <AlertDialog
                            title={dialog.title}
                            message={dialog.message}
                            okButtonLabel={dialog.okButtonLabel}
                            okButtonType={dialog.okButtonType}
                            okButtonIconName={dialog.title}
                            open={dialog.showDialog}
                            cancelButtonLabel={dialog.cancelButtonLabel}
                            onClickOk={() => {
                                dialog.onClickOk();
                            }}
                            onClickCancel={() => {
                                dialog.onClickCancel();
                            }}
                        />
                        <Formik
                            innerRef={this.formRef}
                            enableReinitialize={true}
                            initialValues={initialFormValues}
                            onSubmit={async (values, { resetForm: reset, setTouched }) => {
                                if (values.actionMethod === RepairToolActions.Save) {
                                    const selectedRepairConditions = values.repairConditions.map((ra) => ra.key);
                                    const saveRequest = this.createSaveRequest(values, selectedRepairConditions);
                                    await saveRepairActionsAction(saveRequest);
                                } else if (values.actionMethod === RepairToolActions.AssignRA) {
                                    const assignRARequest = this.createAssignRARequest(values);
                                    await assignRepairActionsAction(assignRARequest);
                                }

                                this.postSaveorAssignRA(values, reset, setTouched);
                            }}>
                            {({ values, setFieldTouched, setFieldValue, submitForm, dirty, touched }) => (
                                <Form role="form">
                                    <div className="form__fields__container">
                                        {currentRepairConditions === undefined && (
                                            <Fragment>
                                                <MessageBar ariaLabel="Repair Conditions Not Available Message Bar">
                                                    {t("messages.repairConditionsNotAvailable")}
                                                </MessageBar>
                                            </Fragment>
                                        )}
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label required">
                                                <Trans i18nKey="currentDebugAction.repairConditions" />
                                            </label>
                                            <div
                                                data-testid="repairConditionsTagPicker"
                                                className={
                                                    "form__field__input__container" +
                                                    (!repairConditionsNotAvailable && dirty && values.repairConditions.length === 0 ? " error" : "")
                                                }>
                                                <TagPicker
                                                    inputProps={{
                                                        "data-testid": "repairConditionsTagPicker--Input",
                                                        "aria-label": "Repair conditions tag picker",
                                                        "required": true
                                                    }}
                                                    name="repairConditions"
                                                    className="tag__picker"
                                                    componentRef={this.repairConditionRef}
                                                    onResolveSuggestions={this.filterRepairConditions}
                                                    onChange={(items) => {
                                                        this.repairConditionsChange(items, setFieldValue, setFieldTouched);
                                                        setUnsavedChangesAction(true);
                                                    }}
                                                    onEmptyResolveSuggestions={(selectedItems) => this.filterRepairConditions("", selectedItems)}
                                                    selectedItems={values.repairConditions}
                                                    disabled={allDebugControlsDisabled || repairConditionsNotAvailable}
                                                    removeButtonAriaLabel="Remove tag item button"
                                                    pickerSuggestionsProps={{
                                                        suggestionsContainerAriaLabel: "Repair conditions tag picker suggestions"
                                                    }}
                                                />
                                                <ErrorMessage className="text-danger" component="span" name="repairConditions" />
                                            </div>
                                        </div>
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.repairAction" />
                                            </label>
                                            <div
                                                data-testid="assignRAPage--repairActions"
                                                className="form__field__input__container">
                                                <TagPicker
                                                    inputProps={{
                                                        "aria-label": "Repair actions tag picker"
                                                    }}
                                                    disabled={true}
                                                    className="tag__picker disabled"
                                                    selectedItems={values.repairActions}
                                                />
                                            </div>
                                        </div>

                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.repairActionCode" />
                                            </label>
                                            <div
                                                data-testid="assignRAPage--repairActionsCode"
                                                className="form__field__input__container">
                                                <TagPicker
                                                    inputProps={{
                                                        "aria-label": "Repair actions code tag picker"
                                                    }}
                                                    disabled={true}
                                                    className="tag__picker disabled"
                                                    selectedItems={values.repairActionCodes}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.debugFlow" />
                                            </label>
                                            <div className="form__field__input__container">
                                                <TextField
                                                    name="debugFlow"
                                                    onChange={(e, newValue) => {
                                                        setFieldValue("debugFlow", newValue, true);
                                                        setFieldTouched("debugFlow", true, false);
                                                        setUnsavedChangesAction(true);
                                                    }}
                                                    data-testid="assignRAPage--debugFlowField"
                                                    value={values.debugFlow}
                                                    disabled={allDebugControlsDisabled || repairConditionsNotAvailable}
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    ariaLabel="Debug flow input text box"
                                                />
                                            </div>
                                        </div>
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.defectiveComponent" />
                                            </label>
                                            <div className="form__field__input__container" data-testid="assignRAPage--defectiveComponents">
                                                <TagPicker
                                                    inputProps={{
                                                        "aria-label": "Defective component tag picker"
                                                    }}
                                                    disabled={true}
                                                    className="tag__picker disabled"
                                                    selectedItems={values.defectiveComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.collateralComponent" />
                                            </label>
                                            <div className="form__field__input__container" data-testid="assignRAPage--collateralComponents">
                                                <TagPicker
                                                    inputProps={{
                                                        "aria-label": "Collateral component tag picker"
                                                    }}
                                                    disabled={true}
                                                    className="tag__picker disabled"
                                                    selectedItems={values.collateralComponent}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.reassembleComponent" />
                                            </label>
                                            <div className="form__field__input__container" data-testid="assignRAPage--reassembleComponents">
                                                <TagPicker
                                                    inputProps={{
                                                        "aria-label": "Reassemble component tag picker"
                                                    }}
                                                    disabled={true}
                                                    className="tag__picker disabled"
                                                    selectedItems={values.reassembleComponent}
                                                />
                                            </div>
                                        </div>

                                        <div className="form__field__datapairs">
                                            <label className="form__field__label">
                                                <Trans i18nKey="currentDebugAction.comments" />
                                            </label>
                                            <div className="form__field__input__container">
                                                <TextField
                                                    name="comment"
                                                    onChange={(e, newValue) => {
                                                        setFieldValue("comment", newValue, true);
                                                        setFieldTouched("comment", true, false);
                                                        setUnsavedChangesAction(true);
                                                    }}
                                                    data-testid="assignRAPage--commentField"
                                                    value={values.comment}
                                                    maxLength={INPUT_MAX_LENGTH}
                                                    disabled={allDebugControlsDisabled}
                                                    ariaLabel="Repair comment text input box"
                                                />
                                            </div>
                                        </div>
                                            <div className="form__field__datapairs">
                                                <label className="form__field__label required">
                                                    <Trans i18nKey="currentDebugAction.debondingRollback" />
                                                </label>
                                                <div
                                                    data-testid="assignRAPage--debonding-rollback"
                                                    className={
                                                        "form__field__input__container inline" +
                                                        (touched.debonding === true &&
                                                            dataSavedForAllErrorCodes &&
                                                            (!values.debonding || values.debonding.length === 0)
                                                            ? " error"
                                                            : "")
                                                    }>
                                                    <Dropdown
                                                        className="dropdown__select"
                                                        placeholder="Select an option"
                                                        data-testid="assignRAPage--debondingDropdown"
                                                        disabled={allDebugControlsDisabled}
                                                        notifyOnReselect={true}
                                                        styles={{ dropdown: { width: 'calc(100% - 32px)' } }}
                                                        multiSelect={true}
                                                        selectedKeys={values.debonding}
                                                        options={DebugActionUtils.debondingEnumData}
                                                        onChange={(e, items) => {                                                           
                                                            this.debondingChange(items, values.debonding, setFieldValue, setFieldTouched);
                                                            setUnsavedChangesAction(true);
                                                        }}
                                                        ariaLabel="Debonding required list drop down "
                                                    />
                                                    <ErrorMessage className="text-danger" component="span" name="debonding" />
                                                </div>
                                            </div>
                                            <div className="form__field__datapairs">
                                                <label className="form__field__label required text-left">
                                                    <Trans i18nKey="currentDebugAction.nextStep" />
                                                </label>
                                                <div
                                                    data-testid="assignRAPage--next-step"
                                                    className={
                                                        "form__field__input__container" +
                                                        (touched.nextStep === true &&
                                                            dataSavedForAllErrorCodes &&
                                                            (!values.nextStep || values.nextStep.length === 0)
                                                            ? " error"
                                                            : "")
                                                    }>
                                                    <ComboBox
                                                        className="ms-ComboBox-Input"
                                                        placeholder="Select Next Step."
                                                        openOnKeyboardFocus={true}
                                                        notifyOnReselect={true}
                                                        data-testid="assignRAPage--nextStepDropdown"
                                                        selectedKey={values.nextStep}
                                                        options={nextStepList}
                                                        onPendingValueChanged={(option, index, value) => filterNextStepList(option, index, value)}
                                                        maxLength={INPUT_MAX_LENGTH}
                                                        onChange={(e, item) => {
                                                            if (!item) {
                                                                let value = e.currentTarget.value.trim();
                                                                const regex = new RegExp(nextStepRegex);
                                                                if (value.length > 30 || value === "" || value.length < 2) {
                                                                    this.setState({ nextStepErrorMessage: Message.NextStepLengthCharactersError });
                                                                    setFieldValue("nextStep", "", true);
                                                                    setFieldTouched("nextStep", true, false);
                                                                    return;
                                                                }
                                                                else if (regex.test(value)) {
                                                                    this.setState({ nextStepErrorMessage: Message.NextStepInvalidCharactersError });
                                                                    setFieldValue("nextStep", "", true);
                                                                    setFieldTouched("nextStep", true, false);
                                                                    return;
                                                                }
                                                                else {
                                                                    item = { "key": value, "text": value };
                                                                    fullNextStepList.push(item);
                                                                    nextStepList.push(item);
                                                                }
                                                                this.setState({ nextStepErrorMessage: "" })
                                                            }
                                                            setFieldValue("nextStep", item.key, true);
                                                            setFieldTouched("nextStep", true, false);
                                                            setUnsavedChangesAction(true);
                                                            this.setState({ nextStepErrorMessage: "" })
                                                        }}
                                                        disabled={allDebugControlsDisabled}
                                                        ariaLabel="Next step required list drop down"
                                                        autoComplete={'off'}
                                                        allowFreeform={true}
                                                    />
                                                    <ErrorMessage className="text-danger" component="span" name="nextStep" />
                                                </div>
                                            </div>
                                        
                                        {nextStepErrorMessage && (
                                            <Fragment>
                                                <MessageView
                                                    dataTestId="error-assignra-message-bar"
                                                    title={nextStepErrorMessage}
                                                    messageBarType={1}
                                                />

                                            </Fragment>
                                        )}
                                        <div className="debug__action__actionpairs">
                                            <ButtonPrimary
                                                isDisabled={
                                                    allDebugControlsDisabled || saveIsLoading || !(dirty && values.repairConditions.length !== 0)
                                                }
                                                isLoading={saveIsLoading}
                                                buttonLabel="currentDebugAction.save"
                                                dataTestid="assignRAPage--saveButton"
                                                loadingLabel="Saving..."
                                                disableMessage="messages.saveDisabledMessage"
                                                ariaLabel="Save repair action button"
                                                onClickEvent={() => {
                                                    setFieldValue("actionMethod", RepairToolActions.Save, false);
                                                    submitForm();
                                                }}
                                            />
                                            <ButtonPrimary
                                                isDisabled={!(repairState === RepairState.DebugComplete) || unassignRAIsLoading}
                                                isLoading={unassignRAIsLoading}
                                                buttonLabel="currentDebugAction.unassignRA"
                                                dataTestid="assignRAPage--unassignRAButton"
                                                loadingLabel="Unassigning..."
                                                disableMessage="messages.unassignRADisabledMessage"
                                                ariaLabel="Unassign repair action button"
                                                onClickEvent={async () => {
                                                    await unassignRepairActionsAction(this.createUnassignRARequest());
                                                    if (!errorMessage) {
                                                        this.resetForm();
                                                    }
                                                }}
                                            />
                                            <ButtonPrimary
                                                isDisabled={
                                                    allDebugControlsDisabled ||
                                                    assignRAIsLoading ||
                                                    !(
                                                        dataSavedForOnlineErrorCode &&
                                                        values.debonding &&
                                                        values.debonding.length > 0 &&
                                                        values.nextStep &&
                                                        !touched.repairConditions &&
                                                        !touched.debugFlow
                                                    ) ||
                                                    (isEventBased && sfcsAckActionState === LOAD_START)
                                                }
                                                dataTestid="assignRAPage--assignRAButton"
                                                isLoading={assignRAIsLoading}
                                                buttonLabel={ isEventBased ? "currentDebugAction.initiateAssignRA" : "currentDebugAction.assignRA" }
                                                loadingLabel="Assigning..."
                                                disableMessage="messages.assignRADisabledMessage"
                                                ariaLabel="Assign repair action button"
                                                onClickEvent={() => {
                                                    const cancelDialogState = { dialog: { ...this.state.dialog, showDialog: false } };
                                                    if ((values.defectiveComponent.filter(r => r.key != "NULL").length !== 0 || values.collateralComponent.filter(r => r.key != "NULL").length !== 0 || values.reassembleComponent.filter(r => r.key != "NULL").length !== 0) && values.debonding.length === 1 && (values.debonding[0] === "Retest" || values.debonding[0] === "Quarantine" || values.debonding[0] === "Salvage") )
                                                    {
                                                        if (values.debonding[0] === "Retest") {
                                                            this.setState({
                                                                dialog: {
                                                                    title: t("messages.cannotContinueWithRetestHeader"),
                                                                    message: t("messages.retestConfirmationPopupComponents"),
                                                                    cancelButtonLabel: "Close",
                                                                    showDialog: true,
                                                                    onClickCancel: () => {
                                                                        this.setState(cancelDialogState);
                                                                    }
                                                                }
                                                            });
                                                        } else if (values.debonding[0] === "Salvage") {
                                                            this.setState({
                                                                dialog: {
                                                                    title: t('messages.cannotContinueWithSalvageHeader'),
                                                                    message: t("messages.salvageConfirmationPopupComponents"),
                                                                    cancelButtonLabel: "Close",
                                                                    showDialog: true,
                                                                    onClickCancel: () => {
                                                                        this.setState(cancelDialogState);
                                                                    }
                                                                }
                                                            });
                                                        } else if (values.debonding[0] === "Quarantine") {
                                                            this.setState({
                                                                dialog: {
                                                                    title: t('messages.cannotContinueWithQuarantineHeader'),
                                                                    message: t("messages.quarantineConfirmationPopupComponents"),
                                                                    cancelButtonLabel: "Close",
                                                                    showDialog: true,
                                                                    onClickCancel: () => {
                                                                        this.setState(cancelDialogState);
                                                                    }
                                                                }
                                                            });
                                                        }                                                     
                                                    }
                                                    else {
                                                        if (!dataSavedForAllErrorCodes) {
                                                            this.setState({
                                                                dialog: {
                                                                    title: t("messages.doYouWantToContinueMessage"),
                                                                    message: t("messages.dataNotProvidedForOfflineErrorCodesPopupMessage") + errorCodesWithRepairActionNotAssigned,
                                                                    okButtonLabel: t("popupButton.yes"),
                                                                    cancelButtonLabel: t("popupButton.cancel"),
                                                                    okButtonType: ButtonType.Success,
                                                                    showDialog: true,
                                                                    onClickOk: () => {
                                                                        if (values.defectiveComponent.filter(r => r.key != "NULL").length === 0 && values.collateralComponent.filter(r => r.key != "NULL").length === 0 && values.reassembleComponent.filter(r => r.key != "NULL").length === 0 &&
                                                                            !((values.debonding.includes("Retest") || values.debonding.includes("Salvage") || values.debonding.includes("Quarantine")) && values.debonding.length === 1)) {                                                                         
                                                                            this.setState({
                                                                                dialog: {
                                                                                    title: "Do you want to go for Retest/Quarantine/Salvage?",
                                                                                    message: t("messages.retestConfirmationPopupNoComponents"),
                                                                                    okButtonLabel: t("popupButton.yes"),
                                                                                    cancelButtonLabel: t("popupButton.noContinue"),
                                                                                    okButtonType: ButtonType.Success,
                                                                                    showDialog: true,
                                                                                    onClickOk: () => {
                                                                                        this.setState(cancelDialogState);
                                                                                    },
                                                                                    onClickCancel: () => {
                                                                                        this.setState(cancelDialogState);
                                                                                        const touchedInitial = Object.assign({}, touched);
                                                                                        setFieldValue("touched", touchedInitial, false);
                                                                                        setFieldValue("actionMethod", RepairToolActions.AssignRA, false);
                                                                                        submitForm();
                                                                                    }
                                                                                }
                                                                            });
                                                                        } else {
                                                                            this.setState(cancelDialogState);
                                                                            const touchedInitial = Object.assign({}, touched);
                                                                            setFieldValue("touched", touchedInitial, false);
                                                                            setFieldValue("actionMethod", RepairToolActions.AssignRA, false);
                                                                            submitForm();
                                                                        }
                                                                    },
                                                                    onClickCancel: () => {
                                                                        this.setState(cancelDialogState);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            if (values.defectiveComponent.filter(r => r.key != "NULL").length === 0 && values.collateralComponent.filter(r => r.key != "NULL").length === 0 && values.reassembleComponent.filter(r => r.key != "NULL").length === 0 &&
                                                                !((values.debonding.includes("Retest") || values.debonding.includes("Salvage") || values.debonding.includes("Quarantine")) && values.debonding.length === 1)) {
                                                                this.setState({
                                                                    dialog: {
                                                                        title: t("messages.retestConfirmationHeader"),
                                                                        message: t("messages.retestConfirmationPopupNoComponents"),
                                                                        okButtonLabel: t("popupButton.yes"),
                                                                        cancelButtonLabel: t("popupButton.noContinue"),
                                                                        okButtonType: ButtonType.Success,
                                                                        showDialog: true,
                                                                        onClickOk: () => {
                                                                            this.setState(cancelDialogState);
                                                                        },
                                                                        onClickCancel: () => {
                                                                            this.setState(cancelDialogState);
                                                                            const touchedInitial = Object.assign({}, touched);
                                                                            setFieldValue("touched", touchedInitial, false);
                                                                            setFieldValue("actionMethod", RepairToolActions.AssignRA, false);
                                                                            submitForm();
                                                                        }
                                                                    }
                                                                });
                                                            } else {
                                                                const touchedInitial = Object.assign({}, touched);
                                                                setFieldValue("touched", touchedInitial, false);
                                                                setFieldValue("actionMethod", RepairToolActions.AssignRA, false);
                                                                submitForm();
                                                            }
                                                        }
                                                    }
                                                }
                                                }
                                            />
                                        </div>
                                        {errorMessage && (
                                            <Fragment>
                                                <MessageView
                                                    dataTestId="error-assignra-message-bar"
                                                    title={errorMessage}
                                                    subTitle={errorMessageSubtitle}
                                                    messageBarType={MessageBarType.error}
                                                />

                                            </Fragment>
                                        )}
                                        {assignRAActionState === LOAD_END && sfcsAckActionState !== LOAD_END && isEventBased && (
                                            <Fragment>
                                                <ProgressWithTime title={"Waiting for SFCS Acknowledgement"} />
                                            </Fragment>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </fieldset>
                </div>
            </React.Fragment>
        );
    }

    /**
     * Create Unassign RA Request
     */
    createUnassignRARequest() {
        const { serialNumber } = this.props;
        return {
            SerialNumber: serialNumber,
        };
    }

    /**
     * Create Save Request
     * @param {*} values Formik Values
     * @param {*} selectedRepairConditions Selected Repair Conditions
     */
    createSaveRequest(values, selectedRepairConditions) {
        const { serialNumber, currentDebugDetails, currentErrorCode, currentOperation } = this.props;
        return {
            SerialNumber: serialNumber,
            Debonding: values.debonding,
            NextStep: values.nextStep,
            RepairComment: values.comment,
            ErrorCode: currentErrorCode.trim(),
            Operation: currentOperation,
            DebugFlow: values.debugFlow,
            DebugFlowName: currentDebugDetails.DebugFlowName,
            RepairActions: currentDebugDetails.RepairActions.filter((repairAction) =>
                selectedRepairConditions.includes(repairAction.RepairCondition)
            ),
        };
    }

    /**
     * Create Assign RA Request
     * @param {*} param0 object contains debonding, nextStep and comment fields
     */
    createAssignRARequest({ debonding, nextStep, comment }) {
        const { serialNumber } = this.props;

        return {
            SerialNumber: serialNumber,
            Debonding: debonding,
            NextStep: nextStep,
            RepairComment: comment,
        };
    }
}

DebugAction.propTypes = propTypes;

/**
 * Map redux state to props
 * @param {*} param0 redux state
 */
const mapStateToProps = ({ repairActions, debugDetails, searchSettings }) => ({
    currentErrorCode: repairActions.currentErrorCode,
    currentOperation: debugDetails.operation,
    currentDebugDetails: repairActions.currentDebugDetails,
    currentDebugFormData: repairActions.currentDebugFormData,
    currentRepairConditions: repairActions.currentRepairConditions,
    savedDebugDetails: repairActions.debugDetails,
    debonding: debugDetails.debonding,
    nextStep: debugDetails.nextStep,
    repairComment: debugDetails.repairComment,
    repairState: debugDetails.repairState,
    serialNumber: debugDetails.serialNumber,
    saveIsLoading: debugDetails.saveActionState === LOAD_START,
    assignRAIsLoading: debugDetails.assignRAActionState === LOAD_START,
    unassignRAIsLoading: debugDetails.unAssignRAActionState === LOAD_START,
    errorMessage: debugDetails.actionError,
    errorMessageSubtitle: debugDetails.errorMessageSubtitle,
    nextStepList: debugDetails.nextStepList,
    fullNextStepList: debugDetails.fullNextStepList,
    nextStepRegex: debugDetails.nextStepRegex,
    isActionCompleted:
        debugDetails.saveActionState === LOAD_END ||
        debugDetails.assignRAActionState === LOAD_END ||
        debugDetails.unAssignRAActionState === LOAD_END,
    saveActionState: debugDetails.saveActionState,
    assignRAActionState: debugDetails.assignRAActionState,
    unAssignRAActionState: debugDetails.unAssignRAActionState,
    onlineErrorCode: debugDetails.dutInfo.ErrorDetails.find(x => x.OperatorMode === OperatorMode.Online).ErrorCode,
    isEventBased: searchSettings.isEventBased,
    sfcsAckActionState: debugDetails.sfcsAckActionState,
});

export default withTranslation()(
    connect(mapStateToProps, {
        saveRepairActions,
        assignRepairActions,
        unassignRepairActions,
        setUnsavedChanges,
        clearActionStates,
        filterNextStepList,
    })(DebugAction)
);
