import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../styles/default.scss";
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAppInsights } from "../AppInsights/TelemetryService";
import {
  Image,
  Modal,
  ChoiceGroup,
  ImageLoadState,
  IconButton,
  Stack,
  Label,
} from "office-ui-fabric-react";
import "./DebugFlow.scss";

const propTypes = {
  currentErrorCode: PropTypes.string,
  currentDebugDetails: PropTypes.object,
};

export class DebugFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverlayVisible: false,
      imageProps: {
        src: "",
          alt: "No image found."
      },
       imageWidth: "100%"
    };

    this.ZoomPercentage = 25;

      this.MaxZoomPercentageAllowed = 800;

    this.setImage = this.setImage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.langSelectionRef = React.createRef();
    this.onClickZoomIn = this.onClickZoomIn.bind(this);
    this.onClickZoomOut = this.onClickZoomOut.bind(this);
    this.onClickZoomReset = this.onClickZoomReset.bind(this);
  }

  componentDidMount() {
    const appInsights = getAppInsights();
    appInsights &&
          appInsights.trackTrace({ message: "Debug flow page loaded" });

    var langDropdown = this.langSelectionRef.current;
    if (langDropdown && this.props.currentErrorCode) {
      this.setImage(
        this.langSelectionRef.current.state.keyChecked,
        this.props.currentDebugDetails
      );
    }
  }

  onChange(ev, option) {
    this.setImage(option.key, this.props.currentDebugDetails);
  }

  setImage(key, currentDetails) {
    if (currentDetails) {
      const imageAttachment = currentDetails.Attachments.find(
        (attachment) => attachment.Language === key
      );
      this.setState({
        imageProps: {
          src: imageAttachment.URL,
              alt: "Debug flow Image",
        },
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.currentErrorCode !== this.props.currentErrorCode &&
      this.langSelectionRef
    ) {
      this.setImage(
        this.langSelectionRef.current.state.keyChecked,
        nextProps.currentDebugDetails
      );
    }

    return true;
  }

  onClickZoomIn() {
    const prevImageWidth = this.state.imageWidth;
    const width = parseFloat(prevImageWidth.replace("%", ""));
    if ((width + this.ZoomPercentage) > this.MaxZoomPercentageAllowed) {
      return;
    }
    const newImageWidth = String(width + this.ZoomPercentage) + "%";
    this.setState({
      imageWidth: newImageWidth
    });
  }

  onClickZoomOut() {
    const prevImageWidth = this.state.imageWidth;
    const width = parseFloat(prevImageWidth.replace("%", ""));

    if (width <= this.ZoomPercentage) {
      return;
    }
    const newImageWidth = String(width - this.ZoomPercentage) + "%";

    this.setState({
      imageWidth: newImageWidth
    });
  }

  onClickZoomReset() {
    this.setState({
      imageWidth: "100%"
    });
  }

  toggleOverlay() {
    this.setState({ isOverlayVisible: !this.state.isOverlayVisible });
  }

  render() {
    const options = [
      {
        key: "en",
        text: i18n.getResource("en", "translations", "language"),
        "data-testid": "English",
      },
      {
        key: "zh-Hans",
        text: i18n.getResource("zh-Hans", "translations", "language"),
        "data-testid": "Chinese",
      },
    ];
    const onLoadingStateChange = (loadState) => {
      if (loadState === ImageLoadState.error) {
        this.setState({
          imageProps: {
                alt: "No image found.",
          },
        });
      }
    };

    const { imageWidth } = this.state; 

    return (
      <React.Fragment>
        <div className="debug__flow__container">
            <fieldset className="section__header">
            <legend id="Debug_Flow_Image" role="heading" aria-level="3">
              <Trans i18nKey="debugFlow.title" />
            </legend>
            <div className="debug__flow__datacontainer">
              <ChoiceGroup
                className="debug__flow__lang__selection"
                componentRef={this.langSelectionRef}
                options={options}
                defaultSelectedKey={i18n.language}
                onChange={this.onChange}
                ariaLabel="Debug flow image language selection"
                        />  
               <Modal
                isOpen={this.state.isOverlayVisible}
                titleAriaId={"Debug_Flow_Image"}
                onDismiss={this.toggleOverlay}                
                containerClassName="container__overlay">
                <Stack tokens={{ childrenGap: 5 }} verticalAlign>
                  <IconButton
                    className="icon-primary"
                    iconProps={{
                      iconName: "ZoomIn",
                    }}
                    title="Zoom In"
                    onClick={this.onClickZoomIn}
                    ariaLabel="Zoom In"
                  />
                  <Label title={imageWidth}>{imageWidth}</Label>
                  <IconButton
                    className="icon-primary"
                    iconProps={{
                      iconName: "ZoomOut",
                    }}
                    onClick={this.onClickZoomOut}
                    title="Zoom Out"
                    ariaLabel="Zoom Out"
                  />
                  <IconButton
                    className="icon-primary"
                    iconProps={{
                      iconName: "ZoomToFit",
                    }}
                    onClick={this.onClickZoomReset}
                    title="Reset"
                    ariaLabel="Reset"
                  />
                 </Stack>        
                 <Image
                  ariaLabel="Debug Flow Image"
                  {...this.state.imageProps}
                  shouldStartVisible={true}
                  onLoadingStateChange={onLoadingStateChange}
                  data-testid="image-modal"
                  styles={{
                    image: {
                      opacity: 1,
                      width: imageWidth,
                      overflowX: "auto"
                    },
                    root: { 
                      overflow: "auto",
                      maxHeight: "70vh"
                    }
                  }}
               />              
               </Modal>    
              <a
                href="#"
                data-testid="maximize-image"
                onClick={this.toggleOverlay}>
                <Image
                  {...this.state.imageProps}
                  shouldStartVisible={true}
                  onLoadingStateChange={onLoadingStateChange}
                  data-testid="image"
                  styles={{
                    image: {
                      opacity: 1,
                      width: "40vw",
                    },
                  }}
                  ariaLabel="Debug Flow Image"
                />
              </a>
            </div>
          </fieldset>
        </div>
      </React.Fragment>
    );
  }
}

DebugFlow.propTypes = propTypes;

const mapStateToProps = ({ repairActions }) => ({
  currentDebugDetails: repairActions.currentDebugDetails,
  currentErrorCode: repairActions.currentErrorCode,
});

export default withTranslation()(connect(mapStateToProps, {})(DebugFlow));
