export const chineseUIStrings ={
  "FeedbackSubtitle": "向 Microsoft 发送反馈",
  "PrivacyStatement": "隐私声明",
  "Form": {
    "CommentPlaceholder": "请不要在评论中包含任何机密信息或个人信息",
    "CategoryPlaceholder": "选择类别(可选)",
    "EmailPlaceholder": "电子邮件(可选)",
    "RatingLabel": "评级",
    "ScreenshotLabel": "包括屏幕截图",
    "Submit": "提交",
    "Cancel": "取消",
    "EmailCheckBoxLabel": "可就此反馈与我联系",
    "PrivacyConsent": "组织的 IT 管理员将能够查看和管理你的反馈数据。",
    "PrivacyLabel": "按“提交”即表示你的反馈将用于改进 Microsoft 产品和服务。 ",
    "ScreenshotImgAltText": "屏幕截图预览"
  },
  "SingleForm": { "Title": "请提供反馈" },
  "SmileForm": { "Anchor": "我喜欢其中一些方面", "Title": "你喜欢哪些内容?" },
  "FrownForm": {
    "Anchor": "我不喜欢其中一些方面",
    "Title": "你不喜欢哪些内容?"
  },
  "IdeaForm": { "Anchor": "我有一个建议", "Title": "你有何建议?" },
  "BugForm": { "Anchor": "提交 Bug", "Title": "有什么 bug?" },
  "ThanksPanel": {
    "Title": "谢谢！",
    "AppreciateText": "我们非常感谢你的反馈。你的意见有助于我们改进。",
    "MyFeedbackDescription": "想要更多地进行参与? 请访问我们的反馈论坛以创建、查看社区反馈并进行投票。",
    "MyFeedbackLinkText": "我的反馈",
    "FeedbackPortalDescription": "跟踪你的反馈并了解它是如何发挥作用的。请留出一些时间显示你的最新反馈。",
    "FeedbackPortalLinkText": "反馈论坛",
    "Close": "关闭"
  },
  "Floodgate": {
    "Nps": {
      "Prompt": {
        "Title": "我们期待你的反馈!",
        "Question": "我们只想问两个问题。",
        "Yes": "当然",
        "No": "以后再说"
      },
      "Comment": { "Question": "请详细告知我们。为什么选择了该答案?" },
      "Rating": {
        "Question": "你向好友或同事推荐此站点的可能性有多大?",
        "Points11Value0": "0 - 完全不可能",
        "Points11Value1": "1",
        "Points11Value2": "2",
        "Points11Value3": "3",
        "Points11Value4": "4",
        "Points11Value5": "5",
        "Points11Value6": "6",
        "Points11Value7": "7",
        "Points11Value8": "8",
        "Points11Value9": "9",
        "Points11Value10": "10 - 极有可能",
        "Points5Value1": "1 - 完全不可能",
        "Points5Value2": "2",
        "Points5Value3": "3",
        "Points5Value4": "4",
        "Points5Value5": "5 - 极有可能"
      }
    }
  },
  "CloseLabel": "关闭"
}
